
.get-a-quote-page {
	.lockton-top-section {
		.two-column-grid {
			.top-section-block {
				// padding-top: 40px;

				@media only screen and (max-width: 1199px) {
					padding-top: 32px;
				}
				@include media("<wide-tab") {
					padding-top: 28px;
				}
			}
			.left-column {
				&::before {
					// background-color: rgba(87, 103, 108, 0.9);
				}
				@include media("<tabport") {
					display: block;
					width: 100%;
					-ms-flex: none;
					-webkit-flex: none;
					flex: none;

					&::before {
						right: -50%;
						width: 200%;
					}
				}	
				.column-title {
					// margin: 7px 0 30px;
					// @include Font(Proxima-Thin);
  					// font-size: 24px;
  					// line-height: 24px;
					// margin: 4px 0 12px;
  					// color: #faf7f7;

					// @media only screen and (max-width: 1199px) {
					// 	margin: 5px 0 20px;
					// }
					@include media("<=phoneland") {
						// font-size: 20px;
    					// line-height: 1.3;
						//margin: 0 0 12px;
					}
				}
				.column-content {
					//@include Font(Proxima-ThinIt);
					font-size: 16px;
  					line-height: 20px;
					//color: $white;

					@include media("<=phoneland") {
						font-size: 15px;
    					line-height: 1.3;
					}
				}
			}
			.right-column {
				padding-right: 5%;
				max-width: 34%;	

				@media only screen and (max-width: 1199px) {
					padding-right: 0;
				}
				@include media("<tabport") {
					display: none;
				}
			}
		}
	}
	.main-section-container {
		padding: 38px 0 25px;

		@media only screen and (max-width: 1199px) {
			padding: 35px 0 10px;
		}
		@include media("<tabport") {
			padding: 24px 0 10px;
		}

		.bounds {
			.main-section {
				float: left;
				width: 61.5%;
				padding: 0 0 20px;

				@media only screen and (max-width: 1199px) {
					float: none;
					width: 100%;
					padding: 0 0 40px;
				}

				.section-title {
					font-size: 24px;
					line-height: 29px;
					margin: 0 0 25px;

					@include media("<tabport") {
						margin: 0 0 20px;
					}
				}

				.form-container {
					padding: 18px 0 36px;
				}
				.social-icons-wrap {
					padding-top: 15px;
				}
			}
			.sidebar {
				float: right;
				width: 33.5%;
				padding: 0 0 20px;

				@media only screen and (max-width: 1199px) {
					float: none;
					width: 100%;
					padding: 0;
				}

				.sidebar-widgets-container {
			
					@media only screen and (min-width: 1200px) {
						padding-right: 15px;
					}

					@media only screen and (min-width: 768px) and (max-width: 1199px) {
						.sidebar-news-widget {
							width: 48%;

							&:nth-child(odd) {
								float: left;
								clear: left;
							}
							&:nth-child(even) {
								float: right;
								clear: right;
							}
						}
					}
				}
			}
		}
	}
}