
.resources-page {
    .page-header {
        border-left: 0;
        &::before {
            display: none;
        }


        @if ($site != mobility) {
            @include media('<tabport') {
                border-top: 3px solid $b_accent;
            }
        }
        .block {
            z-index: 0;

            flex-basis:100%;
            
            &:first-child{
                padding-right:0;
                &::before{
                    background-color: $b_background;
                }
                &::after{
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    bottom: 0;
                    left: 100%;

                    display: block;

                    width: 2500px;

                    content: '';

                    background-color: $b_background;

                    // @include media('<=phoneland') {
                    //     left: -32px;
                    // }
                }
                .content-wrap{
                    border-color:$b_accent;
                }
            }
            &:last-child:before{
                display:none;
            }
            &:last-child:after{
                left:-50%;
                width:200%;

                background-color: $a_background;
            }
            & + .block{
                padding-left: 16px;
                @include media('<=990px') {
                    padding-left:0;
                }
            }
            .content-wrap {
                position: relative;
                z-index: 3;

                @if($site != mobility) {
                    padding: 0 220px 0 32px; // @TODO Query this - it's just bunching up the filters
                }

                border-color: $b_accent;

                @include media('<1200px') {
                    padding: 0 0 0 32px;
                }
                @include media('<wide-tab') {
                    
                    @if($site != accountants) {
                        padding: 0 0 0 16px;
                    } @else {
                        padding:0;
                    }
                }
                @include media('<tabport') {
                    @if($site != accountants) {
                        padding: 0 8px 0 7px;
                    }
                }
                .column-title {
                    margin: 0;

                    color: $b_text;

                    @if ($site != mobility) {
                        padding-right:110px;   
                    }

                    @include media('<1200px') {
                        margin: 0;
                    }
                    @include media('<wide-tab') {
                        font-size: 28px;
                        line-height: 1.0;

                        @if($site != accountants) {
                            margin: 0 0 15px;
                        }
                    }
                    @include media('<tabport') {
                        margin: 0;
                        @if($site != accountants) {
                            padding-left: 5px;
                        }
                    }
                    @include media('<=phoneland') {
                        font-size: 26px;
                        line-height: 1.2;

                        @if($site == accountants) {
                            padding-left:24px;
                        }

                        @include Font(Proxima-Light);
                    }
                }
                .block-content { // @TODO
                    font-size: 20px;
                    line-height: 30px;

                    margin: 0 0 35px;

                    color: $b_text;

                    @include Font(Proxima-Thin);
                    @include media('<wide-tab') {
                        font-size: 20px;
                        line-height: 24px;

                        margin: 0 0 22px;
                    }
                    @include media('<tabport') {
                        padding-left: 5px;
                    }
                    @include media('<=phoneland') {
                        font-size: 18px;
                        line-height: 22px;

                        margin: 0 0 20px;

                        @include Font(Proxima-Light);
                    }
                }
            }
        }
    }
    .main-section-container {
        position: relative;

        padding: 0 0 41px;

        @include media('<tabport') {
            padding: 27px 0 47px;
        }
        @include media('<1200px') {
            padding: 28px 0 52px;
        }
        .bounds {
            .top-row {
                padding: 15px 0 7px;
                .social-icons-wrap {
                    float: right;
                }
            }
            .main-section {
                float: left;

                width: 74%;

                @include media('<1200px') {
                    width: 65.5%;
                }
                @include media('<wide-tab') {
                    width: 63.5%;
                }
                @include media('<tabport') {
                    float: none;

                    width: 100%;
                    padding-bottom: 7px;
                }
                p {
                    margin-bottom: 17px;
                }
                .main-article {
                    padding-bottom: 25px;

                    @include media('<1200px') {
                        padding-bottom: 10px;
                    }
                    @include media('<wide-tab') {
                        padding-bottom: 17px;
                    }
                    @include media('<=phoneland') {
                        padding-bottom: 7px;
                    }
                    .article-image-wrap {
                        position: relative;

                        float: left;

                        max-width: 45%;
                        height: auto;
                        margin: 0 28px 20px 0;
                        padding: 7px 0 0;

                        @include media('<1200px') {
                            width: 100%;
                            max-width: none;
                            margin: 0 0 25px;
                            padding: 0;
                        }
                        @include media('<=phoneland') {
                            margin: 0 0 22px;
                        }
                        img {
                            width: 100%;
                            height: auto;
                        }
                        .image-block-text {
                            font-size: 16px;
                            line-height: 31px;

                            position: absolute;
                            z-index: 1;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            box-sizing: border-box;
                            height: 31px;
                            padding: 0 16px;

                            color: $white;
                            background: $body-text; // @TODO check this

                            @include Font(Proxima-Reg);
                        }
                    }
                    .article-heading {
                        font-size: 31px;
                        line-height: 37px;

                        margin: 0 0 14px;

                        color: $greyish-brown;

                        @include Font(Proxima-Light);
                        @include media('<1200px') {
                            margin: 0 0 25px;
                        }
                        @include media('<=phoneland') {
                            font-size: 28px;
                            line-height: 32px;

                            margin: 0 0 20px;
                        }
                    }
                    .date-author-container {
                        font-size: 16px;
                        line-height: 18px;

                        position: relative;

                        display: block;

                        margin-bottom: 16px;
                        padding-left: 43px;

                        @include media('<1200px') {
                            margin-bottom: 20px;
                        }
                        .icon {
                            position: absolute;
                            z-index: 1;
                            top: 2px;
                            left: 0;
                        }
                    }
                }
                .search-results {
                    overflow: hidden;
                    .three-column-grid {
                        position: relative;

                        margin: 0 -12px;
                        padding: 0;

                        @include media('<wide-tab') {
                            margin: 0 -8px;
                        }
                        .block {
                            padding: 39px 12px 47px;

                            border-top: 2px solid $white-six;

                            @include media('<1200px') {
                                width: 50%;
                                max-width: 50%;
                                padding: 30px 12px 37px;
                            }
                            @include media('<wide-tab') {
                                // padding: 31px 8px;
                                padding: 36px 8px 31px;
                            }
                            @include media('<tabport') {
                                width: 100%;
                                max-width: none;
                                padding: 27px 8px 25px;
                            }
                            p {
                                margin-bottom: 22px;
                                color: $body-text;
                            }
                            .search-result-title {
                                font-size: 20px;
                                line-height: 24px;

                                min-height: 72px;
                                max-height: 72px;
                                margin: 0 0 7px;

                                color: $greyish-brown;

                                @include Font(Proxima-Light);
                            }
                            .article-heading {
                                display: none;
                            }
                            .date-author-container {
                                font-size: 16px;
                                line-height: 18px;

                                position: relative;

                                display: inline-block;

                                margin-bottom: 15px;
                                padding-left: 44px;

                                @include media('<1200px') {
                                    margin-bottom: 22px;
                                }
                                @include media('<tabport') {
                                    margin-bottom: 18px;
                                }
                                .icon {
                                    position: absolute;
                                    z-index: 1;
                                    top: 2px;
                                    left: 0;
                                }
                            }
                            .read-more-link {
                                @if($site == mobility){
                                    @extend .btn_b;
                                }
                                line-height: 18px;

                                position: static;

                                @include Font(Proxima-Reg);


                            }
                            &:nth-child(2) {
                                // Was first-child but count based div's have got in the way so it's down to second
                                min-width: 100%;
                                padding-bottom: 25px;

                                -ms-flex-preferred-size: 100%;
                                flex-basis: 100%;
                                @include media('<1200px') {
                                    padding-bottom: 10px;
                                }
                                @include media('<wide-tab') {
                                    padding-bottom: 17px;
                                }
                                @include media('<=phoneland') {
                                    padding-bottom: 7px;
                                }
                                .image-wrap {
                                    position: relative;

                                    float: left;

                                    max-width: 45%;
                                    height: auto;
                                    margin: 0 28px 20px 0;
                                    padding: 7px 0 0;

                                    @include media('<1200px') {
                                        width: 100%;
                                        max-width: none;
                                        margin: 0 0 25px;
                                        padding: 0;
                                    }
                                    @include media('<=phoneland') {
                                        margin: 0 0 22px;
                                    }
                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                    .image-block-text {
                                        font-size: 16px;
                                        line-height: 31px;

                                        position: absolute;
                                        z-index: 1;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;

                                        box-sizing: border-box;
                                        height: 31px;
                                        padding: 0 16px;

                                        color: $white;
                                        background: $body-text;

                                        @include Font(Proxima-Reg);
                                    }
                                }
                                .article-heading {
                                    font-size: 31px;
                                    line-height: 37px;

                                    display: block;

                                    margin: 0 0 14px;

                                    color: $greyish-brown;

                                    @include Font(Proxima-Light);
                                    @include media('<1200px') {
                                        margin: 0 0 25px;
                                    }
                                    @include media('<=phoneland') {
                                        font-size: 28px;
                                        line-height: 32px;

                                        margin: 0 0 20px;
                                    }
                                }
                                .search-result-title {
                                    display: none;
                                }
                                .date-author-container {
                                    font-size: 16px;
                                    line-height: 18px;

                                    position: relative;

                                    display: inline-block;

                                    margin-bottom: 16px;
                                    padding-left: 43px;

                                    @include media('<1200px') {
                                        margin-bottom: 20px;
                                    }
                                    .icon {
                                        position: absolute;
                                        z-index: 1;
                                        top: 2px;
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .article-excerpt-container {
                    .image-block-text,
                    .date-author-container .icon-fill,
                    .image-wrap img,
                    .article-image-wrap img {
                        transition: all 300ms;
                    }
                    &:hover {
                        cursor: pointer;
                        .read-more-link {
                            color: $charcoal-grey;
                        }
                        .image-block-text {
                            background: $charcoal-grey;
                        }
                        .image-wrap {
                            .block-title {
                                background: $charcoal-grey;
                            }
                            img {
                                -webkit-filter: grayscale(100%);
                                    -ms-filter: grayscale(100%);
                                        filter: grayscale(100%);
                                        filter: gray;
                            }
                        }
                        .article-image-wrap {
                            img {
                                -webkit-filter: grayscale(100%);
                                    -ms-filter: grayscale(100%);
                                        filter: grayscale(100%);
                                        filter: gray;
                            }
                        }
                        .date-author-container {
                            .icon-fill {
                                fill: $charcoal-grey;
                            }
                        }
                    }
                }
                .back-to-page {
                    margin: 30px 0;

                    @include media('<1200px') {
                        margin: 16px 0 23px;
                    }
                    @include media('<wide-tab') {
                        margin: 26px 0 23px;
                    }
                    @include media('<tabport') {
                        margin: 0 0 23px;
                    }
                    .back-to-page-link {
                        padding-left: 35px;

                        @include media('<=phoneland') {
                            padding-left: 28px;
                        }
                        .back-to-page-text {
                            font-size: 18px;
                            line-height: 22px;

                            @include media('<=phoneland') {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .sidebar {
                float: right;

                width: 23%;

                @include media('<1200px') {
                    width: 31%;
                }
                @include media('<wide-tab') {
                    width: 33.5%;
                }
                @include media('<tabport') {
                    float: none;

                    width: 100%;
                }
                .sidebar-widgets-container {
                    .sign-up-widget {
                        padding-bottom: 0;

                        @include media('<1200px') {
                            padding-top: 42px;
                        }
                        .sign-up-form {
                            padding-bottom: 44px;

                            border-bottom: 1px solid $white;

                            @include media('<tabport') {
                                padding-bottom: 31px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    img[src=''] {
        background-image: url(/img/onErrorImg/png) center center;
    }
    
    .imgError { //@TODO is this eventCal?
        overflow: hidden;

        min-height: 110px;
        max-height: 110px;

        background: url(/img/Lockton_white.png) center center;
        background-size: 100% auto;
    }
    .results-count {
        display: none;
    }
    .noResults {
        padding-top: 2em;
    }
}
svg {
    background-color: transparent!important;
}
.icon {
    background-color: transparent!important;
    &-video {
        width: 28px;
        height: 28px;
    }
    &-presentation {
        width: 25px;
        height: 25px;
    }
    &-document {
        width: 19px;
        height: 23px;
    }
    &-template {
        width: 21px;
        height: 25px;
    }
    &-all {
        width: 25px;
        height: 25px;
    }
    &-event {
        width: 30px;
        height: 41px;
    }
    &-downloadBox {
        width: 30px;
        height: 32.05px;

        fill: #DDDDDD;
    }
}
.file-download {
    line-height: 32px;
    
    position: relative;

    margin: 12px 0;
    float:left;
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
    a span {
        font-size: 20px;
        font-weight: 400;

        display: inline-block;

        padding-left: 40px;

        vertical-align: top;
    }
}

.block-title.bottom {
    left: 0;
    right: 0;
    bottom: 0;
    height: 31px;
    padding: 7px 16px;
    @include Font(Proxima-Reg);
    font-size: 16px;
    line-height: 17px;
    
    @if($site == mobility){
        max-height:2px;
        padding:0!important;
    }
}

@if ($site == solicitors) {
    .author-block a,
    .read-more-link{
        color: $b_accent;
    }
}


// Resource types:
$newstypes: news,event,guidance,cpd,proposalform;

@each $type in $newstypes {
    .newstype_#{$type} {
        .block-title {
            background-color: map-get($newstype-colors,#{$type});
            @if($site == mobility) {
                &.bottom{
                    color: transparent!important;
                }
            }
        }
        .icon svg {
            fill: map-get($newstype-colors,#{$type});
        }
    }
}
