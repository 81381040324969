.pf-admin, .pf-dashboard{
	.table {
        display: table;

        width: 100%;
    }
    .table .two-third,
    .table .one-third {
        position: relative;

        display: table-cell;

        box-sizing: border-box;

        vertical-align: top;

		@include media("<=850px") {
            display: block;

            width: 100%;
        }
    }
    .table .one-third {
        width: 33.33%;
        @include media("<=850px") {
        	margin-top: 30px;
        }
    }
    .table.summary .one-third {
        width: 425px;
        @include media("<=desktop") {
        	width: 225px;
        }
    }

    // Table Styles
    table tfoot:before {
        display: block;

        height: 5px;

        content: '';
    }
    table tfoot tr {
        border: 1px solid #939598;
    }
}
.pf-admin{
	 /*table style*/
    table {
        font-size: 16px;

        border-collapse: separate;
    }
    table thead:after {
        display: block;

        height: 5px;

        content: '';
    }
    table thead tr th:not(:empty) {
        border: 1px solid #939598;
    }


    table tbody {
        border: 1px solid #939598;
        border-bottom: 0;
        background: #F1F2F2;
    }
    table tbody tr {
        padding: 5px;

        border-bottom: 1px solid #939598;
    }
    table tbody tr:nth-of-type(odd) {
        background: #E6E7E8;
    }
}
.pf-dashboard{
	    /*table style*/
    table {
        font-size: 16px;
    }
    table thead {
        font-size: 18px;
        font-weight: 400;
    }
    table thead th {
        font-weight: 400;
    }

    table tfoot tr {
        border: 1px solid #939598;
    }
    table tfoot tr td {
        padding: 5px 5px 5px 0;
    }
    table tbody {
        border: 0;
        border-bottom: 2px solid $a_background;
        background: #FAFAF9;
    }
    table tbody tr {
        padding: 5px;

        border-bottom: 1px solid $white;
    }
    table tbody tr td {
        box-sizing: border-box;
        padding: 5px 5px 5px 0;
    }
    table tbody tr:nth-of-type(odd) {
        background: #DDE1E2;
    }
}