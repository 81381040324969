section.notification-bar {
	background-color: $slate-grey;
	padding: 17px 0 15px;

	.bounds {
		.align-left, .align-right {
			line-height: 21px;
			color: $white;

			.notification-icon {
				width: 21px;
				height: 21px;
				display: inline-block;
    			vertical-align: middle;
				svg{
					display: block;
					fill: $white;
					width: 100%;
					height: 100%;
				}
			}
			.notification-counter-rounded {
				display: inline-block;
				height: 20px;
				width: 20px;
				margin-right: 10px;
				font-size: 12px;
				text-align: center;
				background-color: $b_accent;
				border-radius:50%;
			}
			.notification-message {
				display: inline-block;
				@include Font(Proxima-RegIt);
				font-size: 14px;
				color: #faf7f7;

				@include media("<tabport") {
					display: none;
				}
			}
			.go-to-my-lockton {
				display: block;
				font-size: 10px;
				line-height: 22px;
				text-transform: uppercase;
				color: $white;

				span {
					padding: 0 2px 0 5px;
				}

				&:hover, &:active {
					color: $link-primary;
				}
			}
		}
		.align-right {
			margin-left: 25px;
		}
	} 
}