
/***************** MEDIA QUERIES**************/

$breakpoints: (
  'phoneport'	: 320px,
  'phoneland'	: 568px,
  'tabport'		: 768px, 
  'wide-tab'	: 991px, 
  'desktop'		: 1024px
);

/***************** FONTS *******************/

$mp_proxima: 'proxima-nova', sans-serif;

/***************** COLORS *******************/

// Global Styles
$background-grey: $light-grey-91!default;
$body-text: $greyish-brown!default;

// Main Colours
$accent-primary: $cerulean!default;
$accent-secondary: $nasty-green!default;
$link-primary: $cerulean!default;
$hover-primary: $greyish-brown!default;

//Greys
$dark-grey: $greyish-brown!default;
$mid-grey: $cool-grey!default;
$light-grey: $light-grey-91!default;

// Success/Error/Warn
$success-color: $apple!default;
$error-color: $rouge-two!default;
$warn-color: $dull-orange!default;

// Section Colours

//Header
$header: (
    logoBg        	: $dark-slate-blue,
    navIconFill 	: $petrol
)!default;

$footer: (
    copywriteBg     : $dark-slate-blue,
    copywriteBorder : $petrol
)!default;

// Left header style
$a_background: $light-navy-blue!default;
$a_text: $white!default;
$a_accent: $accent-primary!default;
$a_other: transparent!default;

// Right hedaer style
$b_background: $light-grey-91!default;
$b_text: $greyish-brown!default;
$b_accent: $accent-secondary!default;
$b_other: $white!default;


//Sidebar widget Style
$side_background: $light-grey-91!default;
$side_text: $greyish-brown!default;
$side_background_b: $light-grey-91!default;
$side_text_b: $white!default;
$side_border: transparent!default;
$side-icons: $silver!default;



/****************************/
/*  	    IMAGES          */
/****************************/

// Banner
$banner-bg-img: "/img/mobility/banner-image.jpg"!default;
$topsection-bg-img: "/img/building.jpg"!default;
$login-bg-img: "../img/background-new.jpg"!default;


/****************************/
/* 	      COMPONENTS        */
/****************************/

// BUTTONS
// btn_a
$a_btn-border     : $rouge!default;
$a_btn-text       : $white!default;
$a_btn-background : $rouge!default;

$a_btn-hover-border     : $accent-primary!default;
$a_btn-hover-text       : $white!default;
$a_btn-hover-background : $charcoal-grey!default;


// btn_b
$b_btn-border     : $accent-primary!default;
$b_btn-text       : $a_btn-text!default;
$b_btn-background : $accent-primary!default;

$b_btn-hover-border     : $a_btn-hover-border!default;
$b_btn-hover-text       : $a_btn-hover-text!default;
$b_btn-hover-background : $a_btn-hover-background!default;


// btn_c
$c_btn-border     : $accent-primary!default;
$c_btn-text       : $a_btn-text!default;
$c_btn-background : $accent-primary!default;

$c_btn-hover-border     : $a_btn-hover-border!default;
$c_btn-hover-text       : $a_btn-hover-text!default;
$c_btn-hover-background : $a_btn-hover-background!default;


// FAQ
$faq-heading-color      : $body-text!default;
$faq-plusminus-color    : $body-text!default;
$faq-bordercolor        : $light-grey!default;
$faq-header-bg_open     : rgba($faq-bordercolor,.3)!default;
$faq-trigger-color      : $body-text!default;	
$faq-trigger-bg         : transparent!default;
$faq-trigger-bg_open    : $accent-secondary!default;
$faq-trigger-color_open : $white!default;


// Tabs
$tab-heading-color        : $body-text!default;
$tab-heading-color_active : $body-text!default; 
$tab-heading-bg           : $white!default;
$tab-heading-bg_active    : $white!default;


//Main Tabs Block (Homepage & Dashboard)
$mainTabs: (
    tabTopBg     		   	: $light-grey,
    tabTriggerBg 			  : $petrol,
    tabTriggerBgActive 	: $petrol,
    tabTriggerBorder 	 	: $accent-secondary,
    tabTriggerSpacer 	 	: $white,
    tabTriggerIconBg 	 	: $petrol,
    tabTriggerIconBgHov : $accent-primary,
    tabTriggerIconColor	: $white,
    inactive            : #CCCCCC,
    done                : #93BE30
)!default;


// Location Tabs
$locationTabs: (
    btnBg           :   $silver-three,
    btnText         :   $greyish-brown,
    btnBgActive     :   $a_background,
    btnTextActive   :   $a_text,
    sectionBorder   :   $silver-three,
    sectionBg       :   $background-grey
  )!default;

// HEADBAND
$headband: (
    background  :   $a_background,
    text        :   $a_text,
    hover       :   $a_accent
  )!default;

$datatables: (
	thBg        :   $blue-grey,
	thText      :   $white
)!default;

// pushNav
$pushNav: (
    background  :   $light-grey,
    text        :   $slate-grey,
    hover       :   $a_accent,
    border      :   $warm-grey-two
  )!default;

/****************************/
/* 	      THEMES            */
/****************************/
//Content types
$newstype_news: $cerulean!default;
$newstype_event: $sick-green!default;
$newstype_guidance: $dull-orange!default;
$newstype_cpd: $link-primary!default;
$newstype_proposalform: $accent-primary!default;

$newstype-colors: (
  news: 		$cerulean,
  event: 		$sick-green,
  guidance: 	$dull-orange,
  cpd: 			$link-primary,
  proposalform: $accent-primary
)!default;

$news-download: $light-burgundy!default;

// Products and s4rvices
$insurance_product: $accent-primary!default;
$insurance_service: $link-primary!default;


//Banner styles
$banner-text: $light-navy-blue!default;


/****************************/
/* 	      LAYOUTS           */
/****************************/

$login: (
	background 	: 	$a_background,
	btnBg 		: 	$a_btn-background,
	btnBorder	: 	$a_btn-border,
	btnText 	: 	$a_btn-text,
	btnReset	: 	$a_other
)!default;

$homepage: (
    main-content-bg	 	: transparent,
    tabTopBg     	 	: $light-grey
)!default;

/********************************************/
// DASHBOARD
/********************************************/

//DASHBOARD VARIABLES
$policyActionStates: (
    warning     : #922545,
    needsaction : #D17B2F,
    inprogress  : #0086BF,
    inactive    : #CCCCCC,
    done        : #93BE30
)!default;

$policyStatuses: (
    not-started : map-get($policyActionStates, warning),
    open        : map-get($policyActionStates, inprogress),
    started     : map-get($policyActionStates, inprogress),
    locked      : map-get($policyActionStates, warning),
    idle        : map-get($policyActionStates, inprogress),
    inactive    : map-get($policyActionStates, inactive),
    returned    : map-get($policyActionStates, warning),
    complete    : map-get($policyActionStates, done),
    retracted   : map-get($policyActionStates, warning),
    submitted   : map-get($policyActionStates, inprogress),
    not-renewed : map-get($policyActionStates, warning)
)!default;



