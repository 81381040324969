
.header,
#mainheader.header {
    z-index: 12;
    .flex-nav {
        padding: 12px 32px;

        //@include media("<=wide-tab") {
        // 	-ms-flex: none;
        //	-webkit-flex: none;
        //	flex: none;
        //	position: relative;
        //	display: block;
        // }
        @include media("<=phoneland") {
            padding-top: 9px;
            padding-bottom: 9px;
            @if ($site == accountants) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
            @if ($site == mobility) {
                padding: 0 16px;
            }
        }

        .logo {
            position: relative;
            max-width: 215px;
            padding: 25px 41px 32px 0;
            background: linear-gradient(to right, map_get($header, logoBg) 350px, white 351px);
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            z-index: 1;

            text-align: left;

            @if ($site == lsos) {
                max-width: 355px;
                min-width: 350px;
            }

            @if ($site == mobility) {
                padding: 0;
            }

            @include media("<1200px") {
                padding: 20px 41px 26px 0;
                max-width: 138px;
                @if ($site == lsos) {
                    max-width: 300px;
                    min-width: 298px;
                }

                @if ($site == mobility) {
                    padding: 0;
                }

            }

            @include media("<=desktop") {
                @if ($site == lsos) {
                    padding: 20px 32px 26px 0;
                    max-width: 300px;
                    //min-width: 230px;
                }

                @if ($site == mobility) {
                    padding: 0;
                }
            }

            @include media("<=wide-tab") {
                //-ms-flex: none;
                //-webkit-flex: none;
                //flex: none;
                //display: block;
                //float: left;
            }
            @include media("<=phoneland") {
                padding: 12px 32px 13px 0;
                @if ($site == accountants) {
                    margin: 0;
                    width: auto;
                }

                @if ($site == mobility) {
                    padding: 0;

                    height: 56px;
                    margin: 6px 0;
                }

                @if ($site == lsos) {
                    max-width: 240px;
                    min-width: 230px;
                }
            }

            a {
                display: inline-block;

                img, svg {
                    width: 112px;
                    height: 54px;

                    @if ($site == mobility) {
                        width: 131px;
                        height: 81px;
                    }

                    @include media("<1200px") {
                        width: 97px;
                        height: 48px;
                    }
                    @include media("<=phoneland") {
                        width: 84px;
                        height: 39px;
                    }
                }
            }

            &::after {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 2500px;
                margin-left: -2500px;
                content: "";
                background-color: map_get($header, logoBg);
                z-index: 1;
            }
        }

        .logo_lsos { // @FIXME needs to be secondary logo
            display: inline-block;

            margin-left: 35px;

            vertical-align: top;

            @include media('<=desktop') {
                margin-left: 20px;
            }

            @include media('<=phoneland') {
                margin-left: 16px;
            }
            img {
                min-width: 97px;
                max-width: 154px;
                height: auto;
                @include media('<=1200px') {
                    width: 120px;
                }
                @include media("<=desktop") {
                    width: 120px;
                }
                @include media('<=phoneland') {
                    width: 97px;
                }
            }
        }

        .mobile-menu-button {
            position: absolute;
            right: 20px;
            top: 50%;
            width: 26px;
            height: 20px;
            margin-top: -10px;
            padding: 0;
            z-index: 50;
            background: url("../img/mobile-icon.svg") no-repeat;
            background-size: cover;

            @include media("<=wide-tab") {
                display: block;
            }

			@include media("<phoneland") {
				right:16px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.main-nav-wrap {
			
		    flex: 1 1 auto;
		    z-index:20;

		    @include media("<=wide-tab") {
		    	-ms-flex: none;
				-webkit-flex: none;
				flex: none;
				display: block;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				width: 384px;
				margin-right: -386px;
				padding: 55px 0 20px 28px;
				overflow-y: scroll;
				background: map_get($pushNav,background);
				background-size:cover;
				z-index: 1000;
				transition: margin 700ms;

				&.menu-opened {
			    	margin-right: 0;
				}
		    }
		    @include media("<=phoneland") {
		    	width: 100%;
				margin-right: -100%;
				padding: 20px 0 20px 16px;

				&.menu-opened {
			    	margin-right: 0;
				}
		    }

		    .mobile-nav-header {
		    	position: relative;
		    	padding-right: 58px;
		    	margin-bottom: 62px;

		    	@include media("<=phoneland") {
		    		margin-bottom: 35px;
		    		padding-right: 45px;
		    	}

		    	.mobile-nav-title {
		    		float: left;
		    		padding-top: 12px;
				 	@include Font(Proxima-Reg);
					font-size: 16px;
					line-height: 20px;
					text-align: left;
					color: map_get($pushNav,text);
		    	}
		    	.mobile-nav-close {
		    		position: absolute;
		    		right: 33px;
		    		top: 0;
		    		width: 14px;
		    		height: 14px;
		    		padding: 0;
		    		background: url("../img/close-icon.svg") no-repeat center center;
		    		background-size: cover;
		    		z-index: 100;

		    		&:hover {
		    			cursor: pointer;
		    		}
		    		@include media("<=phoneland") {
		    			right: 20px;
		    		}
		    	}
		    }
		    .main-nav {
				-webkit-flex: 1 1 auto;
			    -ms-flex: 1 1 auto;
			    flex: 1 1 auto;
				-webkit-justify-content: space-around;
			    -ms-flex-pack: distribute;
			    justify-content: space-around;
			    font-size: 0;

			    @include media("<=wide-tab") {
			    	-ms-flex: none;
					-webkit-flex: none;
					flex: none;
					display: block;
					min-width: 0;
			    }

				&>li {
					position: relative;
					
				    flex: 1 1 10%;
					text-align: center;
					font-size: 16px;
					line-height:42px;

					padding: 15px;

					display:flex;
					align-items:center;


                    &:not(.menu-button){
                        @include media("<=wide-tab") {
                            display:none;
                        }
                    }

					&.nav-icons-wrap{
						// flex: 1 1 5%;
						@include media("<=wide-tab") {
				    		border-bottom: 0px none;
				    		padding-left:0;
				    		a{
				    			padding-left:15px;
				    		}
				    	}

				    
					}

					&>ul.main-nav-icons {
						position: static;
						display: block;
						font-size: 0;

						&>li {
							display: inline-block;
							width: 50%;
							vertical-align: middle;
							font-size: 16px;
							// padding: 15px;
							line-height:42px;

							@if($site == mobility) {
								width:100%;
							}

							@include media("<=wide-tab") {
								width: 100%;

						    }
						
							&>a {
								display: inline-block;
								width: 100%;
								// height: 81px;
								line-height: 21px;
								vertical-align: middle;
								@include Font(Proxima-Light);
								color: $charcoal-grey;

								// @include media("<1200px") {
								// 	padding: 19px 12px;
								// }

								@include media("<=wide-tab") {
									height: auto;
									// padding: 15px 25px 15px 0;
									overflow: hidden;
									text-align: left;								
							    }

								.nav-icon {
									transition: all 250ms;
								}

								&:hover, &:active {
									text-decoration: none;
									// color: $link-primary;
									@if($site == solicitors) {
										color: $accent-secondary;
									} @else{
										color: $link-primary;
									}

									.nav-icon, svg {
										
										@if($site == solicitors) {
											fill: $accent-secondary;
										} @else{
											fill: $link-primary;
										}
									}


								}

								span {
									display: block;
									font-size: 10px;
									line-height: 22px;
									text-transform: uppercase;
									white-space: nowrap;

									@include media("<=wide-tab") {
										float: left;
										@include Font(Proxima-Reg);
										font-size: 16px;
										line-height: 20px;
										@if($site == solicitors) {
											color: $accent-secondary;
										} @else{
											color: $link-primary;
										}
										text-transform: capitalize;
										color: map_get($pushNav,text);
										&:hover{
											color: map_get($pushNav,hover);
										}
									}
								} 
								svg {
									transition: all 250ms;
  									fill: map_get($header,navIconFill);

									@include media("<=wide-tab") {
										float: right;
									}
								}
								.login {
								  width: 14px;
								  height: 20px;
								}
								.search {
								  width: 21px;
								  height: 20px;
								}
							}

							&.main-nav-login {
								position: relative;

								@include media("<=wide-tab") {
									max-height: 52px;
									transition:none;
									border-bottom: 2px solid map_get($pushNav,border);

									@if($site == mobility) {
										max-height: 38px;
									}

									&.hidden-box-opened {
										max-height: 300px;
										transition: all 0.6s linear;
									}
								}
								@include media("<=phoneland") {
							    	border-bottom: 1px solid map_get($pushNav,border);
							    }
								
								.logInBox-sibling {
									position: relative;
									padding-right: 5px;
									z-index: 12;

									@include media("<=wide-tab") {
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: auto;
										padding-right: 27px;
										// padding-left: 0;
										z-index: 10;									
										transform: rotate3d(1, 0, 0, 0deg);
										opacity: 1;
										transition:none;

										&.hidden-box-opened {
											z-index: -10;
											transform: rotate3d(1, 0, 0, 90deg);
											opacity: 0;
											transition: all 0.25s linear;
										}
									}
									@include media("<=phoneland") {
										padding-right: 19px;
									}

									&.hidden-box-opened {
										color: $link-primary;

										.nav-icon, svg {
											fill: $link-primary; //@FIXME 0 this just doesn't wori. Appears to be making hte icon light up but class isn't present
										}
									}
								}

								.logInBox-container {
									display: none;
									position: absolute;
									top: 100%;
									right: 14px;
									width: 330px;
									height: auto;
									padding: 29px 35px 32px;
									background: $light-grey;
									z-index: 9999999999;
									box-sizing: border-box;

									&::after {
										display: block;
										position: absolute;
										content: '';
										right: 8px;
										top: -37px;
										border-top: 20px solid transparent;
										border-left: 11px solid transparent;
										border-right: 11px solid transparent;
										border-bottom: 20px solid $light-grey;
										z-index: 1;
									}

									@include media("<=wide-tab") {
										display: block;
										position: relative;
										top: auto;
										right: auto;
										width: 100%;
										height: auto;
										padding: 24px 26px 26px 0;
										background: inherit;
										z-index: -5;										
										transform: rotate3d(1, 0, 0, -90deg);
										opacity: 0;
										
										transition:none;

										&.hidden-box-opened {
											z-index: 10;
											transform: rotate3d(1, 0, 0, 0deg);
											opacity: 1;
											transition:all 0.25s linear 0.25s;
										}

										&::after {
											display: none;
										}
									}
									@include media("<=phoneland") {
										padding-right: 18px;
									}

									.form-fields-wrap {
										.form-field {
											margin-bottom: 12px;

											&:first-child {
												margin-bottom: 15px;
											}

											label {
												display: block;
												@include Font(Proxima-Light);
												font-size: 14px;
												line-height: 17px;
												text-align: left;
												color: $charcoal-grey;
											}
											input {
												width: 100%;
												height: 29px;
												padding: 0 10px;
												margin-top: 4px;
												@include Font(Proxima-Light);
											    font-size: 16px;
											    line-height: 27px;
											    color: #565656;
												background-color: $white;
												border: solid 1px $mid-grey;
												border-radius: 0;
												box-sizing: border-box;
												transition:all 300ms;

												&:focus {
										            border: 1px solid $a_accent;

										            box-shadow:inset 0 0 5px rgba(141,198,63,0.3);
										        }

											}
										}
										.form-row {
											.form-links {
												float: left;
												padding: 7px 0;
												line-height: 0;
												font-size: 0;

												a {
													position: relative;
													display: inline-block;
													padding: 0;
													@include Font(Proxima-Light);
												    font-size: 12px;
												    line-height: 15px;
												    text-align: left;
												    color: $charcoal-grey;

												    &:hover, &:active {
														text-decoration: none;
														color: $link-primary;
													}

													&:not(:first-child) {
														margin-left: 10px;

														&::before {
															display: block;
															position: absolute;
															content: "";
															left: -5px;
															top: 3px;
															bottom: 4px;
															width: 1px;
															background-color: $charcoal-grey;
															z-index: 1;
														}
													}
												}
											}
											.form-submit {
												float: right;
											}
										}
									}
								}
							}
							&.main-nav-search {
								position: relative;

								@include media("<=wide-tab") {
									max-height: 52px;
									transition:none;

									@if($site == mobility) {
										max-height: 38px;
									}

									&.hidden-box-opened {
										max-height: 150px;
										transition: all 0.5s linear;
									}
								}

								.searchBox-sibling {
									position: relative;
									padding-left: 5px;
									z-index: 12;

									&.hidden-box-opened {
										color: $link-primary;

										.nav-icon, svg {
											fill: $link-primary;
										}
									}

									@include media("<=wide-tab") {
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: auto;
										padding-right: 25px;
										padding-left: 0;
										z-index: 10;									
										transform: rotate3d(1, 0, 0, 0deg);
										opacity: 1;
										transition:none;

										&.hidden-box-opened {
											z-index: -10;
											transform: rotate3d(1, 0, 0, 90deg);
											opacity: 0;
											transition:all 0.25s linear;
										}

										svg {
											width: 17px !important;
											height: 16px !important;
											margin-top: 2px;
										}
									}
									@include media("<=phoneland") {
										padding-right: 17px;
									}
								}

								.searchBox-container {
									display: none;
									position: absolute;
									top: 100%;
									right: 20px;
									width: 378px;
									height: auto;
									padding: 32px 33px 31px;
									background: $light-grey;
									z-index: 10;
									box-sizing: border-box;

									&::after {
										display: block;
										position: absolute;
										content: '';
										right: 10px;
										top: -37px;
										border-top: 20px solid transparent;
										border-left: 11px solid transparent;
										border-right: 11px solid transparent;
										border-bottom: 20px solid $light-grey;
										z-index: 1;
									}

									@include media("<=wide-tab") {
										display: block;
										position: relative;
										top: auto;
										right: auto;
										width: 100%;
										height: auto;
										padding: 23px 26px 22px 0;
										background: inherit;
										z-index: -5;										
										transform: rotate3d(1, 0, 0, -90deg);
										opacity: 0;
										transition:none;

										&.hidden-box-opened {
											z-index: 10;
											transform: rotate3d(1, 0, 0, 0deg);
											opacity: 1;
											transition:all 0.25s linear 0.25s;
										}

										&::after {
											display: none;
										}
									}
									@include media("<=phoneland") {
										padding-right: 18px;
									}

									.form-fields-wrap {
										.search-field-wrap {
											float: left;
											width: 239px;

											@include media("<=phoneland") {
												width: 70%;
												width: -moz-calc(100% - 75px);
												width: -webkit-calc(100% - 75px);
												width: calc(100% - 75px);
											}

											input {
												width: 100%;
												height: 31px;
												padding: 0 10px;
												@include Font(Proxima-Light);
											    font-size: 16px;
											    line-height: 29px;
											    color: #565656;
												background-color: $white;
												border: solid 1px $mid-grey;
												box-sizing: border-box;
												border-radius: 0;
												transition:all 300ms;

												&:focus {
										            border: 1px solid $a_accent;

										            box-shadow:inset 0 0 5px rgba(141,198,63,0.3);
										        }

										        @include media("<=wide-tab") {
										        	height: 30px;
										        	line-height: 28px;
										        }
											}
										}
										.search-submit-wrap {
											float: right;

											.btn {
												width: 65px;
												height: 31px;
												padding: 0;
												line-height: 31px;
												text-align: center;

												@include media("<=wide-tab") {
													height: 30px;
													line-height: 30px;
												}
											}
										}
									}
								}
							}
						}
					}
					a {
						&:hover, &:active {
							@if($site == solicitors) {
								color: $accent-secondary!important;
							} @else{
								color: $link-primary!important;
							}
							
							background-color: $light-grey;

							text-decoration: none;
							@if($site == mobility){
								background-color: transparent;
							}

							@include media("<=wide-tab") {
								background-color: transparent;
							}
						}
					}
					&>a {
						display: inline-block;
						width: 100%;
						// height: 81px;
						// padding: 15px;
						vertical-align: middle;
						@if($site == mobility) {
							@include Font(Proxima-Reg);
							font-size: 16px;
						} @else {
							@include Font(Proxima-Light);
							font-size: 14px;
						}
						line-height: 21px;
						text-transform: uppercase;
						color: $charcoal-grey;
						box-sizing: border-box;

						// @include media("<1200px") {
						// 	padding: 30px 12px;
						// }
						@include media("<=wide-tab") {
							height: auto;
							// padding: 15px 45px 15px 0;
							@include Font(Proxima-Reg);
							font-size: 16px;
							line-height: 20px;
							@if($site == solicitors){
								color: $accent-secondary;
							} @else{
								color: map_get($pushNav,text);
							}
							text-align: left;
							text-transform: capitalize;
						}

						
					}
					
					&>ul.first-level-submenu {
						position: absolute;
						top: 100%;
						width: 365px;
						left: -50%;
						padding: 27px 18px 0 18px;
						text-align: left;
						background-color: $light-grey;
						box-shadow: 0 2px 3px rgba(0,0,0,0.2);
						box-sizing: border-box;
						z-index: 50;
						display: none;

						@include media("<=wide-tab") {
							display: none;
							position: static;
							width: 100%;
							min-width:0;
							height: auto;
							top: auto;
							left: auto;
							padding: 0 0 15px 20px;
							background-color: transparent;
							box-shadow: none;
							z-index: 1;
						}

						&>li {
							display: block;
							float: left;
							padding: 0 30px;
							box-sizing: border-box;

							@include media("<=wide-tab") {
								position: relative;
								float: none;
								padding: 0 0 0 20px;

								&::after {
									position: absolute;
									display: block;
									top: 14px;
									left: 1px;
									height: 4px;
									width: 4px;
									content: "";
									background-color: $greyish-brown;
									z-index: 1;
									border-radius:50%;
								}
							}

							&>a {
								display: inline-block;
								padding: 0 0 10px 0;
								@include Font(Proxima-Reg);
								font-size: 16px;
								line-height: 20px;
								color: $link-primary;

								text-transform: capitalize;
								text-align: left;

								@include media("<=wide-tab") {
									display: block;
									padding: 0;
									@include Font(Proxima-Light);
									line-height: 33px;
									color: $greyish-brown;
								}
							}

							&>ul.second-level-submenu {
								position: static;
								padding: 0 0 27px 0;

								@include media("<=wide-tab") {
									padding: 5px 0;
									display: none;
									min-width: 0;
									height: auto;
								}

								&>li {
									display: block;
									position: relative;
									padding-left: 28px;

									@include media("<=wide-tab") {
										padding-left: 0;
									}

									&::after {
										position: absolute;
										display: block;
										top: 50%;
										left: 1px;
										height: 4px;
										width: 4px;
										margin-top: -2px;
										content: "";
										background-color: $black-two;
										z-index: 1;
										border-radius:50%;

										@include media("<=wide-tab") {
											height: auto;
											top: 0;
											bottom: 0;
											width: 10px;
											left: 0;
											margin-top: 0;
											content: ">";
											padding: 6px 0;
											color: $greyish-brown;
											@include Font(Proxima-Light);
											font-size: 16px;
											line-height: 21px;
											background-color: transparent;
											border-radius:0;
										}
									}

									&>a {
										display: block;
										padding: 6px 0;
										@include Font(Proxima-Light);
										font-size: 16px;
									    line-height: 21px;
									    color: $black-two;
									    text-transform: capitalize;
										text-align: left;

										@include media("<=wide-tab") {
											position: relative;
											padding: 6px 40px 6px 15px;
											@include Font(Proxima-Light);
											color: $greyish-brown;
											z-index: 5;
										}
									}

									&:not(:first-child) a {
										border-top: solid 0.5px $mid-grey;

										@include media("<=wide-tab") {
											border-top: 0;
										}
									}
								}
							}
						}
					}

					&>ul.two-column-submenu {
						left: -230px;
						width: 714px;

						@include media("<=wide-tab") {
							left: 0;
							width: 100%;
						}

						&>li:first-child {
							width: 316px;

							@include media("<=wide-tab") {
								width: 100%;
							}
						}
						&>li:not(:first-child) {
							width: 362px;

							@include media("<=wide-tab") {
								width: 100%;
							}
						}
					}

					&.has-submenu {
						a.submenu-sibling {
							@include media("<=wide-tab") {
								position: relative;
								padding-right: 45px;
								
								&::before {
									display: block;
									position: absolute;
									right: 30px;
									top: 50%;
									content: "";
									width: 10px;
									height: 2px;
									margin-top: -1px;
									background: rgba(87, 103, 108, 0.9);
									z-index: 1;
								}

								&::after {
									display: block;
									position: absolute;
									right: 34px;
									top: 50%;
									content: "";
									width: 2px;
									height: 10px;
									margin-top: -5px;
									background: rgba(87, 103, 108, 0.9);
									z-index: 1;
								}
							}
							@include media("<=phoneland") {
								&::before {
									right: 20px;
								}
								&::after {
									right: 24px;
								}
							}
							&.submenu-opened {
								@include media("<=wide-tab") {
									&::after {
										display: none;
									}
								}
							}
						}
					}
				}

				li.on>a {

					@if($site == solicitors){
						color: $accent-secondary!important;
					} @else{
						color: $link-primary!important;
					}

					.nav-icon {
						
						@if($site == solicitors){
							fill: $accent-secondary;
						} @else{
							fill: $link-primary;
						}
					}
				}
			}
		}
	}
}

.site-lsos{
	 .page-get-a-quote,
	 .page-request-a-quote{
	 	display:none!important;
	 }
}

.site-locktonmobility{
	 .page-insurance-services,
	 .page-about-us{
	 	display:none!important;
	 }
}
