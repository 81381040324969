
.faq-section {
    padding-bottom: 47px;

    @include media('<=phoneland') {
        padding-bottom: 34px;
    }
    @media only screen and (max-width: 1199px) {
        padding-bottom: 40px;
    }
    .section-heading {
        font-size: 20px;
        line-height: 24px;

        margin: 32px 0 25px;

        color: $faq-heading-color;

        @include Font(Proxima-Light);
        @include media('<wide-tab') {
            margin: 28px 0 24px;
        }

        @if($site == mobility){

            font-weight: 300;
    
            font-size: 24px;
            line-height: 29px;
        }
    }
    .faq-accordion {
        border: solid 2px $faq-bordercolor;
        .accordion-item {
            &:not(:last-child) {
                border-bottom: solid 2px $faq-bordercolor;
            }
            .faq-icon {
                position: absolute;
                top: 14px;
                left: 20px;

                display: block;

                width: 0;
                height: 8px;

                transition: all .3s;

                opacity: 0;
            }
            .accordion-trigger {
                position: relative;

                display: block;

                background-color: $faq-trigger-bg;
                @if $site == 'mobility' {
                    padding: 16px 64px 16px 32px;//Allow for open-close-icon
                } @else {
                    // DEFAULT
                    padding: 7px 45px 7px 14px;
                }
                @if $site != 'mobility' { // simple FAQ plus/minus
                    &::before {
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        right: 15px;

                        display: block;

                        width: 15px;
                        height: 3px;
                        margin-top: -1px;

                        content: '';

                        background: $faq-plusminus-color;
                    }
                    &::after {
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        right: 21px;

                        display: block;

                        width: 3px;
                        height: 15px;
                        margin-top: -7px;

                        content: '';
                        transition: all 150ms;

                        background: $faq-plusminus-color;
                    }
                }
                .title-tert {
                    margin: 0;

                    color: $faq-heading-color;

                    font-weight: 300;
                    @include media('<=phoneland') {
                        font-size: 15px;
                    }
                    @if $site == 'mobility' {
                        font-size: 24px;
                        line-height: 1.62em;
                    } @else {
                        // DEFAULT
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
            .accordion-content {
                @if $site == 'mobility' {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.56em;
                }
                ul {
                    box-sizing: border-box;
                    padding: 20px 50px 15px 30px;

                    list-style: none;

                    @include media('<=phoneland') {
                        padding: 20px 40px 15px 25px;
                    }
                    li {
                        font-size: 14px;
                        line-height: 18px;

                        color: $body-text;

                        @include Font(Proxima-RegIt);
                    }
                }
                p {
                    padding-right: 16px;
                    padding-left: 16px;
                }
            }
            &.accordion-item-open {
                .accordion-trigger {
                    @if $site == 'mobility' {
                        padding-left: 34px;
                    } @else {
                        //DEFAULT
                        padding-left: 42px;
                    }

                    border-bottom: solid 1px $faq-bordercolor;
                    background-color: $faq-trigger-bg_open;
                    .title-tert {
                        @if $site == 'mobility' {
                            font-weight: 300;
                        } @else{
                            //DEFAULT
                            font-weight: 600;
                        }

                        color: $faq-trigger-color_open;
                    }
                    &::after {
                        opacity: 0;
                    }
                }
                .faq-icon {
                    width: 12px;

                    opacity: 1;
                }
                .accordion-content {
                    padding: 16px 0 16px 26px;
                }
            }
        }
    }
    /********************* Rewrited Default Accordion Styles **************************/
    .accordion-content {
        overflow: hidden;

        max-height: 0;

        -webkit-transition: max-height .7s ease-out, opacity .3s ease-out;
             -o-transition: max-height .7s ease-out, opacity .3s ease-out;
                transition: max-height .7s ease-out, opacity .3s ease-out;

        opacity: 0;
    }
    .accordion-item-open .accordion-content {
        max-height: 800px;

        -webkit-transition: max-height 1.2s ease-out, opacity .5s ease-out;
             -o-transition: max-height 1.2s ease-out, opacity .5s ease-out;
                transition: max-height 1.2s ease-out, opacity .5s ease-out;

        opacity: 1;
    }
}
