.alert {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 12px;
	border: 1px solid $light-grey-four;
}

// @deprecated
.alert.green {
	color: $success-color;
}

.alert.success {
	color: $success-color;
}

.alert.red, .alert.error {
	color: $warn-color;
}

.alert.warn {
	color: $warn-color;
	
	.icon-warning-triangle {
		fill: $warn-color;
		float: left;
		margin-right: 10px;
		vertical-align: top;
	}
}