
.form-container {
	.quote-form {
		display: block;
		padding: 22px 32px 30px;
		background-color: $side_background;
		overflow: hidden;
  		position: relative;

		@include media("<wide-tab") {
			padding: 22px 24px 38px;
		}
		@include media("<tabport") {
			padding: 16px 16px 35px;
		}

		input[type="text"], input[type="tel"], input[type="email"], select, textarea, input[type="date"] {
			width: 100%;
			padding: 0 10px;
			@include Font(Proxima-Light);
			font-size: 16px;
			color: $greyish-brown;
			background-color: $white;
			border: solid 1px #dedddd;
			border-radius: 0;

			&:focus {
	            border: 1px solid rgba(0,134,139,0.5);

	            box-shadow:inset 0 0 5px rgba(0,134,139,0.3);
	        }
		}
		input[type="text"], input[type="tel"], input[type="email"], select, input[type="date"] {
			height: 29px;
			line-height: 27px;
			transition: all 300ms;
		}
		textarea {
			padding: 5px 10px 10px;
			height: 169px;
			line-height: 20px;
			resize: none;

			@include media("<tabport") {
				height: 248px;
			}
		}
		label {
			display: block;
			padding: 0 30px 4px 0;
			font-size: 18px;
			line-height: 1.3;
			color: #212121;

			@include media("<=phoneland") {
				font-size: 15px;
				line-height: 24px;
			}
		}
		.form-field {
			position: relative;
			width: 48%;
			margin-bottom: 18px;

			&:nth-child(odd) {
				float: left;
				clear: left;

				@include media("<=phoneland") {
					width: 100%;
					float: none;
				}
			}
			&:nth-child(even) {
				float: right;
				clear: right;

				@include media("<=phoneland") {
					width: 100%;
					float: none;
				}
			}
			.ff-validation {
				&::after {
					display: block;
					position: absolute;
					content: '';
					top: 6px;
					right: 10px;
					width: 16px;
					height: 17px;
					@include Font(Proxima-Black);
					font-size: 19px;
					text-align: center;
					color: $nasty-green;
					z-index: 3;
				}
			}
			input, select {
				&:required:invalid, &:required.error, &.error {
					border: solid 1px #ec1a3b;
					box-shadow:0 0 20px rgba(236,26,59,0.35);

					& ~ .ff-validation {
						&::after {
							background: url("../img/invalid-icon.svg") no-repeat center top;
						}
						&::before {
						    display: block;
						    content: attr(data-err);
						    @include Font(Proxima-Reg);
						    font-size: 16px;
						    line-height: 24px;
						    padding: 3px 0 0;
						    color: #ec1a3b;
						}
					}
				}
				&:required:valid, &.no-error {
					& ~ .ff-validation {
						&::after {
							content: '\2713';
						}
					}
				}
			}
			input[value=""]:required:invalid, select:required.no-error, input:required.no-error, .no-error {
				border: solid 1px #dedddd;
				box-shadow:none;

				&:focus {
		            border: 1px solid rgba(0,134,139,0.5);

		            box-shadow:inset 0 0 5px rgba(0,134,139,0.3);
		        }

				& ~ .ff-validation {
					&::before {
						display: none;
					}
					&::after {
						background: none;
					}
				}
			}
			select:required.no-error.not-selected, .no-error.not-selected {
				& ~ .ff-validation {
					&::after {
						content: '';
					}
				}
			}

			select {
				border-radius: 0;
				padding-right: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				appearance: none !important;

				&::-ms-expand {
				  display: none;
				}

				background-image: url('../img/select-arrow.svg');
				background-position: 96% center;
				background-repeat: no-repeat;
			}
		}
		.form-textarea {
			clear: both;
			margin-bottom: 26px;

			@include media("<wide-tab") {
				margin-bottom: 18px;
			}
		}
		.form-submit {
			text-align: right;
		}
	}
}