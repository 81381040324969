// Bloody clearfix
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
// Use as an @extend on the parent of floated children
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//Visually hidden
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
// Can't recall using this sort of thing
// Also an @extend

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

%list_default{
  list-style: none;
  padding: 20px 20px 8px;

  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 1.5em;
    color: #212121;

    .list-icon {
      @extend %icon_check;
    }
  }
}
%list_links {
    list-style: none;
    li {
        background-color: $side_background;
        a {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.43em;

            position: relative;

            display: block;
            // margin-bottom: 20px;

            padding: 18px 26px;

            color: $body-text;
            &:hover {
                background-color: rgba($accent-secondary,.07);
            }
        }
        .list-icon {
            top: -2px;
            left: 0;

            display: inline-block;

            width: 18px;
            height: 18px;

            margin-right: 18px;

            vertical-align: middle;

            fill: $accent-secondary;
            position:relative;

            svg {
                display: block;

                width: 100%;
                height: 100%;

                fill: $accent-secondary;
            }
        }
    }
}

%icon_check{
  display: block;
  position: absolute;
  left: 0;
  top: 1px;
  @include Font(Proxima-Black);
  font-size: 18px;
  line-height: 0.9;
  color: $accent-secondary;
  z-index: 1;
  height: 18px;
  width: 14px;

  @include media("<=phoneland") {
    top: 0;
    font-size: 20px;
    line-height: 18px;
  }
  svg {
    display: block;
    fill: $accent-secondary;
    width: 100%;
    height: 100%;
  }
}