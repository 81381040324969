
.insurance-services-index-page {
	// .lockton-top-section {
	// 	.two-column-grid {
	// 		.top-section-block {

	// 			@media only screen and (max-width: 1199px) {
	// 				padding-top: 32px;
	// 			}
	// 			@include media("<wide-tab") {
	// 				padding-top: 28px;
	// 			}
	// 		}
	// 		.left-column {
				
	// 			@media only screen and (min-width: 992px) {
	// 				padding-right: 70px;
	// 			}
	// 			@include media("<tabport") {
	// 				display: block;
	// 				width: 100%;
	// 				-ms-flex: none;
	// 				-webkit-flex: none;
	// 				flex: none;

	// 				&::before {
	// 					right: -50%;
	// 					width: 200%;
	// 				}
	// 			}	
	// 		}
	// 		.right-column {
	// 			padding-right: 5%;
	// 			max-width: 34%;	

	// 			@media only screen and (max-width: 1199px) {
	// 				padding-right: 0;
	// 			}
	// 			@include media("<tabport") {
	// 				display: none;
	// 			}
	// 		}
	// 	}
	// }

	.main-content {
		position: relative;
		padding: 25px 0 0;

		@include media("<tabport") {
			padding: 25px 0 75px;
		}

		.bounds {
			.main-content-header {
				position: static;
				padding: 0 32px 0;

				@include media("<=phoneland") {
					padding: 0 16px 25px;
				}

				.social-icons-wrap {
					float: left;
					padding-top: 5px;

					@include media("<tabport") {
						position: absolute;
						bottom: 40px;
						left: 32px;
						float: none;
						padding-top: 0;
						z-index: 5;
					}
					@include media("<=phoneland") {
						left: 16px;
					}
				}
				.select-wrap {
					float: right;

					@include media("<tabport") {
						float: none;
					}

					select {
						width: 218px;
						height: 30px;
						padding: 0 0 0 15px;
						@include Font(Proxima-Light);
						font-size: 16px;
						line-height: 28px;
						color: $slate-grey;
						background-color: $white;
						background-image: url('../img/select-arrow.svg');
						background-position: 94% center;
						background-repeat: no-repeat;
						border: 1px solid $warm-grey-two;
						border-radius: 0;
						box-sizing: border-box;
						-webkit-appearance: none;
						-moz-appearance: none;
						-ms-appearance: none;
						appearance: none !important;

						&::-ms-expand {
						  display: none;
						}

						&:focus {
				            border: 1px solid rgba(0,134,139,0.5);

				            @include box-shadow(inset 0 0 5px rgba(0,134,139,0.3));
				        }

						option {
							@include Font(Proxima-Light);
							font-size: 16px;
							color: $slate-grey;
						}
					}
				}
			}
			.three-column-grid {
				padding-left: 0;
				padding-right: 0;
				margin: 0 16px;

				@include media("<=phoneland") {
					margin: 0 8px;
				}

				.excerpt-wrap {
					width: 33.33%;
					max-width: 33.33%;
					padding-bottom: 40px;

					@media only screen and (max-width: 1199px) {
						width: 50%;
						max-width: 50%;
						padding-bottom: 30px;
					}
					@include media("<tabport") {
						width: 100%;
						max-width: none;
						padding-bottom: 25px;
					}

					.excerpt {
						.read-more-link {
							position: static;
						}
					}
				}
			}
		}
	}
}

.insurance_service{
	.image-wrap{
		img{
			opacity:1;
		}
		border-bottom:3px solid $insurance_service;
		transition: 0.25s ease;
	}
	.block-title{
		background:$insurance_service;
	}
	&:hover{
		.image-wrap{
			img{
				opacity:0.4;
				transition: 0.25s ease;
			}
			border-bottom:3px solid $a_accent;
			@if($site == accountants) {
				border-bottom:3px solid lighten($insurance_service,11.2%);
			}
		}
		.block-title{
			@if($site == accountants) {
				background:lighten($insurance_service,11.2%)!important;
			} @else{
				background:$a_accent!important;
				color: $greyish-brown!important;
			}
		}	
	}
}
.insurance_product{
	.image-wrap{
		img{
			opacity:1;
		}
		border-bottom:3px solid $insurance_product;
		transition: 0.25s ease;
	}
	.block-title{
		background:$insurance_product;
	}
	&:hover{
		.image-wrap{
			img{
				opacity:0.4;
				transition: 0.25s ease;
			}
			border-bottom:3px solid $a_accent;
			@if($site == accountants) {
				border-bottom:3px solid lighten($insurance_product,11.2%);
			}
		}
		.block-title{
			@if($site == accountants) {
				background:lighten($insurance_product,11.2%)!important;
			} @else{
				background:$a_accent!important;
				color: $greyish-brown!important;
			}
		}
	}
}