.importContacts {margin-top:50px; margin-bottom:50px;}
.importContacts-download {margin-bottom:20px;}
.importContacts-download button {margin-top:0;}
.importContacts-upload {}
.importContacts-upload form {
	width:100%;
	max-width:400px;
	padding: 0;
	display: flex;
	align-items: flex-end;
}
.importContacts-upload .jf-row {margin:0;}
.importContacts-upload .jf-row.importContacts-submit {
	width: auto;
}
.jf-row.importContacts-submit .btn_a {
	margin: 0;
	color: #ffffff!important;
}