// Default - setup as per mobility
#icon-open-close {
    #oc-background {
        fill: transparent;
    }
    #oc-plus {
        fill: currentColor;
    }
    #oc-minus {
        fill: inherit;
    }
    #oc-border {
        fill: inherit;
    }
}

// Default - setup as per mobility
.icon-open-close {
    position: absolute;
    top: 50%;
    right: 24px;

    transform: translateY(-50%);
    vertical-align: middle;

    color: $slate-grey;

    fill: $slate-grey;

	height:30px;
	width:30px;

    .tab-title.active &,
    .active .tabtrigger &,
    .accordion-item-open &{
        color: transparent;

        fill: $white;
    }
}
