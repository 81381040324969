.comparison-accordion {
    padding-bottom: 32px;

    .accordion {
        
        @include media('>980px') {
            display: flex;

            margin: 0 -1px;

            justify-content: space-between;
        }
        @include media('<=phoneland') {
            padding:0;
        }
    }
    @include media('>980px') {
        .accordion-item {
            margin: 0 1px;

            flex: 1 1 25%;
            &#comparison2 {
                @include media('<=1240px') {
                    flex-basis: 20%;
                }
                @include media('<=1020px') {
                    flex-basis: 16%;
                }
            }
            &#comparison3 {
                @include media('<=1240px') {
                    flex-basis: 20%;
                }
                @include media('<=1020px') {
                    flex-basis: 17%;
                }
            }
            .accordion-content {
                max-height: 1000px;

                opacity: 1;
            }
        }
    }
    .accordion-trigger{
        position:relative;
        display:block;
        .icon-open-close{
            display:none;
            @include media("<=980px") {
                display:block;
            }
        }
    }
    .accordion-content {
        display: flex;
        overflow: visible;

        justify-content: space-between;
        flex-wrap: wrap;

        transition: all .6s ease-in 0s, opacity 0.4s ease 0.6s;

        @include media('<=980px') {
            overflow: hidden;
        }
        > ul {
            list-style-type: none;

            flex: 1 1 33%;
            @include media('<=1020px') {
                font-size: 14px;
            }
            @include media('<=980px') {
                flex-basis: 10%;
                &:first-child li {
                    padding-left: 10px;
                }
                &:last-child li {
                    padding-right: 10px;
                }
            }

            &.ca-labels {
                display: none;

                flex-grow: 2;
                @include media('<=980px') {
                    display: inline-block;
                }
            }
            &.ca-info {
                display: none;

                max-width: 34px;

                @include media('<=980px') {
                    display: inline-block;
                }
                @include media('<=phoneland') {
                    max-width: 30px;
                }
                .icon-info {
                    position: relative;
                    top: 1px;

                    width: 18px;
                    height: 18px;

                    vertical-align: middle;

                    fill: $silver-two;
                }
                .info-active .icon-info {
                    fill: $light-navy-blue;
                }
            }
            > li {
                line-height: 20px;

                position: relative;

                height: 32px;
                padding: 6px 24px;

                white-space: nowrap;

                background: $light-grey-four;

                @include media('<=1240px') {
                    line-height: 40px;

                    height: 52px;

                    white-space: normal;
                    a {
                        line-height: 20px;

                        display: inline-block;

                        vertical-align: middle;
                    }
                }
                @include media('<=980px') {
                    a {
                        line-height: 17px;

                        display: inline-block;

                        vertical-align: middle;
                        &.info-link {
                            position: relative;
                            top: -2px;
                        }
                    }
                }
                @include media('<=980px') {
                    font-size: 14px;
                    line-height: 17px;

                    height: 29px;
                    padding: 6px 4px;
                }
                 @include media('<=phoneland') {
                    font-size:12px;
                }
                &:nth-child(even) {
                    background: $white-seven;
                }

                a{
                    pointer-events:none;
                }
                .ca-infoBox {
                    font-weight: 400;

                    position: absolute;
                    z-index: 10;
                    top: 34px;
                    right: 8px;

                    line-height: 20px;

                    display: none;

                    width: 280px;
                    padding: 10px;

                    transition: opacity .4s ease 0s;
                    white-space: normal;

                    opacity: 0;
                    border: solid .9px rgba(87, 103, 108, .46);
                    background: $white;
                    box-shadow: 2px 2px 11px 4px rgba(0, 0, 0, .15);
                    ul {
                        padding-left: 20px;

                        list-style-type: disc;
                    }
                }
                .ca-infoBox:before,
                .ca-infoBox:after {
                    position: absolute;
                    z-index: 9;
                    top: -10px;
                    right: 4px;

                    width: 0;
                    height: 0;

                    content: '';

                    border-width: 0 8px 10px 8px;
                    border-style: solid;
                    border-color: transparent transparent rgba(87, 103, 108, .46) transparent;
                }
                .ca-infoBox:after {
                    top: -8px;
                    right: 6px;

                    border-width: 0 6px 8px 6px;
                    border-color: transparent transparent $white transparent;
                }
            }
            > .info-link.info-active > .ca-infoBox {
                display: block;

                opacity: .95;
            }
        }
        .price {
            font-size: 20px;
            font-weight: 800;

            height: 45px;
            padding: 12px 24px;

            color: $white;
            background: $accent-secondary;

            flex-basis: 100%;
            small {
                font-weight: 300;
            }
        }
    }
    .header-column {
        flex-basis: 40%;
        @include media('<=1240px') {
            flex-basis: 30%;
        }
        @include media('<=1080px') {
            flex-basis: 25%;
        }
        @include media('<=980px') {
            display: none;
        }
        ul.ca-info {
            display: inline-block;

            max-width: 34px;

            flex-basis: 10%;
            @include media('<=980px') {
                display: none;
            }
            li {
                padding: 0 16px 0 4px;
            }
        }
    }
    .comparison-title {
        font-size: 24px;
        font-weight: 900;

        min-height: 68px;
        margin: 0;
        padding: 12px 24px;

        color: $white;
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
        background-color: $accent-secondary;

        @include media('<=1020px') {
            font-size: 22px;
        }
        @include media('<=980px') {
            font-weight: 300;

            color: $body-text;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            background-color: $light-grey-four;
        }
        span {
            font-size: 14px;
            font-weight: 400;

            display: block;
        }
    }
    .accordion-item-open {
        .comparison-title {
            @include media('<=980px') {
                font-weight: 900;

                color: $white;
                background-color: $accent-secondary;
            }
        }
        .accordion-content {
            overflow: visible;
        }
    }
    .header-column .comparison-title,
    .header-column .price {
        background-color: $light-navy-blue;
    }
    .comparison-footer {
        justify-content: space-between;
        .block {
            min-width: 0;
        }
        .block + .block {
            text-align: right;

            @include media('<=phoneland') {
                text-align: center;
            }
            .btn_a {
                font-size: 18px;
                line-height: 22px;

                width: 60%;
                max-width: 275px;
                padding: 8px 0;

                text-align: center;

                @include media('<=phoneland') {
                    width:100%;
                    max-width:100%;
                }
            }
        }
    }
    .ipt-notice {
        // margin-top:1em;
        font-size: 14px;

        color: $slate-grey;

        font-weight: 700;
    }
}
.icon-check {
    width: 18px;
    height: 14px;

    fill: $accent-secondary;
}
