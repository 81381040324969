body {
	@include Font(Proxima-Light);
	font-size: 16px;
	line-height: 20px;
	background: $white;
	color: $body-text;
	overflow-x: hidden;

	@include media("<=phoneland") {
		font-size: 15px;
		line-height: 19px;
	}


	h1,h2,h3,h4,h5,h6 {
		font-weight: normal;
	}

	h5 {
		margin: 10px 0 18px;
		@include Font(Proxima-RegIt);
		font-size: 18px;
		line-height: 18px;
		color: #212121;

		@include media("<=phoneland") {
			margin: 15px 0 18px;
			font-size: 14px;
			color: #464646;
		}
	}


	a, input, button, select, textarea {
		text-decoration: none;
		transition: all 250ms;

		&:focus, &:hover, &:active {
			outline: 0;
		}
	}


	a {
		color: $link-primary;
		font-weight: 400;

		&:visited{
			color: $link-primary;
		}

		&:hover, &:active {
			text-decoration: none;
		}
	}

	a{
		&:hover, &:active {
			color: $hover-primary;
		}
	}
	
	img{
		max-width:100%;
	}

	ul, li {
		margin: 0;
		padding: 0;
	}

	ul,ol{
		list-style-position: inside;
	}

	::-webkit-input-placeholder {color: #565656;}
	::-moz-placeholder          {color: #565656;}
	:-moz-placeholder           {color: #565656;}
	:-ms-input-placeholder      {color: #565656;}

	section {
		position: relative;
		display: block;
		width: 100%;
	}

	.bounds {
	    max-width: 1332px;
	    box-sizing: border-box;
	}
	.wide-bounds {
		width: 100%;
		margin: 0 auto;
		max-width: 1440px;
	    box-sizing: border-box;
	}

	.clearfix:after {
	    display: table;
	    clear: both;
	    content: '';
	}
	.page-container {
		position: relative;
		//display: block;
		width: 100%;
		margin-left: 0;

		@include media("<100px") {
			border: 1px solid black;
		}		

		@include media("<wide-tab") {
			//transition: margin 700ms;

			&.menu-opened {
				margin-left: -384px;
			}
		}
		@include media("<=phoneland") {
			&.menu-opened {
				margin-left: -100%;
			}
		}
	}

	.align-left {
		float: left;
	}
	.align-right {
		float: right;
	}
	.visible {
		display: block !important;
	}

	.clear {
		clear: both;
	}

	.hide-for-mobile {
		@include media("<=phoneland") {
			display: none;
		}
	}
	.hide-for-widephone {
		@include media("<tabport") {
			display: none;
		}
	}
	.hide-for-tablet {
		@include media("<wide-tab") {
			display: none;
		}
	}
	.hide-for-ipad {
		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}
	.hide-for-desktop {
		@media only screen and (min-width: 992px) {
			display: none;
		}
	}
	.tablet-visible {
		display: none;

		@media only screen and (min-width: 768px) and (max-width: 1199px) {
			display: block;
		}
	}
	.desktop-hidden {
		@media only screen and (min-width: 1200px) {
			display: none;
		}
	}
	.tablet-hidden {
		display: none;
		
		@media only screen and (min-width: 1200px) {
			display: block;
		}
		@media only screen and (max-width: 767px) {
			display: block;
		}
	}

}

	a, input, button, select, textarea {
		text-decoration: none;
		transition: all 250ms;

		&:focus, &:hover, &:active {
			outline: 0;
		}
	}

	a {
		color: $link-primary;
		font-weight: 400;

		&:visited{
			color: $link-primary;
		}

		&:hover, &:active {
			text-decoration: none;
		}
	}

	a {
		&:hover, &:active {
			color: $hover-primary;
		}
	}
