.banner {
    background: center bottom url($banner-bg-img);
    background-size: cover;
    @if($site == mobility) {
        height: 100%;
        height: 100vh;
        max-height: 715px;
        margin-top: -109px;
        // margin-bottom: 86px; // Increased for mobility :After;
        padding: 165px 0 78px;

        background-position-x:right;

        @include media('<=wide-tab') {
            background-position-x:90%;
        }
        @include media('<=phoneland') {
            margin-top: 0;
            padding: 26px 0 26px;
            background-position-x:75%;
        }
    } @else {
        margin-bottom: 32px;
        padding: 82px 0 78px;

        @include media('<wide-tab') {
            padding: 52px 0 48px;
        }
        @include media('<=phoneland') {
            padding: 32px 0 28px;
        }
    }
    &:after {
        font-size: 24px;
        line-height: 2.25em;

        position: absolute;
        bottom: 0;

        display: block;

        width: 100%;
        height: 17px;
        // @if($site == mobility) {
        // content: 'Lockton Mobility Price Match Means We\'ll Never Be Beaten On Price';
        // height: 54px;
        // bottom: -54px;
        // @include media("<=phoneland") {
        // font-size: 18px;
        // line-height: 1.33em;
        // height:72px;
        // padding:12px;
        // }
        // } @else {

        content: '';
        // }
        text-align: center;

        color: $white;
        background-color: $accent-secondary;

        @include Font(Proxima-RegIt);
    }
    .banner-contentwrap {
        display: flex;
        @if($site == mobility) {
            justify-content: flex-start;
            @include media('<=phoneland') {
                flex-wrap: wrap;
            }
        } @else {
            justify-content: flex-end;
        }
    }
    .banner-content {
        @include media('<=phoneland') {
            flex-basis: 100%;
        }
        @if($site == mobility) {
            padding-left: 5%;

            @include media('<=wide-tab') {
                padding-left:3%;
            }

            @include media('<=phoneland') {
                padding-left: 0;

                order: 1;
            }
        }
        .title_site {
            font-size: 52px;
            line-height: 1.2em;

            margin-top: -.2em;
            margin-bottom: .25em;

            color: $banner-text;

            @include media('<=1200px') {
                font-size: 46px;
            }
            @include media('<=wide-tab') {
                font-size: 40px;
                line-height: 1em;
            }
            @include media('<=phoneland') {
                font-size: 32px;
                line-height: .89em;
            }
            @include Font(Proxima-Bold);
        }
        .strapline {
            font-size: 28px;

            margin-top: 0;

            color: $banner-text;

            @include media('<=wide-tab') {
                font-size: 24px;
                line-height: 1.2em;
            }
            
            @include media('<=phoneland') {
                font-size: 20px;
                line-height: 1.2em;

                margin-bottom: 12px;
            }
            @include Font(Proxima-Light);
        }
        .banner-phoneno {
            font-weight: 400;
        }
    }
    .banner-widget {
        position: relative;

        width: 352px;
        padding-bottom: 20px;

        border: 1px solid $accent-secondary;
        background: rgba($white,.94);

        @include media('<=desktop') {
            width:300px;
        }
        @include media('<=phoneland') {
            flex-basis: 100%;
            order: 2;
        }
    }
    .banner-header {
        font-size: 17px;
        line-height: 1.41em;

        padding: 8px 0;

        text-align: center;

        color: $white;
        background: $accent-secondary;
        @if ($site == mobility) {
            font-size: 20px;
            line-height: 1.1em;

            padding: 20px 0;

            @include media('<=desktop') {
                font-size: 18px;
            }

            @include media('<=tabport') {
                font-size: 16px;
            }

            @include media('<=phoneland') {
                font-size: 17px;
                line-height: 1.29em;

                padding: 14px 0;
            }
        }
    }
    .banner-main {
        font-size: 17px;
        line-height: 1.41em;

        padding: 18px 26px;
        @if ($site == mobility) {
            font-size: 14px;

            @include media('<=phoneland') {
                padding: 0 16px 8px;
            }
        }
    }
    .banner-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.17em;
    }
    .banner-list {
        margin-bottom: 30px;

        list-style-type: none;
        .list-icon {
            position: absolute;
            top: 3px;
            left: 0;

            display: block;
            svg {
                width: 17.6px;
                height: 13.4px;

                fill: $accent-secondary;
            }
        }
        .banner-listitem {
            position: relative;

            margin-bottom: 18px;
            padding-left: 37px;
        }
    }
    .jsonForm .banner-cta,
    .jsonForm input[type="submit"]:active {
        font-size: 18px;
        line-height: 1.22em;

        width: 100%;
        padding: 12px 0;

        text-align: center;
        text-transform: uppercase;

        @include Font('Proxima-Black');

        @if ($site == mobility) {
            position: absolute;
            top: 100%;

            width: 100%;
            margin-top: 0;

            transform: translateY(50%);

            border-color: $a_btn-border;
            border-radius: 28px;
            background-color: $a_btn-background;
        }
    }
    .banner-balloon {
        font-size: 18px;

        position: absolute;
        top: 38%;
        left: 104%;

        width: 9.4em;
        height: 9.4em;
        padding: 1.46em 1em 1.6em;

        text-align: center;

        color: $white;
        border-radius: 100%;
        background: $rouge-two;
        box-shadow: 3px 3px 14px 5px rgba(0, 0, 0, .18);

        @include media('<=wide-tab') {
            font-size:16px;
            top:45%;
        }
        @include media('<=880px') {
            font-size:15px;
            top:49%;
        }
        @include media('<=phoneland') {
            font-size: 13px;

            top: 24.5%;
            left: 60%;
        }
        &:before {
            position: absolute;
            top: 50%;
            left: -1em;

            width: 0;
            height: 0;
            margin-top: -.3em;

            content: '';

            border-top: .6em solid transparent;
            border-right: 1.2em solid $rouge-two;
            border-bottom: .6em solid transparent;
        }
        .line-one {
            font-size: 1em;
            line-height: 1.8;

            text-align: center;

            @include Font('Proxima-Bold');
        }
        .line-two {
            font-size: 3.444em;
            line-height: 1.0;

            @include Font('Proxima-Bold');
        }
        .line-three {
            font-size: 1em;
            line-height: 1.1;
        }
    }
    .social-icons-wrap {
        position: absolute;
        z-index: 20;
        bottom: -40px;

        width: 100%;
        ul.social-icons {
            text-align: right;
        }
        @if($site == mobility) {
            display: none;
        }
    }
    .icon-down-arrow {
        position: absolute;
        top: 90%;
        left: 50%;

        width: 40px;
        height: 40px;

        transform: translate(-50%,-50%);

        @include media("<=tabport") {
            width: 30px;
            height: 30px;
        }

        @include media("<=phoneland") {
            width: 20px;
            height: 20px;
        }

        svg {
            width: 40px;
            height: 40px;

            fill: $white;

            @include media("<=tabport") {
                width: 30px;
                height: 30px;
            }

            @include media("<=phoneland") {
                width: 20px;
                height: 20px;
            }
        }
    }
    #scrollDown:before {
        position: absolute;
        top: -54%;
        left: -50%;

        width: 200%;
        height: 200%;

        content: '';
        transition: background .4s ease 0.1s,border-color .4s ease 0s;

        border: 3px solid rgba($white,.02);
        border-radius: 50%;
        background: rgba($white,.05);

        @include media("<=tabport") {
            background: rgba($white,.2);
            border-color:rgba($white,.6);
        }

    }
    #scrollDown:hover:before{
        background: rgba($white,.2);
        border-color:rgba($white,.6);
        transition: background .4s ease 0s,border-color .4s ease 0.1s;
    }
}
