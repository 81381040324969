.pf-form_flex {
    // font-weight: 400;
    // line-height: 1.4;
    font-size: 16px;
    line-height: 20px;
    button {
        padding-top: 0;
        padding-bottom: 0;
    }
    .section-title {
        font-size: 24px;
        line-height: 29px;

        margin: 0 0 25px;

        @include media('<tabport') {
            margin: 0 0 20px;
        }
    }
    #jf_jf1 {
        padding-top: 0;
    }
    .accManBasic {
        font-size: 14px!important;
    }
    .page-header {
        // margin-top: 203px;
        padding-bottom: 0;
    }
    #mainheader {
        width: 100%;
    }
    #mainheader.isDashboard + #headband [class^='headericon-']:before,
    #mainheader.isDashboard + #headband [class*=' headericon-']:before {
        margin-bottom: 2px;
    }
    #mainheader.isDashboard + #headband #headerbuttons .headericon-user {
        top: 3px;
    }
    .widget-cta {
        padding-bottom: 0;
        .widget-block.widget-header {
            div:first-child {
                line-height: 1.3em;

                margin-bottom: 4px;
            }
            .notice {
                line-height: 1.25em;
            }
        }
    }
    // .widget-list li:last-child { // Uncomment when recovery comes back in
    //     margin-bottom: 0;
    //     padding-bottom: 12px;
    //     border-bottom: 1px solid $light-grey-five;
    // }
    .widget-block.recovery {
        line-height: 1.5;

        padding: 20px 26px;
        strong {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.4;

            margin-bottom: 8px;
        }
    }
    .recovery-img {
        float: left;

        margin-right: 5px;
    }
    .bounds {
        width: 100%;
        max-width: 1364px;
        // padding: 0;
    }
    .recovery-add {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;

        padding: 5px 12px;

        text-align: center;

        background-color: rgba(darken($light-grey-five,.22),.35);
    }
    .icon-logout:before,
    .headericon-logout:before {
        display: none!important;
    }
    .jsonForm a.nextbutton, .jsonForm input[type="submit"] {
        font-family: 'proxima-nova',sans-serif;
        font-size: 14px;
        line-height: 20px;

        padding: 6px 20px;

        color: $a_btn-text;
        border: 1px solid $a_btn-border;
        border-radius: 28px;
        background-color: $a_btn-background;
    }
}
.readonlyMode.pf-form_flex {
    form#jf_jf1 {
        padding-top: 0;
    }
    .page-header {
        // margin-top: 227px;
    }
}
