.pf-dashboard{

    /*login page style*/
    .login-wrap-wrap {
        position: absolute;
        top: 0;
        bottom: 0;

        display: table;

        width: 100%;
        height: 100%;

        background: url($login-bg-img) center center no-repeat fixed;
        background-size: cover;
    }
    .login-wrap {
        display: table-cell;

        vertical-align: middle;
    }
    .login-box {
        position: relative;

        overflow: hidden;

        width: 90%;
        max-width: 615px;
        margin: 0 auto;

        transition: opacity 1s !important;

        opacity: 0;
        box-shadow: 0 0 70px 5px rgba(0,0,0,.3);

        background: url(../img/blur.jpg) center center no-repeat fixed;
        background: map_get($login,background);
        background-size: cover;

        padding: 45px 45px 100px 45px;

        @include media("<=phoneland") {
            padding: 48px 24px 8px 24px;            
        }

        &.loaded {
            margin-top: 0;

            opacity: 1;
        }
        .jsonForm {
            font-size: 14px;
            font-weight: 300;

            color: $a_text;

            @if( $site == mobility) {
                color:$white;
            }
            > section {
                padding: 20px 0;
            }
            > section fieldset {
                width: 100%;
                max-width: 100%;
                margin: 0;
                padding: 0 20px;


                border:0 none;
                border-left: 3px solid $a_accent;

                @include media("<=phoneland") {
                    border-left:0 none;
                    border-top:2px solid $a_accent;
                    padding:20px 0;
                }
            }
            > section fieldset h1 {
                font-size: 24px;
                font-weight: 300;

                margin: 0;
            }
            .jf-row.jf-input-type-text {
                display: inline-block;

                width: 50%;
                padding-right: 10px;

                @include media("<=phoneland") {
                    width:100%;
                    padding-right:0;
                }
            }
            .jf-row.jf-input-type-text + .jf-row.jf-input-type-text {
                padding-right: 0;
                padding-left: 10px;
                @include media("<=phoneland") {
                    width:100%;
                    padding-left:0;
                }
            }
            .jf-row.jf-input-type-checkbox {
                display: inline-block;

                width: 50%;

                vertical-align: top;
                @include media("<=phoneland") {
                    width:100%;

                    >label {
                        display:inline-block;
                        width:auto;
                    }
                }
            }
            .jf-row > label.login-wrap {
                width: 100%;
                max-width: 100%;
                padding: 10px 0 0 0;
            }
            .jf-row input.login-submit {
                float: right;

                margin-top: 0;

                transition: all .3s !important;

                border-color: map_get($login,btnBorder);
                background: map_get($login,btnBg);
                color:map_get($login,btnText);
                @include media("<=phoneland") {
                    float:none;
                    width:100%;
                }
            }
            .focbed.filled{

            }
            .jf-row input:focus{
                border-color:$a_accent;
            }
            input[type='submit'] {
                transition: all .3s !important;
            }
        }
        .fpw {
            font-size: 12px;

            margin-top: -20px;

            @include media("<=phoneland") {
                margin-top: 0;
            }
            
            a {
                color: map_get($login,btnReset);
            }
        }
    }

    //This element appears to have no use DEPRECATED - element has been removed for next release from develop (280717)
    .login-blur {
        display:none;
    }
    
    .login-logo {
        position: relative;

        display: block;

        margin-bottom: 20px;

        @include media("<=phoneland") {
            margin:0 auto 20px;
        }
    }
    
}