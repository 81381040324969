.btn {
    font-size: 14px;
    line-height: 17px;

    display: inline-block;

    box-sizing: border-box;
    padding: 6px 20px;

    text-transform: uppercase;

    color: $white;
    border: none;
    @if($site == mobility){
        border-radius: 28px;
    }

    @include Font(Proxima-Semibold);
    &:hover,
    &:active {
        color: $white;
    }
}
.btn_a,
.btn_a:visited {
    @extend .btn;
    color: $a_btn-text!important; //Overriden by top-section link colour/dashboard link colour otherwise;
    border: solid 1px $a_btn-border;
    background-color: $a_btn-background;
    &:hover,
    &:active {
        color: $a_btn-hover-text!important;
        border: solid 1px $a_btn-hover-border;
        background-color: $a_btn-hover-background;
    }
}

.btn_b,
.btn_b:visited {
    @extend .btn;
    color: $b_btn-text!important; //Overriden by top-section link colour otherwise
    border: solid 1px $b_btn-border;
    background-color: $b_btn-background;
    &:hover,
    &:active {
        color: $b_btn-hover-text!important;
        border: solid 1px $b_btn-hover-border;
        background-color: $b_btn-hover-background;
    }
}
.btn_c,
.btn_c:visited {
    @extend .btn;
    color: $c_btn-text!important; //Overriden by top-section link colour otherwise
    border: solid 1px $c_btn-border;
    background-color: $c_btn-background;
    &:hover,
    &:active {
        color: $c_btn-hover-text!important;
        border: solid 1px $c_btn-hover-border;
        background-color: $c_btn-hover-background;
    }
    
}
.large-btn {
    line-height: 17px;

    padding: 10px 25px;
}

.filter-button {
    position: relative;
    &:before {
        position: absolute;
        right: 90px;
        bottom: 2px;

        display: block;

        width: 26px;
        height: 26px;

        content: '';
        -webkit-transition: all .2s ease 0s;
           -moz-transition: all .2s ease 0s;
             -o-transition: all .2s ease 0s;
                transition: all .2s ease 0s;
        -webkit-animation: anim-rotate 1s infinite linear;
           -moz-animation: anim-rotate 1s infinite linear;
             -o-animation: anim-rotate 1s infinite linear;
                animation: anim-rotate 1s infinite linear;

        opacity: 0;
        border: 2px solid transparent;
        border: 2px solid rgba($b_accent,.6);
        border-left: 2px solid $b_accent;
        border-radius: 15px;
        background: transparent;
    }
    &:after {
        font-size: 14px;
        line-height: 24px;

        position: absolute;
        right: 90px;
        bottom: 2px;

        display: block;

        width: auto;
        height: 26px;

        content: attr(data-count);
        // content: 'test';
        -webkit-transition: all .2s ease 0s;
           -moz-transition: all .2s ease 0s;
             -o-transition: all .2s ease 0s;
                transition: all .2s ease 0s;
        white-space: nowrap;

        opacity: 0;
        color: $b_text;
        background: transparent;
    }
}
.ajaxLoaded .filter-button:after,
.ajaxLoading .filter-button:before {
    opacity: 1;
}
.ajaxLoading.ajaxLoaded .filter-button:after {
    opacity: 0;
}
@keyframes anim-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes anim-rotate {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
