
.forthcoming-events-widget {
	padding: 35px 20px 0;
	background-color: $side_background;
	margin-bottom: 28px;
	border-bottom: 6px solid $side_border;


	@include media("<wide-tab") {
		padding: 30px 20px 0;
	}
	@include media("<tabport") {
		padding: 23px 20px 0;
	}

	.widget-title {
		margin: 0 0 26px;
		@include Font(Proxima-Thin);
		font-size: 28px;
		line-height: 34px;
		color: #212121;

		@include media("<wide-tab") {
			margin: 0 0 23px;
		}
		@include media("<=phoneland") {
			margin: 0 0 19px;
			font-size: 26px;
			line-height: 32px;
		}
	}
	.widget-content {
		padding: 0 0 20px;

		@include media("<wide-tab") {
			padding: 0 0 10px;
		}
		@include media("<tabport") {
			padding: 0 0 15px;
		}

		.content-block {
			position: relative;
			display: block;
			margin-bottom: 28px;

			.icon-wrap {
				float: left;
				width: 32px;
				height: 32px;
			}
			.resource-title {
				padding-left: 44px;
				@include Font(Proxima-Light);
				font-size: 13px;
				line-height: 16px;
				color: $greyish-brown;
			}

			&:hover, &:active {
				.resource-title {
					color: $warm-grey-two;
				}
			}
		}
	}
}