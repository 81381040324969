.client-console{
    .block.sidebar{
        flex-basis:25%;
        min-width: 250px;
    }
    .jsonForm fieldset{
        max-width:100%;
        fieldset{
            padding:0; // For repeatable areas - padding on fieldsets is incorrectly set from jsonform-overrides.scss (which needs to go at some point)
        }
    }
    .console-pane-actions{
        position:absolute;
        top: 32px;
        right: 32px;
        //float:right;
        .console-pane-action{
        }
    }
}

//TODO There is probably a better place for this...
select#selectPolicySummary {
    text-indent: 30px;
}
.summary-sja fieldset .intro {
    font-weight: 600;
}
.summary-sja #wrapper-for-locktonPf_form_staff>label:empty {
    display: none;
}