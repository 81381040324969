
.logo-carousel-section {
	padding: 40px 0 40px;
	border-top: 3px solid $greyish-brown;
	background: $light-grey;

	@include media("<tabport") {
		padding: 22px 0 22px;
		border-top: 2px solid $greyish-brown;
	}

	.bounds {
		.logo-carousel {
			.carousel-button-wrapper {
				display: none;
				margin-right: 24px;
				overflow: hidden;

				@media only screen and (max-width: 1360px) {
					margin-right: 0;
				}
			}

			ul {
				padding: 15px 0 5px;
				font-size: 0;
				border: none;
				box-sizing: border-box;

				@include media("<tabport") {
					padding: 10px 0 5px;
				}

				li {
					display: inline-block;
					width: 12.5%;;
					font-size: 16px;
					text-align: right;

					// @media only screen and (max-width:1260px) {
					// 	width: 11.111%;
					// }
					// @media only screen and (max-width:1080px) {
					// 	width: 12.5%;
					// }
					@media only screen and (max-width:960px) {
						width: 25%;
					}
					// @media only screen and (max-width:767px) {
					// 	width: 20%;
					// }
					// @media only screen and (max-width:620px) {
					// 	width: 24%;
					// }
					@media only screen and (max-width:568px) {
						width: 50%;
					}
					// @media only screen and (max-width:370px) {
					// 	img {
					// 		max-width: 95px;
					// 		max-height: 38px;
					// 	}
					// }
				}
			}
		}
	}
}