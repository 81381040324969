.icon-activity {
	width: 32px;
	height: 32px;
}

.icon-bell {
	width: 24px;
	height: 22px;
}

.icon-bin {
	width: 32px;
	height: 32px;
}

.icon-circle-bullet {
	width: 10px;
	height: 10px;
}

.icon-close {
	width: 21px;
	height: 22px;
}

.icon-contact-email {
	width: 32px;
	height: 32px;
}

.icon-create-policy {
	width: 32px;
	height: 32px;
}

.icon-cross-drawn {
	width: 23px;
	height: 21px;
}

.icon-dashboard {
	width: 146px;
	height: 116px;
}

.icon-down-arrow {
	width: 512px;
	height: 512px;
}

.icon-edit {
	width: 32px;
	height: 32px;
}

.icon-hamburger {
	width: 32px;
	height: 22px;
}

.icon-info {
	width: 18px;
	height: 18px;
}

.icon-insurances {
	width: 32px;
	height: 32px;
}

.icon-invoice {
	width: 26px;
	height: 26px;
}

.icon-letter {
	width: 367.69px;
	height: 370px;
}

.icon-lock {
	width: 17px;
	height: 21px;
}

.icon-logo_mobility {
	width: 136px;
	height: 85px;
}

.icon-menu-selected {
	width: 19px;
	height: 19px;
}

.icon-notes {
	width: 82.81px;
	height: 109.38px;
}

.icon-open-close {
	width: 24px;
	height: 24px;
}

.icon-overview-client {
	width: 32px;
	height: 32px;
}

.icon-overview-progress {
	width: 32px;
	height: 32px;
}

.icon-overview-started {
	width: 32px;
	height: 32px;
}

.icon-overview-submitted {
	width: 32px;
	height: 32px;
}

.icon-payonline {
	width: 33px;
	height: 28px;
}

.icon-percent {
	width: 45px;
	height: 45px;
}

.icon-pf-download {
	width: 32px;
	height: 32px;
}

.icon-phone {
	width: 35px;
	height: 30px;
}

.icon-policies {
	width: 32px;
	height: 32px;
}

.icon-pound {
	width: 45px;
	height: 45px;
}

.icon-pound_minus {
	width: 45px;
	height: 45px;
}

.icon-right-arrow {
	width: 20px;
	height: 20px;
}

.icon-search {
	width: 18px;
	height: 18px;
}

.icon-settings {
	width: 22px;
	height: 22px;
}

.icon-staff {
	width: 36px;
	height: 32px;
}

.icon-tick-drawn {
	width: 28px;
	height: 28px;
}

.icon-view-files {
	width: 403px;
	height: 485px;
}

.icon-warning-triangle {
	width: 24px;
	height: 21px;
}

