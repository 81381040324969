
.obtain-quote-widget {
	padding: 12px 20px;
	margin-bottom: 28px;
	border-bottom: 6px solid $side_border;
	background: $side_background;

	@include media("<wide-tab") {
		padding: 12px 15px 30px;
	}
	@include media("<tabport") {
		padding: 12px 15px 14px;
	}

	.widget-title {
		margin: 0 0 15px;
		@include Font(Proxima-Light);
		font-size: 24px;
		line-height: 29px;
		color: $greyish-brown;

		@include media("<=phoneland") {
			font-size: 22px;
			line-height: 27px;
		}
	}
	.btn-wrap {
		margin: 25px 0 30px;

		@include media("<tabport") {
			margin: 25px 0;
		}
	}
	.row {
		position: relative;
		padding-left: 38px;
		margin-bottom: 18px;

		&.first-row {
			margin-top: 27px;
		}
		a {
			@include Font(Proxima-Reg);
			font-size: 16px;
			line-height: 20px;

			@include media("<=phoneland") {
				font-size: 15px;
			}
		}
		.icon {
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 28px;
			height: 28px;
			// background: url("../img/icons.png") no-repeat;
			z-index: 1;
			svg{
				fill: $side-icons;
				width: 24px;
				height: 24px;
				display: inline-block;
				vertical-align: middle;
			}
			&.download-icon {
				// margin-top: -4px;
				background-position: left bottom;
				svg {
					width: 19px;
					height: 16px;
				}
			}

			&.upload-icon {
				margin-top: -2px;
				background-position: right bottom;
				svg {
					width: 19px;
				 	height: 16px;
				}
			}

			&.mail-icon {
				margin-top: -2px;
				background-position: left top;
			}

			&.post-icon {
				margin-top: -5px;
				background-position: right top;
				svg {
					width: 22px;
					height: 16px;
				}
			}	
		}
		.before-address {
			float: left;
			margin-right: 14px;
			padding-bottom: 4px;
		}
		.address {
			float: left;

			.line {
				display: block;
			}
		}
	}
	.increased-margin {
		margin: 25px 0;

		@include media("<wide-tab") {
			margin: 17px 0;
		}
	}
}

.form-trigger,
.form-close {
  font-weight: 400;
  cursor: pointer;
}