.documents{
    span {
        margin-left: 5px;
    }
}
.documents-fileUpload, .documents-fileUpload-v2 {

    #fileUploadContainer {
        width: 100%;
        margin: 30px auto;
    }
    #fileUploadWrapper {
        background: #ececec;
        text-align: center;
        vertical-align: middle;
        display: table;
        width: 100%;
        min-height: 300px;
        font-size: 1em;
        color: #999;
        border-radius: 10px;
        overflow: hidden;
    }
    #fileUpload {
        background: #ececec;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        padding: 50px;
    }
    #fileUpload.dz-started .dz-default.dz-message {
        /* display: none;
        font-size: 0.7em; */
    }

    .dropzone .dz-preview {
        position: relative;
        display: inline-block;
        width: 180px;
        margin: 0.5em;
        padding: 10px;
        border-radius: 5px;
        /*border: 2px dotted #bbb;*/
    }
    .dropzone .dz-preview .dz-progress {
        display: block;
        height: 10px;
        border: 2px solid #ffffff;
        background-color: #848484;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 0 6px #dedede, 0px 1px 1px 0px #000 inset;
        box-shadow: 0 0 0 6px rgba(0,0,0,0.1), 0px 1px 1px 0px #000 inset;
        margin: 8px 0;
    }
    .dz-preview.dz-file-preview.dz-success select {
        opacity: 0.3;
        pointer-events: none;
    }
    .dropzone .dz-preview .dz-progress .dz-upload {
        background: #addc3c;
    }
    .dropzone .dz-preview .dz-error-mark, .dropzone .dz-preview .dz-success-mark {
        position: absolute;
        left: auto;
        top: 0;
        width: auto;
        height: auto;
        right: 0;
        margin: 0;
        font-size: 1.6em;
    }
    .dropzone .dz-preview .dz-error-mark {
        color:#bf1919;
    }
    .dropzone .dz-preview .dz-success-mark {
        color:#43bb47;
    }
    .type-sel {
        background: #fff;
        border: 1px solid #ccc;
        margin-top: 5px;
    }
    label {
        font-size: 16px;
        margin-bottom: 5px;
    }
}