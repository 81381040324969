
.author-widget {
	padding: 14px 20px 30px;
	margin-bottom: 3px;
	background-color: $side_background;;

	@include media("<tabport") {
		padding-bottom: 37px;
	}

	@if($site == mobility) {
		display:none;
	}

	.widget-title {
		margin: 0 0 17px;
		@include Font(Proxima-Thin);
		font-size: 28px;
		line-height: 34px;
		color: $greyish-brown;

		@include media("<tabport") {
			font-size: 26px;
			line-height: 32px;
		}
	}
	.widget-image-wrap {
		width: 100%;
		margin-bottom: 12px;
		box-sizing: border-box;
		border-right: 2px solid #cccccc;
	    border-bottom: 1px solid #afafaf;
	    border-top: 1px solid #afafaf;
	    border-left: 2px solid #cccccc;

	    img {
	    	width: 100%;
	    	height: auto;
	    }
	}
	.contacts {
		@include Font(Proxima-Light);
		font-size: 14px;
		line-height: 18px;
		color: $greyish-brown;

		span {
			display: block;
		}
	}
	@if($site == solicitors){
		a{
			color: $accent-secondary;
		}
		
	}
}