@if $site == 'mobility' {
	/***************** FONTS *******************/



	/***************** COLORS *******************/

	// Global Styles
	$background-grey: $light-grey-91;
	$body-text: $greyish-brown;

	// Main Colours
	$accent-primary: $cerulean;
	$accent-secondary: $nasty-green;
	$link-primary: $cerulean;
	$hover-primary: $greyish-brown;

	//Greys
	$dark-grey: $greyish-brown;
	$mid-grey: $cool-grey;
	$light-grey: $light-grey-91;



	// Section Colours

	//Header
	$header: (
	    logoBg        	: transparent,
	    navIconFill 	: $rouge-two
	);

	//Footer
	$footer: (
	    copywriteBg     : $slate-grey,
	    copywriteBorder : $accent-secondary
	);

	// Left header style
	$a_background: transparent;
	$a_text: $greyish-brown;
	$a_accent: $accent-primary;

	// Right hedaer style
	$b_background: $light-grey-91;
	$b_text: $greyish-brown;
	$b_accent: $accent-secondary;

	//Sidebar widget Style
	$side_background: $light-grey-91;
	$side_text: $greyish-brown;
	$side_background_b: $light-grey-91;
	$side_text_b: $white;
	$side_border: $slate-grey-96;
	$side-icons: $blue-grey-96;



	/****************************/
	/* 	      LAYOUTS           */
	/****************************/

	$login: (
		background 	: 	$slate-grey,
		btnBg 		: 	$a_accent,
		btnBorder	: 	$a_accent,
		btnText 	: 	$white,
		btnReset	: 	$a_accent
	);



	/****************************/
	/* 	      COMPONENTS        */
	/****************************/

	// BUTTONS

	// btn_a
	$a_btn-border: $rouge;
	$a_btn-text: $white;
	$a_btn-background: $rouge;

	$a_btn-hover-border: $accent-primary;
	$a_btn-hover-text: $white;
	$a_btn-hover-background: $charcoal-grey;


	// btn_b
	$b_btn-border: $accent-primary;
	$b_btn-text: $a_btn-text;
	$b_btn-background: $accent-primary;

	$b_btn-hover-border: $a_btn-hover-border;
	$b_btn-hover-text: $a_btn-hover-text;
	$b_btn-hover-background: $a_btn-hover-background;


	//btn c
	$c_btn-border: $accent-primary;
	$c_btn-text: $a_btn-text;
	$c_btn-background: $accent-primary;

	$c_btn-hover-border: $a_btn-hover-border;
	$c_btn-hover-text: $a_btn-hover-text;
	$c_btn-hover-background: $a_btn-hover-background;


	// Banner
	$banner-bg-img: "/img/mobility/mobility_banner_v2.png";
	$topsection-bg-img: "../img/background-small.jpg";
	$login-bg-img: "/img/mobility/mobility_login_bg.jpg";

	// FAQ
	$faq-heading-color: $body-text;
	$faq-plusminus-color: $body-text;
	$faq-bordercolor:$light-grey;
	$faq-trigger-bg: $light-grey-four;
	$faq-trigger-bg_open: $accent-secondary;
	$faq-trigger-color: $body-text;
	$faq-trigger-color_open: $white;


	// Tabs
	$tab-heading-color: $body-text;
	$tab-heading-color_active: $white; 
	$tab-heading-bg : $light-grey-four;
	$tab-heading-bg_active : $accent-secondary;

	
	// HEADBAND
	$headband: (
	    background  :   $slate-grey,
	    text        :   $white,
	    hover       :   $a_accent
	);

	// pushNav
	$pushNav: (
	    background  :   url(../img/mobility/adobe-stock-52709071-converted.png) center center,
	    text        :   $slate-grey,
	    hover       :   $a_accent,
	    border      :   $warm-grey-two
	 );

	/****************************/
	/* 	      THEMES            */
	/****************************/
	//Content types
	$newstype_news: $cerulean;
	$newstype_event: $nasty-green;
	$newstype_guidance: $dull-orange;
	$newstype_cpd: $link-primary;
	$newstype_proposalform: $accent-primary;

	// Products and s4rvices
	$insurance_product: $accent-primary;
	$insurance_service: $link-primary;


	//Banner styles
	$banner-text: $light-navy-blue;


	/********************************************/
	
}
