
.insurance-service-detail-page {
	
	#testimonials-carousel.carousel{
		ul {
			border-top: 0;
			padding:0;
		}
		li {
		  width: 100%;
		  padding-top:0;
		}
		.carousel-button-wrapper {
		  display: none;
		}
		blockquote {
			padding-top: 3px;
			margin: 0 0 0 10px;

			@include media("<1200px") {
				margin: 0;
			}

			.column-quote {
				@include Font(Proxima-ThinIt);
				font-size: 23px;
				line-height: 28px;
				color: $a_text;

				@include media("<=phoneland") {
					font-size: 19px;
					line-height: 1.4;
				}
			}
			.column-additional {
				@include Font(Proxima-Light);
				font-size: 13px;
				line-height: 16px;
				color: $a_text;

				@include media("<=phoneland") {
					font-size: 17px;
					line-height: 14px;
				}
				cite {
					font-style: normal;
				}
			}
		}
	} 

	.main-section-container {
		position: relative;
		padding: 3px 0 42px;

		@media only screen and (max-width: 1199px) {
			padding: 30px 0 40px;
		}
		@include media("<tabport") {
			padding: 22px 0 85px;
		}

		.main-article {
			padding-bottom: 15px;
			margin-bottom: 16px;
			
			p {
				margin-bottom: 20px;
			}

			.article-image-wrap {
				float: left;
				max-width: 48%;
				height: auto;
				margin: 0 4% 20px 0;

				@media only screen and (max-width: 1199px) {
					margin: 0 0 25px;
					max-width: none;
					width: 100%;
				}

				img {
					width: 100%;
					height: auto;
				}

				&.insurance_product{
					border-bottom:3px solid $insurance_product;
				}
				&.insurance_service{
					border-bottom:3px solid $insurance_service;
				}
			}
			.article-heading {
				margin: 0 0 15px;
				@include Font(Proxima-Light);
				font-size: 24px;
				line-height: 29px;
				color: $greyish-brown;
			}
			ul {
				list-style: disc;
				margin-bottom: 20px;
				clear: both;
					padding: 0 0 0 40px;

				li {
					line-height: 22px;
					span {
						display: inline-block;
						margin-right: 18px;
						@include Font(Proxima-Black);
						font-size: 14px;
						color: $a_accent;
					}
					svg {
						fill: $sick-green;
						display: block;
						width: 9px;
						height: 10px;
					}
				}
			}
		}

		.additional-section {
			padding: 22px 20px 2px;
			margin-bottom: 35px;
			background: $side-background;

			@include media("<tabport") {
				margin-bottom: 0;
			}

			.section-heading {
				margin: 0 0 22px;
				@include Font(Proxima-Light);
				font-size: 20px;
				line-height: 24px;
				color: $black-two;
			}
		}

		.social-icons-wrap {
			@include media("<tabport") {
				position: absolute;
				bottom: 35px;
				left: 32px;
				margin: 0;
				z-index: 2;
			}
			@include media("<=phoneland") {
				left: 16px;
			}
		}

		.sidebar-widgets-container {
			.sidebar-news-widget {
			    margin-bottom: 0;

			    .news-widget {
			    	li:not(:last-child) .news-block {
						border-bottom: solid 1px $white-six;

						@include media("<tabport") {
							border-bottom: 0;
						}
					}
					li {
						padding-bottom: 15px;

						.box-title, .date {
							@media only screen and (min-width: 768px) and (max-width: 991px) {
								clear: both;
								padding-left: 0;
							}
						}
					}
			    }
			}
		}
	}
}