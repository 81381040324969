
body.member-detail-page {
	.lockton-top-section {
		.two-column-grid {
			.top-section-block {
				    // padding-top: 40px;

				    @media only screen and (max-width: 1199px) {
					padding-top: 32px;
					}
					@include media("<wide-tab") {
						padding-top: 28px;
					}

			}
			.left-column {

				@include media("<tabport") {
					display: block;
					width: 100%;
					-ms-flex: none;
					-webkit-flex: none;
					flex: none;

					&::before {
						right: -50%;
						width: 200%;
					}
				}
			}
			.right-column {
				padding-right: 5%;
				max-width: 34%;	

				@media only screen and (max-width: 1199px) {
					padding-right: 0;
				}
				@include media("<tabport") {
					display: none;
				}

				p {
					span {
						display: block;
					}
				}
			}
		}
	}

	.main-content {
		position: relative;
		padding: 56px 0 75px;

		@include media("<wide-tab") {
			padding: 33px 0 40px;
		}
		@include media("<tabport") {
			padding: 66px 0 40px;
		}

		.bounds {
			.left-column {
				float: left;
				width: 38%;

				@include media("<tabport") {
					float: none;
					width: 100%;
					margin-bottom: 23px;
				}

				.photo-wrap {
					border: solid 1px #e7e3e3;

					img {
						width: 100%;
						height: auto;
					}
				}
				.social-icons-wrap {
					margin-top: 25px;
				}
			}
			.right-column {
				float: right;
				width: 58%;

				@include media("<tabport") {
					float: none;
					width: 100%;
				}

				.article-header {
					position: relative;
					padding: 0 160px 22px 0;
					margin-bottom: 20px;
					border-bottom: solid 1px $warm-grey-two;

					@include media("<tabport") {
						position: static;
						padding: 0 0 22px 0;
					}

					.article-title {
						margin-bottom: 10px;
						@include Font(Proxima-Light)
						font-size: 24px;
						line-height: 29px;
						color: $greyish-brown;
					}
					.additional-info {
						@include Font(Proxima-ThinIt)
						font-size: 16px;
						line-height: 22px;
						color: $greyish-brown;
					}
					.back-to-page {
						position: absolute;
						right: 0;
						top: 20px;
						z-index: 1;

						@include media("<wide-tab") {
							top: 5px;
						}
						@include media("<tabport") {
							right: 32px;
							top: 23px;
						}
						@include media("<=phoneland") {
							right: 16px;
						}
					}
				}
				.article-content {
					p {
						margin-bottom: 22px;
					}
				}
				.article-footer {
					.row-wrap {
						margin-bottom: 28px;

						@include media("<=phoneland") {
							margin-bottom: 22px;
						}

						.row {
							.row-name {
								float: left;
								width: 125px;
								padding-right: 20px;
								box-sizing: border-box;

								@include media("<=phoneland") {
									float: none;
									width: 100%;
									padding-right: 0;
								}
							}
							.row-links {
								padding: 0 25% 0 125px;

								@media only screen and (max-width: 1199px) {
									padding: 0 0 0 125px;
								}
								@include media("<=phoneland") {
									padding: 0;
								}

								a {
									@include Font(Proxima-Reg);
									color: $link-primary;

									&:hover, &:active {
										color: $warm-grey-two;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.location-single-section {
		border: 0;
		overflow:hidden;

		.location-wrap {
			margin-top: 0;

			.tab-content .office-info-wrap {
				@media only screen and (min-width: 768px) and (max-width: 991px) {
					width: 34%;
				}

			}
			.tab-content .office-map {
				@media only screen and (min-width: 768px) and (max-width: 991px) {
					width: 66%;
				}
				@include media("<tabport") {
					height: 280px !important;
				}
			}
		}
	}
}
