.resource-centre-filters-container {
    .filters-block-container {
        margin-bottom: 9px;

        @include media('<wide-tab') {
            margin-bottom: 12px;
        }
        @include media('<tabport') {
            margin-bottom: 15px;
        }
        .title {
            font-size: 16px;
            line-height: 20px;

            float: left;

            max-width: 150px;

            color: $b_text;

            @include Font(Proxima-Reg);
            @include media('<tabport') {
                float: none;

                max-width: none;
                margin-bottom: 15px;
                padding-left: 5px;
            }
            &.format-title {
                @include media('<tabport') {
                    float: left;

                    margin-right: 25px;
                }
                @include media('<=phoneland') {
                    float: none;

                    margin-right: 0;
                }
            }
        }
        .filters-block {
            font-size: 0;
            line-height: 0;

            padding-left: 165px;

            @include media('<tabport') {
                padding-left: 0;
            }
            .filter-field {
                position: relative;

                display: inline-block;
                // overflow: hidden;

                margin: 0 5px 13px;

                @include media('<wide-tab') {
                    margin: 0 5px 9px;
                }
                input {
                    position: absolute;
                    z-index: -200;
                    top: 0;
                    left: 0;

                    opacity: 0;
                    &:checked + label {
                        color: $a_text;
                        background-color: $b_accent;
                        @if ($site == solicitors) {
                            background-color: $slate-grey;
                        }
                    }
                }
                label {
                    font-size: 14px;
                    line-height: 17px;

                    position: relative;
                    z-index: 1;

                    display: block;

                    margin: 0;
                    padding: 6px 12px 5px;

                    transition: all 300ms;
                    text-align: center;
                    text-transform: uppercase;

                    color: $b_text;
                    border: solid 1px $b_text;
                    background: transparent;

                    @include Font(Proxima-Reg);
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.format-filter-field {
                    margin-right: 12px;
                    label {
                        padding: 0;

                        border: 0;
                        .icon-bg {
                            transition: all 300ms;
                        }
                        span {
                            font-size: 10px;
                            line-height: 12px;

                            display: block;

                            padding-top: 4px;

                            @include Font(Proxima-Reg);
                        }
                    }
                    input {
                        &:checked + label {
                            color: $b_accent;
                            @if ($site == solicitors){
                                color: $white;
                            }
                            background-color: transparent;
                            .icon-bg {
                                fill: rgb(87,103,108);
                            }
                        }
                    }
                }
            }
            .filter-button {
                float: right;

                margin-left: 50px;

                @include media('<=phoneland') {
                    float: none;

                    width: 100%;
                    margin-left: 0;
                    padding-top: 20px;
                    padding-right:8px;
                    text-align: right;
                }
                .btn {
                    height: 30px;
                }
            }
        }
    }
    .format-filter-field {
        label {
            transition: all .4s ease 0;

            color: $greyish-brown;

            fill: $greyish-brown;
            @if ($site == solicitors) {
                fill:$silver-two;
            }
            &:hover {
                fill: $b_accent;
                @if ($site == solicitors) {
                    fill: $white;
                }
            }
            span {
                font-weight: 400;
            }
        }
        input:checked + label {
            color: $b_accent;

            fill: $b_accent;
            @if ($site == solicitors) {
                color: $white;
                fill: $white;
            }
        }
    }
}
#text-search-container {
    // This is for the resource center search
    position: relative;

    padding-top: 10px;
    padding-bottom: 10px;

    @if ($site == lsos or $site == solicitors){
        
    }

    @include media('<tabport') {
        padding-top: 22px;
        padding-bottom: 22px;
        
        display:block;
    }

    &:after{
        left:-50%!important;
    }

    .single-block {
        position: relative;
        z-index: 3;

        @include media('<1200px') {
            // padding: 12px 32px;
        }
        @include media('<tabport') {
            // padding: 0 8px 0 12px;
        }
        .block-title {
            font-size: 25px;
            line-height: 30px;

            float: left;

            position: relative;
            top: 0; // @TODO Possibly Acca only?
            
            @if ($site == lsos or $site == solicitors){
                border-left: 3px solid $a_accent;
            }

            padding: 12px 32px;

            color: $white;

            @include Font(Proxima-Thin);
            @include media('<wide-tab') {
                font-size: 20px;
                line-height: 30px;
            }
            @include media('<tabport') {
                line-height: 24px;

                float: none;

                margin-bottom: 15px;
                padding-left:16px;
                
            }
            @include media('<=phoneland') {
                font-size: 18px;
                line-height: 22px;

                @if ($site == accountants) {
                    padding-left: 24px;
                }

                @include Font(Proxima-Light);
            }
        }
        .search-form-wrap {
            float: left;
            padding:12px 0;

            @include media('<1200px') {
                float: right;

            }
            @include media('<tabport') {
                float: none;
                padding:0;
            }
            .form-field {
                float: left;
            }
            .search-field-wrap { // @TODO see if this is used? Looks like field inside is though
                @include media('<tabport') {
                    
                    @if ($site == lsos or $site == solicitors){
                        border-left:3px solid $a_accent;
                    }
                    
                    padding:10px 0 50px 15px;
                    margin-top:-28px;
                    float: none;
                    
                }
                @include media('<=phoneland') {
                    @if ($site == accountants){
                        margin-top: -20px;
                        padding: 10px 0 50px 0;
                    }
                }
                .search-field {
                    font-size: 16px;
                    line-height: 30px;

                    box-sizing: border-box;
                    width: 349px;
                    height: 30px;
                    padding: 0 10px;

                    color: $greyish-brown;
                    border: solid 1px $greyish-brown;
                    background-color: $white;

                    @include Font(Proxima-Light);
                    @include media('<wide-tab') {
                        width: 273px;
                    }
                    @include media('<tabport') {
                        width: 100%;
                    }
                    &:focus { // TODO Check this is the right focus colour
                        border: 1px solid rgba(0,134,139,.5);
                        box-shadow: inset 0 0 5px rgba(0,134,139,.3);
                    }
                }
            }
            .submit-btn-wrap {
                margin-left: 15px;

                @include media('<tabport') {
                    float: right;
                    margin-top:-35px;
                }
                .submit-btn {
                    height: 30px;
                }
            }
        }
    }
}


// Show/Hide
.resources-toggle {
    font-size: 10px;
    line-height: 24px;

    position: absolute;
    top: 2px;
    right: 0;

    cursor: pointer;
    text-transform: uppercase;

    color: $charcoal-grey;
    @if($site == solicitors){
        color: $white;
    }
    .resources-open {
        display: inline-block;

        vertical-align: top;
    }
    .resources-close {
        display: none;

        vertical-align: top;
    }
    svg {
        position:relative;
        top:-1px;
        width: 24px;
        height: 24px;
        margin-left: 1em;

        color: $light-grey;

        fill: $b_accent;
    }
}
.is-open {
    .resources-toggle {
        .resources-open {
            display: none;
        }
        .resources-close {
            display: inline-block;
        }
    }
}
.resources-page {
    .flex-parent{
        .text-search-container,
        .resource-centre-filters-container{
            padding: 0;

            transition: all .6s;
        }
        .resource-centre-filters-container{
            max-height: 0;
            margin: 0!important;
            overflow: hidden;
        }
    }
    .is-open {
        .flex-parent{

            .resource-centre-filters-container{

                max-height: 1000px;

            }
            .text-search-container {
                padding: 10px 16px;


                @include media('<tabport') {
                    padding: 22px 16px;
                }
            }
            .block-content {
                margin: 35px 0!important;

                @include media('<1200px') {
                    margin: 20px;
                }
                @include media('<wide-tab') {
                    margin: 15px 0;
                }
                @include media('<tabport') {
                    margin: 17px 0;
                }
            }
        }
    }
    
}
