ul.overview {
    position: relative;

    display: block;

    margin: 10px -16px 30px;

    list-style: none;

    text-align: center;
    text-align: left;

    @include media("<=1180px") {
        margin-right: -10px;
        margin-left: -10px;
    }
    @include media("<=970px") {
        width:100%;
    }
    li {
        font-size: inherit;
        font-size: 15px;

        display: inline-block;
        float: left;

        width: 25%;
        margin: 0;
        margin-bottom: 5px;
        padding: 16px;
        padding-top: 12px;
        padding-bottom: 12px;

        background: #EEEEEE;
        background: transparent;

        @include media("<=1180px") {
            padding: 16px 10px;
        }
        a {
            position: relative;

            display: block;
            overflow: hidden;

            width: 100%;
            padding: 0;

            transition: all .3s !important;
            text-align: center;
            vertical-align: middle;
            text-transform: uppercase;
            pointer-events: none;

            color: #595A5C;
            border: 1px solid #E6E7E8;
            background: white;
            span.icon-download {
                pointer-events: auto;
                display: inline-block;
                position: absolute;
                top: 8px;
                right: 10px;

            }
            span:hover {
                color: #1C75BC;
            }
            &:after {
                display: block;

                padding-top: 100%;

                content: '';
            }
        }
    }
}

    ul.overview > li a .square-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .type-icon{
            position: absolute;
            bottom:10px;
            left:10px;
        }

    }
    ul.overview > li a .square-content:after {
        @include media("<=970px") {
            font-size: 26px;

            width: 26px;
            height: 26px;
        }
    }
    ul.overview > li a .square-content .o-table {
        position: absolute;
        // top: 0;

        display: table;

        width: 100%;
        // height: 100%;
        // padding: 28% 0;
            
        height: 45%;
        top:28%;
        padding:0;
            
    }
    ul.overview > li a .square-content .o-cell {
        display: table-cell;

        padding: 0 15px;

        text-align: center;
        vertical-align: middle;

        color: white;
        background: #1C75BC;

        @include media("<=970px") {
            padding: 5px;
        }
    }
    ul.overview > li.progress a .square-content .o-cell {
        background: #F7941E;
    }
    ul.overview > li.started a .square-content .o-cell {
        background: #1C75BC;
    }
    ul.overview > li.submitted a .square-content .o-cell {
        background: #009444;
    }
    ul.overview > li.client a .square-content .o-cell {
        background: #975CC1;
    }
    ul.overview > li a h2 {
        font-size: 20px;
        font-weight: normal;

        margin: 20px 0 0 0;

        text-transform: none;

        @include media("<=1180px") {
            font-size: 16px;

            margin: 18px 0 0 0;
        }
        @include media("<=970px") {
            margin-top: 12px;
        }
    }
    ul.overview > li a .text {
        font-size: 18px;
        line-height:1.2em;

        display: block;

        width: 100%;

        text-transform: none;
        @include media("<=1180px") {
            font-size:14px;
        }
    }
    ul.overview > li a .number {
        font-size: 30px;
        font-weight: 600;
        line-height:1.2em;
        display: block;

        width: 100%;
        @include media("<=1180px") {
            font-size: 26px;
            font-weight: 600;
        }
    }