
body.contactPage {

	.contact-top-section {
		&::before{
			background-color:$b_accent;
		}

		.two-column-grid {

			.left-column {
				color:$b_text;
				&::before {
					background-color: $b_background;
				}
				@include media("<tabport") {
					display: block;
					width: 100%;
					padding-bottom: 166px !important;

					&::before {
						right: -50%;
						width: 200%;
					}
				}
				.content-wrap{
					border-left: 3px solid $b_accent;
				}
				a {
					display: inline-block;
					color: $b_accent;

					&:hover, &:active {
						color: $b_text;
					}
				}
			}

			.right-column {
				max-width: 34%;
				color:$a_text;
				&::after{
					background-color:$a_accent;
				}

				@include media("<wide-tab") {
					max-width: 27%;
					padding-right: 0;
				}
				@include media("<tabport") {
					display: none;
				}
				&::before {
					background-color:$a_background;
				}
				.content-wrap {
					padding-right: 10%;

					@include media("<1200px") {
						padding-right: 0;
					}

					p:not(.column-title) {
						@include Font(Proxima-RegIt)
						font-size: 16px;

						line-height: 1.4;
					}
					span {
						display: block;
						
						a {
							display: inline-block;
							color: $a_text;

							&:hover, &:active {
								color: $a_accent;
							}
						}
					}
					
				}
				.content-wrap{
					border-left: 3px solid $a_accent;
				}
			}

			.top-section-block {
				padding-top: 44px;
				padding-bottom: 30px;

				@include media("<1200px") {
					padding-top: 32px;
					padding-bottom: 40px;
				}
				@include media("<wide-tab") {
					padding-top: 28px;
				}

				.content-wrap {
					.column-title {
						margin-bottom: 12px;
						@include Font(Proxima-Light)
						font-size: 24px;
						
						line-height: 24px;

						@include media("<tabport") {
							font-size: 20px;
							line-height: 26px;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.meet-the-team {
		background: $background-grey;
		@if($site == accountants) {
			background: rgba($background-grey,.5);
		}

		.meet-the-team-header {
			padding: 30px 0 0;

			.header-text {
				float: left;
				margin: 0;
				@include Font(Proxima-Light);
				font-size: 24px;
				line-height: 29px;
				color: $greyish-brown;

				@include media("<=phoneland") {
					margin-bottom: 20px;
				}
			}
			select {
				float: right;
				width: 100%;
				max-width: 300px;
				height: 30px;
				padding: 0 30px 0 15px;
				@include Font(Proxima-Reg)
				// font-weight: 300;
				font-size: 16px;
				line-height: 28px;
				color: $slate-grey;
				background-color: $white;
				background-image: url('../img/select-arrow.svg');
				background-position: 94% center;
				background-repeat: no-repeat;
				border: 1px solid $mid-grey;
				border-radius: 0;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				appearance: none !important;

				&::-ms-expand {
				  display: none;
				}

				&:focus {
		            border: 1px solid rgba(0,134,139,0.5);

		            @include box-shadow(inset 0 0 5px rgba(0,134,139,0.3));
		        }

				option {
					@include Font(Proxima-Reg)
					
					font-size: 16px;
					color: $dark-grey;
				}

				@include media("<=phoneland") {
					float: left;
					clear: both;
					margin-bottom: 40px;
				}
			}
		}
		.meet-the-team-content {
			padding-left: 0;
			padding-right: 0;
			margin: 0 16px;

			@include media("<=phoneland") {
				margin: 0 8px;
			}

			.single-block {
				width: 25%;
				max-width: 25%;
				margin-bottom: 20px;

				@include media("<1200px") {
					-ms-flex: 1 1 33.33%;
					-webkit-flex: 1 1 200px;
					flex: 1 1 33.33%;
					max-width: 33.33%;
					width: 33.33%;
				}
				@include media("<tabport") {
					-ms-flex: 1 1 50%;
					-webkit-flex: 1 1 200px;
					flex: 1 1 50%;
					max-width: 50%;
					width: 50%;
				}
				@include media("<=phoneland") {
					-ms-flex: none;
					-webkit-flex: none;
					flex: none;
					max-width: none;
					display: block;
					width: 100%;
				}

				.image-wrap {
					display: block;
					padding: 15px;
					background: $white;
					border: solid 1px $mid-grey;
					box-sizing: border-box;

					.image-block {
						border: solid 1px $mid-grey;

						 img {
						 	width: 100%;
						 	height: auto;
						 }
					}
				}
				.personal-info {
					padding: 22px 15px 15px;
					span {
						@include Font(Proxima-RegIt);
						font-size: 16px;
						line-height: 18px;
						color: $charcoal-grey;
					}
					.name {
						display: block;
						margin: 0 0 16px;
						@include Font(Proxima-Light);
			
						text-transform: uppercase;

						a {
							color: inherit;

							&:hover, &:active {
								color: $mid-grey;
							}
						}
					}
					.position {
						display: block;
						margin-bottom: 16px;
					}
					.contacts {
						display: block;
						
						a {
							display: inline-block;
							margin-bottom: 5px;

							&.tel {
								color: inherit;

								&:hover, &:active {
									color: $mid-grey;
								}
							}
							&.email {
								color: $link-primary;

								&:hover, &:active {
									color: $mid-grey;
								}
							}
						}
					}
				}
			}
		}
	}
}