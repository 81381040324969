
.search-results-page {

	.single-top-section {
		.single-block-container {
			.single-block {
				.block-title {
					margin: 0 0 25px;

					@include media("<wide-tab") {
						margin: 0 0 21px;
					}
					@include media("<tabport") {
						margin: 0 0 15px;
					}
				}
				.search-results-info {
					@include Font(Proxima-Light);
					font-size: 24px;
					line-height: 30px;
					color: $white;

					@include media("<tabport") {
						margin-bottom: 0;
						font-size: 17px;
						line-height: 1.4;
					}
				}
			}
		}
	}

	.main-section-container {
		position: relative;
		padding: 53px 0 100px;

		@include media("<wide-tab") {
			padding: 30px 0 80px;
		}
		@include media("<tabport") {
			padding: 35px 0 55px;
		}

		.bounds {
			.sidebar {
				float: left;
				width: 30%;

				@media only screen and (max-width: 1199px) {
				}
				@include media("<wide-tab") {
					width: 48%;
				}
				@include media("<tabport") {
					float: none;
					width: 100%;
				}
			}

			.main-section {
				position: static;
				float: right;
				width: 64%;

				@include media("<wide-tab") {
					width: 48%;
				}
				@include media("<tabport") {
					float: none;
					width: 100%;
				}

				p {
					margin-bottom: 0;
				}

				.search-results-container {
					padding: 0 0 65px;

					@include media("<wide-tab") {
						padding: 30px 0 50px;
					}

					h2{
						margin-top:0.5em;
						font-weight:300;
					}

					.search-result-block {
						padding: 20px 70px 22px 0;
						border-bottom: 1px solid $white-two;

						@media only screen and (max-width: 1199px) {
							padding: 20px 40px 22px 0;
						}
						@include media("<wide-tab") {
							padding: 18px 0;
						}

						.search-result-wrap {
							.search-result-title {
								display: block;
								margin: 0 0 10px;
								font-size: 22px;
								line-height: 1.3;
								max-height:72px;

								&:hover {
									cursor: pointer;
								}
							}
							.search-result-content {
								line-height: 1.1;

								@include media("<=phoneland") {
									font-size: 15px;
									line-height: 1.3;
								}

								.read-more-link {
									display: inline-block;
									color: $link-primary;

									transition: all 250ms;
								}  
							}
						}

						&:hover {
							cursor: pointer;

							.search-result-title, .search-result-content .read-more-link {
								color: $hover-primary;
							}
						}
					}
				}
			}
		}
	}
}