
.downloads-widget {
	padding: 14px 20px 38px;
	background-color: $side_background;;

	@include media("<wide-tab") {
		padding-bottom: 25px;
	}
	@include media("<tabport") {
		padding-bottom: 34px;
	}

	.widget-title {
		margin: 0 0 14px;
		@include Font(Proxima-Thin);
		font-size: 28px;
		line-height: 34px;
		color: $greyish-brown;

		@include media("<tabport") {
			font-size: 26px;
			line-height: 32px;
		}
	}
	.downloads {
		position: relative;
		padding-left: 43px;

		.icon {
			position: absolute;
			left: 0;
			top: 2px;
			z-index: 1;
		}
		.link-wrap {
			a {
				@include Font(Proxima-Reg);
    			font-size: 16px;
    			line-height: 20px;

    			@include media("<=phoneland") {
    				font-size: 15px;
    			}
			}
		}
	}
	@if($site == solicitors){
		a{
			color: $accent-secondary!important;
		}
		
	}
}