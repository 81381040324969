
.site-search-container {
	padding: 0 0 65px;
	
	.block-title {
		position: relative;
		display: inline-block;
		margin: 0 0 11px;
		padding: 0 30px 0 0;
		@include Font(Proxima-Light);
		font-size: 28px;
		line-height: 34px;
		color: $greyish-brown;

		@include media("<wide-tab") {
			display: block;
			padding: 0;
		}

		.block-icon {
			display: block;
			position: absolute;
			right: 0;
			top: 7px;
			line-height: 19px;
			z-index: 1;

			@include media("<wide-tab") {
				display: none;
			}
		}
	}
	.block-text {
		margin: 0 0 18px;
		@include Font(Proxima-Light);
		font-size: 18px;
		line-height: 22px;
		color: $greyish-brown;
	}
	.form-fields-wrap {
		position: relative;
		width: 100%;
		padding: 0 75px 0 0;

		.search-field-wrap {
			width: 100%;

			input {
				width: 100%;
				height: 30px;
				padding: 0 10px;
				@include Font(Proxima-Light);
			    font-size: 16px;
			    line-height: 28px;
			    color: #565656;
				background-color: $white;
				border: solid 1px #979797;
				box-sizing: border-box;
				border-radius: 0;
				transition: all 300ms;

				&:focus {
		            border: 1px solid $a_accent;

		            box-shadow:inset 0 0 5px rgba(141,198,63,0.3);
		        }
			}
		}
		.search-submit-wrap {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;

			.btn {
				height: 30px;
				width: 65px;
				text-align: center;
			}
		}
	}
}