.insurances-edit{
    .chosen-container {
        min-width: 250px;
    }
    body > div:not(#headband) {
        position: relative;
        padding-bottom: 110px;
    }
    .saveSection {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .jsonForm {
        position: static;
    }
}