.carousel-section {
	.flexSlider-dots {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
		background: transparent;
	}
	.flexSlider {
		// position: relative;
		// margin: 0;
		// overflow: hidden;
		// border: 0;
		// border-radius: 0;
		// box-shadow: none;

		.flexSlider-dots {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 2;
			background: transparent;

			.slider-nav {
				position: relative;
				width: 100%;
				margin: 0 auto;
				height: 100%;
				background: transparent;
			}
		}

		&>ul {
			position: relative;

			@include media("<=tabport") {
				background: #efefec;
			}

			&>li {
				// position: relative;
				padding: 18px 0 35px;
				background: $white repeat center center;
			    background-size: cover;
			    box-sizing: border-box;

			    @include media("<=wide-tab") {
					padding: 18px 0 22px;
				}
				@include media("<=tabport") {
					border-bottom: 6px solid $white;
				}
				@include media("<=phoneland") {
					padding: 16px 0;
				}

				&::before {
					position: absolute;
					display: block;
					content: "";
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(232, 238, 237, 0.8);
					z-index: 1;
				}

			    .bounds {
			    	height: 100%;
			    	position: relative;
			    }

			    .social-icons-wrap {
			    	position: relative;
					margin: 0 60px 0 0;
					z-index: 5;
			    }

			    .carousel-content {
					position: relative;
					margin: 0 60px 0;
					text-align: center;
					z-index: 5;

					@include media("<=wide-tab") {
						margin: 0 45px 0;
					}
					@include media("<=phoneland") {
						margin: 0;
					}

					h3 {
						margin: 35px 0 43px;
						@include Font(Proxima-Light);
						font-size: 36px;
						line-height: 44px;
						color: $greyish-brown;

						@media only screen and (max-width: 1199px) {
							margin: 15px 0 23px;
						}
						@include media("<=wide-tab") {
							margin: 15px 10px 20px;
						}
						@include media("<=tabport") {
							margin: 15px 0 20px;
						}
						@include media("<=phoneland") {
							font-size: 28px;
							line-height: 34px;
							margin: 16px 5px 32px;
						}
					}

					p {
						max-width: 620px;
						margin: 0 auto 45px;
						@include Font(Proxima-Reg);
						font-size: 18px;
						line-height: 1.2;
						color: $greyish-brown;

						@media only screen and (max-width: 1199px) {
							margin: 0 auto 34px;
						}
						@include media("<=wide-tab") {
							max-width: none;
						}
						@include media("<=phoneland") {
							font-size: 16px;
							line-height: 21px;
							margin: 0 auto 23px;
						}
					}
					.btn {
						margin-bottom: 45px;

						@media only screen and (max-width: 1199px) {
							margin-bottom: 10px;
						}
						@include media("<=phoneland") {
							margin-bottom: 30px;
						}
					}
			    }
			}
		}
	}

	.flex-direction-nav {
		display: none;
	}
	.bounds_dots{
		display:flex;
		align-items:stretch;
		max-width:1366px;
		margin:0 auto;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
	}
	.flexSlider-dots {
	    position: absolute;
	    width: 22px;
	    left:auto;
	    top: 50%;
	    right: 60px;
	    bottom: auto;
	    text-align: center;
	    z-index: 10;	
	    padding:0;
	    transform:translateY(-50%);
	    max-width:1334px;
	    margin:0 auto;

	    @media only screen and (max-width: 1199px) {
			right: 41px;
		}
		@include media("<=wide-tab") {
			right: 21px;
		}
		@include media("<=phoneland") {
			display: none !important;
		}

    	a {
    		display: block;
    		width: 20px;
    		height: 20px;
    		margin:10px 0;
    		background: rgba(255, 255, 255, 0.2);
    		border-radius:50%;
    		box-shadow:none;
    		line-height:22px;
    		overflow:hidden;
    		font-size:60px;

    		@media only screen and (max-width: 1199px) {
				background: rgba(255, 255, 255, 0.2);
			}

	    	&:not(:first-child) {
	    		// margin-top: 11px;
	    	}

    		&.flex-active {
    			background: $white;
    		}
	    }
	}
}