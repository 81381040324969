/*initial layout*/

.pf-admin {
    //background:rgba($pale-grey-three,0.5);

    //font-weight: normal;
    //line-height: normal;
    
    /*initial layout*/
    h1 {
        font-size: inherit;
        font-weight: 600;

        text-transform: uppercase;
    }
    // Typography?
    h1,h2,h3,h4,h5{
          margin:.5em 0;
    }


    a,
    a:hover {
        text-decoration: none;

        color: #1C75BC;
    }
    section:last-of-type {
        padding-bottom: 60px;
    }

        
    /* All below amended by matty */
    button,
    input[type=button] {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        line-height: 30px;

        display: inline-block;

        width: auto;
        margin-top: 10px;
        padding: 0 15px;

        text-align: center;
        text-decoration: none;
        text-indent: 0;
        text-transform: uppercase;

        color: white;
        border: 1px solid #1C75BC;
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
        background-color: #1C75BC;
    }
    button:hover,
    input[type=button]:hover {
        cursor: pointer;

        border: 1px solid #009444;
        background-color: #009444;
    }
    // Webshim datepicker fixes (above overrides them)
    .ws-popover button,
    button.ws-popover-opener {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 600;

        display: block;

        margin-top: 0;

        color: #222222;
        border: 0 none;
        background: transparent;
    }
    button.ws-popover-opener {
        width: 19px;
        padding: 0;
    }
    &.pf-create .ws-date {
        width: 100%;
    }


    .login-box .alert {
        margin: 0;
        padding: 5px;

        text-align: center;
    }
    .login-box .alert.red {
        border: 1px solid #AF0004;
        background: rgba(253,74,54,.2);
    }
    .login-box .alert.green {
        border: 1px solid #009D00;
        background: rgba(0, 232, 0, .2);
    }
    select {
        padding: 5px;

        border-radius: 0;
        background: transparent;

        -webkit-appearance: none;
           -moz-appearance: none;
    }
    
    
    header.pageHead {
        margin-bottom: 20px;
        padding: 10px 0;

        border: 0;
        background: #EEEEEE;
    }
    header.pageHead h2 {
        margin: 0;
    }

    /* Media Queries */

    /*-------------------------------------------------------*/
    /*- 1024 Tab Landscape                                  -*/
    /*-------------------------------------------------------*/
    @media only screen and (max-width: 1024px) {
        .chart {
            width: 200px;
            height: 200px;
        }
        .doughnutSummaryTitle {
            font-size: 14px;

            margin: -10px 0 0 -41px;
        }
    }
    @media only screen and (max-width: 850px) {
        .chart {
            width: 500px;
            height: 500px;
        }
        .doughnutSummaryTitle {
            font-size: 18px;

            margin: 0 0 0 -20px;
        }
    }
  
}

// PF ADMIN overrides.css
.pf-admin {
    .jsonForm > section {
        padding: 0;
    }
    section:last-of-type,
    .jsonForm > section:last-of-type {
        padding: 0;
    }
    //.title-section {
    //    margin: 0;
    //}
    .jsonForm textarea {
        height: 12em;
    }

    .jsonForm .chosen-container {
        max-width: 100%;
    }

    // PF-ADMIN style.css
    /* the following is TAKEN FROM /proposalform/jsonForm.0.9.2/style.css and then slightly modified because we dont have time to implement the full 'collapsing' header just yet */
    //#mainheader {
    //    position: relative;
    //
    //    height: auto;
    //    // height: 100px;
    //    margin-top: 50px;
    //    padding-top: 0;
    //    //@TODO Check this against solicitors/lsos/acca and logos.scss header.scss and dashboard/header.scss
    //    // .flex-nav .logo {
    //    //     padding-top: 25px;
    //
    //    //     text-align: left;
    //    // }
    //}
    //#mainheader .flex-nav {
    //    padding-top: 12px;
    //    padding-bottom: 12px;
    //}
    //
    //#mainheader.shallow + #headband { // Possible dupe
    //    border-bottom-color: transparent;
    //}
    //
    //#mainheader #logomask { // Possible dupe
    //    position: fixed;
    //    z-index: 99999555;
    //    top: 0;
    //    left: 0;
    //
    //    display: block;
    //
    //    width: 300px;
    //    height: 13px;
    //
    //    background: #FFFFFF;
    //}
    //
    //#mainheader .logo img,
    //#mainheader .acc-contact-details-wrapper img { // Possible dupe
    //    width: auto;
    //    height: 68px;
    //}
    //#mainheader { // Possible dupe
    //    transition: opacity .5s;
    //
    //    opacity: 1;
    //}
    //#mainheader.shallow { // Possible dupe
    //    opacity: 0;
    //}
    //#mainheader + #headband a.do-maximise-header { // Possible dupe
    //    font-size: 0;
    //
    //    display: inline-block;
    //
    //    width: 0;
    //    height: 0;
    //    margin: 0 5px;
    //
    //    vertical-align: middle;
    //
    //    border: 5px solid transparent;
    //    border-top-width: 7px;
    //    border-top-color: #CCCCCC;
    //}
    .lsoslogo { // Possible dupe
        display: none!important;

        margin-left: 10px;
    }
    .lsos .lsoslogo { // Possible dupe
        display: inline-block!important;
    }
    .lsos #headband .lsoslogo { // Possible dupe
        height: 40px;
        margin: -7px 0 -8px 10px;
    }
}

.pf-admin {

    // Appears t be used in the login/create and login/edit pages
    .jsonForm .jf-row.jf-input-type-text#permissions .check-wrap {
        display: block;

        width: 100%;
    }
    .jsonForm .jf-row.jf-input-type-text#permissions input {
        clear: left;

        width: 30px;
    }

}