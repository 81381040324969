.footer-section {
    .talk-to-specialist {
        margin-bottom: 2px;
        padding: 18px 0;

        background: $dark-80;

        @include media('<=phoneland') {
            margin-bottom: 1px;
        }
        .row {
            text-align: center;
        }
        .row-text {
            font-size: 14px;
            line-height: 18px;

            margin-bottom: 8px;

            text-transform: uppercase;

            color: $white;

            @include Font(Proxima-Semibold);
        }
        .row-tel {
            a {
                font-size: 22px;
                line-height: 27px;

                color: $link-primary;

                @include Font(Proxima-Light);
                &:hover,
                &:active {
                    color: $hover-primary;
                }
            }
        }
    }
    .footer { // DEPRECATED
		position: relative;
		padding-top: 17px;
		background: map_get($footer,copywriteBg);

		&::after {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			left: 0;
			right: 0;
			height: 17px;
			background-color: map_get($footer,copywriteBorder);
			z-index: 1;

			@if($site == lsos) {
				display:none;
			}
		} 

		.footer-text-wrap {
			padding: 57px 0 65px;
			text-align: right;

			@include media("<=phoneland") {
				padding: 28px 0 55px;
				text-align: left;
			}

			.footer-text {	
				display: inline-block;
				width: 290px;			
				@include Font(Proxima-Reg);
				font-size: 12px;
				line-height: 16px;
				color: $white;

				p {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.footer-logo {
				display: block;
				float: left;

				svg{
					width: 112px;
					height: 55px;
					fill: $white;
				}
				@include media("<=phoneland") {
					float: none;
					text-align: left;
					margin-bottom: 18px;

					img, svg {
						max-width: 84px;
    					max-height: 34px;
					}
				}
			}
		}
	}

    .company-meta {
        font-size: 12px;
        line-height: 16px;

        position: relative;

        padding-top: 17px; // To cover off :after

        color: $white;
        background: map_get($footer,copywriteBg);

        @include Font(Proxima-Reg);
        &::after {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            left: 0;

            display: block;

            height: 17px;

            content: '';

            background-color: map_get($footer,copywriteBorder);
            @if($site == lsos) {
                display: none;
            }
        }
        .flex-parent {
            .block {
                min-width: 0;
            }
            .flex-parent {
                padding: 0;
                .block:first-child {
                    padding-left: 0;
                }
            }
        }
        .footer-text-wrap {
            padding: 25px 0;
            // text-align: right;

            @include media('<=phoneland') {
                padding: 28px 0 55px;
            }
            .footer-text,
            // .footer-text Deprecated
            .copyright {
                display: inline-block;

                max-width: 300px;

                text-align: right;

                flex-basis: 300px;
                @include media('<=phoneland') {
                    text-align: left;
                }
                p {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .footer-logos{
            	justify-content:flex-start;
                align-items:center;
	            .footer-logo {
	            	flex:0 1 auto;
	            	max-width:140px;
	            	padding-top:0;
	            	padding-bottom:0;
	            	margin:0 0 24px;
	                @include media('<=phoneland') {
	                    float: none;

	                    margin-bottom: 18px;

	                    text-align: left;
	                    img,
	                    svg {
	                        max-width: 84px;
	                        max-height: 34px;
	                    }
	                }
	                border-right:1px solid rgba($white,.6);

	                svg {
	                    width: 112px;
	                    height: 55px;

	                    fill: $white;
	                }
	                &:last-child{
	                	border-right:0 none;
	                }
	            }
            }
            .logo-blurb{
            	max-width:450px;
            	@if($site == mobility) {
            		p{
            			margin-top:8px;
            		}
            	}
            }
        }
        a{
        	color: $white;
        	text-decoration:underline;
        	&:hover{
        		text-decoration:none;
        	}
        }
    }

	.footer-nav-wrap {
		border-top: 1px solid $warm-grey;
		.bounds{
			@include media("<=desktop") {
				padding:0 32px;
			}
			@include media("<=phoneland") {
				padding:0 16px;
			}
			@include media("<=phoneland") {
				padding:0 8px;
			}
		}
	}
	ul.footer-nav.flex-ul {
		justify-content: flex-start;
		list-style-type: none;
		@include media('<=phoneland') {
			display: block;
		}
		padding:0;
		margin:0;
	}
	ul.footer-nav li {
		padding: 1em 2em 1em 0;
		display: block;
		margin: 0;
		& + li {
			@include media('<=phoneland') {
				padding-top: 0;
			}
		}
		a {
			color: $white;
			text-decoration:underline;
			&:hover{
				text-decoration:none;
			}
		}
	}
	
	
	
	
	
	#cookieNotice {
		background-color: #292e32;
		color: #ffffff;
		position: -webkit-sticky;
		position: sticky;
		bottom: 0px;
		@include Font(Proxima-Light);
		font-size:15px;
		.bounds{
			display:flex;
			justify-content: space-between;
			align-items: center;
			max-height: 300px;
			overflow: hidden;
			transition: all 250ms;
			padding: 14px 0;
			p {margin:0;}
			label {height: 28px; margin-left:10px;}
			a {white-space: nowrap;}
			svg.icon-close {/* With added round border */
				fill: $link-primary;
				border: 2px solid $link-primary;
				padding: 4px;
				border-radius: 50%;
				width: 28px;
				height: 28px;
				&:hover {
					text-decoration: none;
					cursor: pointer;
					fill: $warm-grey;
					border-color: $warm-grey;
				}
			}
			@include media("<=1357px") {
				padding:14px 32px;
			}
			@include media("<=desktop") {
				padding:14px 32px;
			}
			@include media("<=phoneland") {
				padding:14px 16px;
			}
		}
		input {
			position: absolute;
			opacity: 0.01;
			left: -100%;
		}
		input:checked ~ div {
			max-height: 0px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

}
