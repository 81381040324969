.block-stats{
    margin:-32px -32px 0 -32px;
    font-weight:300;
    align-items: stretch;
    @include media("<=tabport") {
        margin:-16px -16px  0 -16px;
    }

    @include media("<=phoneland") {
        margin:-8px -8px  0 -8px;
    }

    .block-stat{
        text-align:center;
        flex-basis:17%;
        flex-shrink:1;
        min-width:0;
        .stat-title,
        .stat-data{
            color:$white;
            margin:0;
            padding: 16px 6px;
        }
        .stat-data {
            line-height:32px;
        }
        .stat_numeric{
            border:1px solid $light-burgundy;
            background-color:$light-burgundy;;

            .stat-data{
                font-size: 26px;
                position:relative;
                &:before{
                    position:absolute;
                    top:1px;
                    left:8px;
                    right:8px;
                    height:1px;
                    background:rgba($white,.5);
                    content:'';
                }
            }
        }
        .stat_text{
            border:1px solid $silver;
            .stat-title{
                background-color:$peacock-blue;
            }
            .stat-data{
                color:$body-text;
            }

        }
        + .block-stat{
            //Second and third stat blocks are a different colour, don't know why yet so use this lazy rule.
            .stat_numeric {
                background-color:$slate-grey;
                border:1px solid $slate-grey;
            }
        }
        &:last-child{
            .stat_text {
                .stat-title {
                    background-color: $squash;
                }
            }
        }

    }
}