// style.scss

/*
 *  Stunnplate style
 *  Version: 2.0
 */
/*
 *  Notes:
 *  Main SCSS file to include entire library followed by custom styles.
 * 
 */

/*-------------------------------------------------------*/
/*- CONFIG                                              -*/
/*-------------------------------------------------------*/
@import 'config';

/*! Styles built for: #{$site} */

/*-------------------------------------------------------*/
/*- HELPERS                                             -*/
/*-------------------------------------------------------*/
/*MG helpers have to go first! */
@import 'helpers/fonts';
@import 'helpers/mixins';
@import 'helpers/include-media';

/*-------------------------------------------------------*/
/*- VARIABLES                                           -*/
/*-------------------------------------------------------*/
// This has to be after include-media for breakpoint variable
@import 'variables/pallette';
@import 'variables/default'; 
@import 'variables/solicitors'; 
@import 'variables/lsos'; 
@import 'variables/accountants'; 
@import 'variables/mobility'; 

/*-------------------------------------------------------*/
/*- PLACEHOLDERS                                        -*/
/*-------------------------------------------------------*/
@import 'helpers/placeholders';


/*-------------------------------------------------------*/
/*- BASE                                                -*/
/*-------------------------------------------------------*/

/* Normalise */
@import 'base/normalize';

/* Core */
@import 'base/default';


@import "base/base"; // Deprecated - need to move styles into other places

/*-------------------------------------------------------*/
/*- COMMON ELEMENTS                                     -*/
/*-------------------------------------------------------*/
@import '_common';

/*-------------------------------------------------------*/
/*- STUNNPLATE LIBRARY MODULES                          -*/
/*-------------------------------------------------------*/
/* Stunn Modules */
@import '../modules/simple-fixed-header/simple-fixed-header';
/*@!import '../modules/fixedHeader/fixedheader';*/
// @import '../modules/layout/layout';
@import '../modules/layout/flexlayout';
/*@!import '../modules/nav/nav';*/
@import '../modules/nav/flex-nav';
@import '../modules/tabbed/tabbed';
@import '../modules/modal/modal';
@import '../modules/carousel/carousel';
@import '../modules/accordion/accordion';
/*@!import '../modules/almostInView/almostInView';*/
@import '../modules/maps/maps';
@import '../modules/flexSlider/flexSlider';
@import '../modules/wizard/wizard';


/* Vendor Modules */
/* No vendor CSS as yet */
@import "../vendor/jquery-ui";
@import "../vendor/datatables/datatables";
@import "../vendor/chosen/1.4.2/chosen.min";


/*-------------------------------------------------------*/
/*- COMPONENTS      	                                -*/
/*-------------------------------------------------------*/
@import "components/buttons";
@import "components/login-form";
@import "components/back-to-page";
@import "components/quote-form";
@import "components/search-form";
@import "components/notification-bar"; // @TODO is this needed? If it's front end then this should be standardised with dashboards meanHeader
@import "components/top-section"; // @TODO Temporary for dashboard
@import "components/page-header";
@import "components/resources-filters";
@import "components/social-icons";
@import "components/main-tabs";
@import "components/contact-tabs";
@import "components/logo-carousel";
@import "components/location-tabs";
@import "components/contacts-mobile-only";
// @import "components/top-contacts"; // Doesn't appear that any of these style rules apply
@import "components/sidebar-news-widget";
@import "components/faq";
@import "components/sidebar-speak-to-specialist-widget";
@import "components/sidebar-obtain-quote-widget";
@import "components/sidebar-author-widget";
@import "components/sidebar-downloads-widget";
@import "components/sidebar-widget_c";
@import "components/sidebar-widget_d";
// @import "components/resources-main-section";
@import "components/info-widget";
// @import "components/sidebar-filter-results";
// @import "components/sidebar-sign-up-widget";
@import "components/pagination";
@import "components/sidebar-popular-resources";
@import "components/sidebar-forthcoming-events";
// @import "components/search-pagination";
@import "components/vertical-carousel";
@import "components/banner";
@import "components/widget-cta";
@import "components/rsa-block";
@import "components/icons";
@import "components/openCloseIcons";
@import "components/eventsCalendar";
@import "components/comparison-accordion";
@import "components/logos";
@import "components/alert";// this is admin/dashboard and probably doesn't affect the front-end.
@import "widgets/importContacts";// import contacts horror

/*-------------------------------------------------------*/
/*- WIDGETS								                -*/
/*-------------------------------------------------------*/
@import "widgets/menus/off-canvas";

/*-------------------------------------------------------*/
/*- LAYOUTS								                -*/
/*-------------------------------------------------------*/
// @import 'layouts/default'; // Blank?
@import 'layouts/grid';
@import "layouts/featured-pages";
@import "layouts/default-grid";
@import "layouts/three-column-grid";
@import "layouts/homepage";
@import "layouts/contact";
@import "layouts/member-detail";
@import "layouts/get-a-quote";
@import "layouts/insurance-index";
@import "layouts/insurance-detail";
@import "layouts/news-detail";
// @import "layouts/events-detail"; // Don't have an events detail anymore
@import "layouts/our-story";
@import "layouts/search-results";
// @import "layouts/resource-search-results";
@import "layouts/resources";
// @import "layouts/auxiliary-pages"; // For login / reset which is styled by dashboard /admin now
@import "layouts/header"; 
@import "layouts/footer";
@import "layouts/master-policy";

//jsonForm//
@import "../../../proposalform/v2016v2/jsonForm.2.0.0/css/style"; 
@import "admin-dashboard/components/pf-form_flex";

//Admin-Dashboard Shared Styles
@import "admin-dashboard/components/summary-table";
@import "admin-dashboard/components/table-flow";
@import "admin-dashboard/components/tables";
@import "admin-dashboard/components/pushNav";
@import "admin-dashboard/components/jsonform-overrides";


//Dashboard
@import "dashboard/dashboard";
// @import "dashboard/dashboard-style"; // Merged into dashboard
@import "dashboard/login";
@import "dashboard/acc-contact-details";
@import "dashboard/header";
@import "dashboard/client-details";
@import "dashboard/notifications";
@import "components/tooltips";
@import "dashboard/layouts/dashboard-contact";
@import "dashboard/layouts/account-details";
@import "dashboard/layouts/documents";
@import "dashboard/layouts/checkout";

//PF-Admin
// @import "pf-admin/admin-dashboard-forms"; // Cleared out and added to jsonform-overrides
@import "pf-admin/pfadmin";

@import "pf-admin/components/overview-block";
@import "pf-admin/components/dtListFilter"; // Datatables List Filter
@import "pf-admin/components/detail-table"; // Detail table - usedin quotes/transaction detail
@import "pf-admin/components/block-stats"; // Client console stats
@import "pf-admin/components/widget-console"; // Client console sidebar
@import "pf-admin/components/datatables"; // Datatables
@import "pf-admin/components/client-tabs"; // Nested tabs in console

@import "pf-admin/layouts/home";
@import "pf-admin/layouts/error-occurred";
@import "pf-admin/layouts/pf-warning"; // Also on dashboard
@import "pf-admin/layouts/pf-list";
@import "pf-admin/layouts/pf-view"; // This is actually dashboard
@import "pf-admin/layouts/staff-edit";
@import "pf-admin/layouts/pf-create";
@import "pf-admin/layouts/insurances-edit";
@import "pf-admin/layouts/insurances-create";
@import "pf-admin/layouts/documents";
@import "pf-admin/layouts/clients";
@import "pf-admin/layouts/client-console";




/*-------------------------------------------------------*/
/*- LAYOUTS (SITE SPECIFIC)  			                -*/
/*-------------------------------------------------------*/

// Master Policy Guide (LSOS ONLY)
@import 'components/master-policy/mp-table';
@import 'components/master-policy/mp-linked-list';
@import 'components/master-policy/mp-modal';
@import 'components/master-policy/mp-common';
@import 'components/master-policy/mp-index';
@import 'components/master-policy/mp-section_01';
@import 'components/master-policy/mp-section_02';
@import 'components/master-policy/mp-section_03';
@import 'components/master-policy/mp-section_04';
@import 'components/master-policy/mp-standard-template';
@import 'components/master-policy/mp-section_06';
@import 'components/master-policy/mp-section_07';
@import 'components/master-policy/mp-section_08';
@import 'components/master-policy/mp-section_09';

/*-------------------------------------------------------*/
/*-     SHAME!!!!!                                      -*/
/*-------------------------------------------------------*/
@import 'shame';

/*-------------------------------------------------------*/
/*-     PRINT STYLES                                    -*/
/*-------------------------------------------------------*/
@import 'base/print'; //arguable whether this should go in base
