.pf-list{
    .details-control,
    .activity-control{
        a:hover{
            cursor: pointer;
        }
        div{
            text-align:center;
        }
    }
    .inline-form {
        display: inline-block;
    }
    td textarea {
        display: block;
        width: 100%;
    }
    .icon-bin svg{
        fill:#1C75BC;
        width:23px;
        height:28px;
    }
    .icon-edit,
    .icon-activity{
        svg{
            fill:#1C75BC;
            width:28px;
            height:28px;
        }
    }
    .icon-notes svg{
        fill:#1C75BC;
        height:28px;
        width:21px;
    }
}