.main-tabs {
    position: relative;
    .bounds {
        @include media('<wide-tab') {
            padding: 0;
        }
    }
    &::before {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        left: 0;

        display: block;

        height: 44px;

        content: '';

        background-color: map-get($mainTabs, tabTopBg);
    }
    .tabbed {
        padding: 0;

        background: transparent;
        &[max-width~='767px'] {
            // @TODO check if this is needed everywhere
            padding: 0;

            border: 1px solid black;
            border-width: 0 1px;
            > ul {
                display: none;
            }
            > section {
                display: block;

                border: 0;
                background-color: none;
            }
            > section > a {
                display: block;

                text-decoration: none;

                border: 1px solid map-get($mainTabs, tabTriggerBg);
                border-width: 1px 0;
                background-color: rgba(map-get($mainTabs, tabTriggerBg),.8);
            }
            > section.active > a {
                background: map-get($mainTabs, tabTriggerBgActive);
                svg {
                    display: none;
                }
            }
        }
        ul.tabs-control {
            font-size: 0;

            height: 44px;
            margin: 0;

            text-align: center;

            @include media('<tabport') {
                height: auto;
            }
            li {
                position: relative;

                display: inline-block;

                width: 33.33%;
                height: 100%;

                text-align: left;
                // @include media("<tabport") {
                // 	width: 100%;
                // }
                &:not(:last-child) {
                    border-right: 1px solid map-get($mainTabs, tabTriggerSpacer);

                    @include media('<tabport') {
                        border-right: 0;
                        border-bottom: 1px solid map-get($mainTabs, tabTriggerBorder);
                    }
                    &.active {
                        border-right: 1px solid map-get($mainTabs, tabTriggerBorder);

                        @include media('<tabport') {
                            border-right: 0;
                        }
                    }
                }
                &.active {
                    a {
                        background: map-get($mainTabs, tabTriggerBgActive);
                        &:after {
                            display: none;
                        }
                        svg {
                            display: none;
                        }
                    }
                }
			}
		}
        ul.tabs-control a,
        .tabtrigger {
            font-size: 18px;
            line-height: 22px;

            z-index: 5;

            display: block;

            box-sizing: border-box;
            height: 100%;
            margin: 0;
            padding: 11px 60px 11px 22px;

            text-decoration: none;
            text-transform: capitalize;

            opacity: 1;
            color: $white;
            border: 0;
            border-radius: 0;
            background: map-get($mainTabs, tabTriggerBg);

            @include Font(Proxima-Light);
            @include media('<wide-tab') {
                padding: 11px 15px 11px 22px;
            }
            @include media('<tabport') {
                padding: 11px 55px 11px 22px;
            }
            svg {
                position: absolute;
                top: 10px;
                right: 28px;

                display: block;

                box-sizing: border-box;
                width: 24px;
                height: 24px;

                color: map-get($mainTabs, tabTriggerIconColor);

                fill: map-get($mainTabs, tabTriggerIconBg);
                @include media('<wide-tab') {
                    display: none;
                }
                @include media('<tabport') {
                    right: 23px;

                    display: block;
                }
            }
            &:hover {
                svg {
                    fill: map-get($mainTabs, tabTriggerIconBgHov);
                }
            }
        }
        .tabtrigger {
            @include media('>=tabport') {
                display: none;
            }
        }
        section.tab-content {
            min-height: 290px;

            border: none;

            @include media('<tabport') {
                min-height: 0;
            }
            .info-centre {
                padding: 15px 0 70px;

                border: none;

                @include media('<wide-tab') {
                    padding: 15px 9px 70px;
                }
                .carousel-button-wrapper {
                    @include media('<wide-tab') {
                        margin-right: 0;
                    }
                }
                & > ul {
                    font-size: 0;

                    height: auto !important;

                    border: none;
                    li {
                        font-size: 15px;

                        width: 33.33%;

                        @media only screen and (max-width: 1199px) {
                            width: 50%;
                        }
                        @include media('<tabport') {
                            width: 100%;
                        }
                        .carousel-block {
                            position: relative;

                            display: block;

                            min-height: 195px;
                            .image-wrap {
                                position: relative;

                                display: block;
                                float: left;
                                overflow: hidden;

                                width: 100%;
                                height: auto;
                                margin-bottom: 16px;
                                // padding-bottom: 5px;
                                // margin-right: 14px;

                                text-align: center;

                                border-bottom: 5px solid transparent;
                                @media only screen and (max-width: 420px) {
                                    float: none;

                                    width: 100%;
                                    height: auto;
                                    margin-right: 0;
                                    padding-bottom: 0;
                                }
                                img {
                                    width: auto;
                                    height: 100%;
                                    @media only screen and (max-width: 420px) {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                                @each $newstype, $color in $newstype-colors {
							        &.newstype_#{$newstype} {
										border-bottom-color: $color;
							        }
							            
							    }
                            }
                            .title {
                                font-size: 18px;
                                line-height: 22px;

                                transition: all 300ms;

                                color: $body_text;

                                @include Font(Proxima-Reg);
                                &:hover,
                                &:active {
                                    color: $link-primary;
                                }
                            }
                            .meta {
                                display: inline-block;

                                margin: 10px 0 0;
                            }
                            p {
                                font-size: 14px;
                                line-height: 18px;

                                margin: 0;
                                padding: 12px 0 10px;

                                color: $body_text;
                                @media only screen and (max-width: 420px) {
                                    margin: 0;
                                    padding: 15px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .carousel-button-wrapper {
        position: absolute;
        right: 0;
        bottom: 30px;

        float: none;

        @include media('<=990px') {
            right: 16px;
        }
    }
    a[href^='#carousel-button'] {
        font-size: 0;

        position: relative;

        width: 32px;
        height: 32px;

        border: none;
        background: $a_background;

        fill: $a_text;
        &:hover {
            background: $a_accent;

            fill: $a_background;
        }
    }
    a[href^='#carousel-button']:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;
    }
}
