
.widget-cta {
    margin-bottom: 3em;

    background-color: $side_background;
    .widget-block {
        padding: 0;
        &.widget-header {
            font-size: 14px;
            font-weight: 300;
            line-height: 2.57em;

            padding: 18px 26px 24px;

            color: $white;
            background: $light-navy-blue;
            .phoneno {
                font-size: 36px;
                line-height: 1em;

                @include Font(Proxima-Black);

                @include media("<=1200px") {
                    font-size:30px;
                }

                @include media("<=phoneland") {
                    font-size:28px;
                }
            }
        }
        .widget-title {
            font-size: 28px;
            line-height: 34px;

            margin: 0 0 25px;

            color: #212121;

            @include Font(Proxima-Thin);

        }
        .list-title {
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            line-height: 1.22;

            position: relative;
            // color: $greyish-brown;

            margin: 0;
            padding: 26px 26px 0 50px;
            &:before {
                position: absolute;
                top: 28px;
                left: 24px;

                display: inline-block;

                width: 17px;
                height: 17px;
                margin-right: 17px;

                content: '';
                vertical-align: middle;

                background-color: $rouge-two;
            }
        }
        .widget-list {
            padding-right: 26px;
            padding-left: 26px;

            @extend %list_default;

            @at-root{
                .homepage &{
                    li{
                        font-size:16px;
                    }
                }
            }
        }
        &.widget-footer {
            margin-top: -10px;

            text-align: center;
            .btn_a {
                font-size: 24px;

                padding: 18px 32px;

                transform: translateY(50%);

                @include Font(Proxima-Black);


                @include media("<=wide-tab") {
                    font-size: 18px;

                    padding: 12px 32px;
                }
            }
        }
    }
    .assistance-logo{
        padding: 0 0 16px;
        text-align:center;
    }
    .openinghours{
        @include media("<=1200px") {
            line-height: 1.4em;
        }
    }
}
