.lockton-top-section {
    position: relative;
    z-index: 1;

    overflow-x: hidden;

    color: $a_text;
    background: url($topsection-bg-img) center bottom no-repeat;
    background-size: cover;
    &::before {
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 0;

        display: block;

        width: 3px;

        content: '';

        background-color: $a_accent;

        @if ($site == accountants) {
            background-color: $a_other;
        }

        @include media('<=phoneland') {
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;

            width: auto;
            height: 3px;
        }
    }
    .two-column-grid {
        padding-top: 0;
        padding-bottom: 0;

        @include media('<=phoneland') {
            display: block;

            width: 100%;
            padding-right: 13px;
            padding-left: 13px;

                -ms-flex: none;
            -webkit-flex: none;
                    flex: none;
        }
        & > div {
            position: relative;

            padding-top: 32px;
            padding-bottom: 32px;

            @include media('<wide-tab') {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            @include media('<=phoneland') {
                display: block;

                width: 100%;
                padding-bottom: 27px;

                    -ms-flex: none;
                -webkit-flex: none;
                        flex: none;
            }
            .content-wrap {
                font-size: 16px;
                line-height: 20px;

                padding-left: 20px;

                @if ($site == accountants) {
                    padding-left: 32px;
                }

                @include Font(Proxima-Light);
                
                @if ($site != accountants) {

                    @include media('<1200px') {
                        padding-left: 15px;
                    }
                    @include media('<wide-tab') {
                        padding-left: 12px;
                    }
                }
                
                h4 {
                    font-size: 24px;
                    line-height: 28px;

                    margin: 25px 0 12px;

                    color: $a_text;

                    @include Font(Proxima-Light);
                    @include media('<1200px') {
                        margin: 8px 0 12px;
                    }
                    @include media('<=phoneland') {
                        font-size: 18px;
                    }
                }
                p {
                    @include media('<1200px') {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
        & > div > div {
            position: relative;
            z-index: 5;
        }
        .top-section-block {
            .column-title {
                font-size: 24px;
                line-height: 28px;

                margin: 25px 0 12px;

                @include Font(Proxima-Light);
                
                @include media('<1200px') {
                    margin: 8px 0 12px;
                }
                @include media('<=phoneland') {
                    font-size: 18px;
                }
            }
        }
        .left-column {
            padding-right: 32px;

            @include media('<wide-tab') {
                padding-left: 0;
            }
            @include media('<=phoneland') {
                padding-right: 7px;
            }
            &::before {
                position: absolute;
                z-index: 0;
                top: 0;
                right: 0;
                bottom: 0;

                display: block;

                width: 2500px;

                content: '';

                background-color: $a_background;

                @include media('<=phoneland') {
                    right: -32px;
                }
            }
            .content-wrap {
                @if ($site != accountants) {
                    border-left: 3px solid $a_accent;
                }
            }
            .additional-content {
                position: relative;

                margin-top: -3px;
                padding-left: 23px;

                @include media('<1200px') {
                    padding-right: 15px;
                }
                @include media('<wide-tab') {
                    margin-top: 0;
                    padding-right: 0;
                    padding-left: 15px;
                }

                ul {
                    list-style-type: none;

                    @include media('<1200px') {
                        padding-top: 5px;
                    }
                    ;
                    li {
                        font-size: 14px;
                        line-height: 18px;

                        padding-bottom: 7px;

                        @include Font(Proxima-Reg);

                        @if ($site != lsos) {
                            @include media('<1200px') {
                                padding-bottom: 20px;
                            } // @TODO - Why is this just LSOS?
                        }

                        span {
                            line-height: .9;

                            display: inline-block;

                            width: 9px;
                            height: 10px;
                            margin-right: 5px;

                            color: $b_accent; // @TODO Is this colour relevant anymore?

                            @include Font(Proxima-Black);
                            svg {
                                display: block;

                                width: 100%;
                                height: 100%;

                                fill: $a_accent;
                            }
                        }
                    }
                }
            }
        }
        .right-column {
            padding-left: 52px;

            color: $b_text;

            @include media('<1200px') {
                padding-left: 23px;
            }
            @include media('<=phoneland') {
                padding-right: 4px;
                padding-left: 0;
            }
            &::before {
                position: absolute;
                z-index: 0;
                top: 0;
                bottom: 0;
                left: 0;

                display: block;

                width: 2500px;

                content: '';

                background-color: $b_background;

                @include media('<=phoneland') {
                    left: -32px;
                }
            }
            &::after {
                position: absolute;
                z-index: 3;
                top: 0;
                bottom: 0;
                left: 0;

                display: block;

                width: 3px;

                @if ($site == accountants){
                    width: 6px;
                }

                content: '';

                background-color: $b_accent;

                @if ($site == accountants){
                    background-color: $b_other;
                }

                @include media('<=phoneland') {
                    right: -32px;
                    bottom: auto;
                    left: -32px;

                    width: auto;
                    height: 3px;
                }
            }
            .content-wrap {
                @if( $site != accountants) {
                    border-left: 3px solid $b_accent;
                }
            }
            a {
                color: $b_accent;
                @if( $site == accountants) {
                    color: $a_text;
                }
                &:hover {
                    color: $b_text;
                    @if( $site == accountants) {
                        color: $a_accent;
                    }
                }
            }
            .additional-content {
                position: relative;

                margin-top: -3px;
                padding-left: 23px;

                @include media('<1200px') {
                    padding-right: 15px;
                }
                @include media('<wide-tab') {
                    margin-top: 0;
                    padding-right: 0;
                    padding-left: 15px;
                }
                ;
                ul {
                    list-style-type: none;

                    @include media('<1200px') {
                        padding-top: 5px;
                    }
                    ;
                    li {
                        font-size: 14px;
                        line-height: 18px;

                        padding-bottom: 7px;

                        @include Font(Proxima-Reg);
                        
                        @if($site != lsos) {
                            @include media('<1200px') {
                                padding-bottom: 20px;
                            } // @TODO Why just LSOS?
                        }
                        
                        span {
                            line-height: .9;

                            display: inline-block;

                            width: 9px;
                            height: 10px;
                            margin-right: 5px;

                            color: $b_accent;

                            @include Font(Proxima-Black);
                            svg {
                                display: block;

                                width: 100%;
                                height: 100%;

                                fill: $b_accent;
                            }
                        }
                    }
                }
            }
            .row {
                @include media('<1200px') {
                    padding-top: 27px;
                    padding-right: 15px;
                }
                @include media('<wide-tab') {
                    padding-top: 0;
                    padding-right: 0;
                }
            }
            .row .btn {
                display: block;
                float: right;

                @include media('<wide-tab') {
                    display: inline-block;
                    float: none;

                    margin-left: 30px;
                }
                @include media('<=phoneland') {
                    margin-bottom: 25px;
                    margin-left: 16px;
                }
            }
        }
    }
}

/******************** Single Section ************************/

.single-top-section {
    position: relative;
    z-index: 1;

    overflow-x: hidden;
    text-align:center;
    // color: $body-text;
    background: url($topsection-bg-img) center top no-repeat;
    background-size: cover;

    @if($site == lsos or $site == solicitors){
        @include media('<1200px') {
            border-left: 3px solid $a_accent;
        }
        @include media('<tabport') {
            border-top: 3px solid $a_accent;
            border-left: 0;
        }
    }

    &::before {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;

        @if($site != mobility) {
            content: '';
        }

        background-color: $a_background;
    }
    .single-block-container {
        position: relative;
        z-index: 5;

        padding-top: 38px;
        padding-bottom: 38px;

        @include media('<tabport') {
            padding-top: 25px;
            padding-bottom:30px;
        }
        ;
        .single-block {
            position:relative;

            @if($site == lsos or $site == solicitors ){
                border-left: 3px solid $a_accent;
             
                padding: 0 32px;
             
                @include media('<1200px') {
                    padding: 0 16px;
                }
             
                @include media('<tabport') {
                    padding: 0 12px;
                }
            } @else if ($site == accountants){
                padding: 0 36px;    
            }
            .block-title {
                font-size: 28px;
                line-height: 1.39em;

                margin: 0 0 35px;

                color: $a_text;
                
                @if ($site == mobility){
                    font-size: 32px;
                    line-height: 1.22em;
                    
                    color: $body_text;
                }

                @include Font(Proxima-Thin);
                @include media('<1200px') {
                    margin: 0 0 20px;
                }
                @include media('<tabport') {
                    font-size: 28px;
                    line-height: 1.1;

                    margin: 0 0 17px;
                }
            }
            .block-content {
                font-size: 25px;
                line-height: 32px;

                margin: 0;

                color: $a_text;

                @include Font(Proxima-ThinIt);
                @include media('<tabport') {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .resource-centre-filters-container {
                .filters-block-container {
                    margin-bottom: 9px;

                    @include media('<wide-tab') {
                        margin-bottom: 12px;
                    }
                    @include media('<tabport') {
                        margin-bottom: 15px;
                    }
                    .title {
                        font-size: 16px;
                        line-height: 20px;

                        float: left;

                        max-width: 150px;

                        color: $b_text;

                        @include Font(Proxima-Reg);
                        @include media('<tabport') {
                            float: none;

                            max-width: none;
                            margin-bottom: 15px;
                            padding-left: 5px;
                        }
                        &.format-title {
                            @include media('<tabport') {
                                float: left;

                                margin-right: 25px;
                            }
                            @include media('<=phoneland') {
                                float: none;

                                margin-right: 0;
                            }
                        }
                    }
                    .filters-block {
                        font-size: 0;
                        line-height: 0;

                        padding-left: 165px;

                        @include media('<tabport') {
                            padding-left: 0;
                        }
                        .filter-field {
                            position: relative;

                            display: inline-block;
                            overflow: hidden;

                            margin: 0 5px 13px;

                            @include media('<wide-tab') {
                                margin: 0 5px 9px;
                            }
                            input {
                                position: absolute;
                                z-index: -200;
                                top: 0;
                                left: 0;

                                opacity: 0;
                                &:checked + label {
                                    color: $a_text;
                                    background-color: $b_accent;
                                    @if ($site == solicitors) {
                                        background-color: $slate-grey;
                                    }
                                }
                            }
                            label {
                                font-size: 14px;
                                line-height: 17px;

                                position: relative;
                                z-index: 1;

                                display: block;

                                margin: 0;
                                padding: 6px 12px 5px;

                                transition: all 300ms;
                                text-align: center;
                                text-transform: uppercase;

                                color: $b_text;
                                border: solid 1px $b_text;
                                background: transparent;

                                @include Font(Proxima-Reg);
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                            &.format-filter-field {
                                margin-right: 12px;
                                label {
                                    padding: 0;

                                    border: 0;
                                    .icon-bg {
                                        transition: all 300ms;
                                    }
                                    span {
                                        font-size: 10px;
                                        line-height: 12px;

                                        display: block;

                                        padding-top: 4px;

                                        @include Font(Proxima-Reg);
                                    }
                                }
                                input {
                                    &:checked + label {
                                        color: $b_accent;
                                        @if ($site == solicitors){
                                            color: $white;
                                        }
                                        background-color: transparent;
                                        .icon-bg {
                                            fill: rgb(87,103,108);
                                        }
                                    }
                                }
                            }
                        }
                        .filter-button {
                            float: right;

                            margin-left: 50px;

                            @include media('<=phoneland') {
                                float: none;

                                width: 100%;
                                margin-left: 0;
                                padding-top: 20px;
                                padding-right:8px;
                                text-align: right;
                            }
                            .btn {
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .text-search-container {
        // This is for the resource center search
        position: relative;

        padding: 10px 0;

        @include media('<tabport') {
            padding: 22px 0;
        }
        &::before {
            position: absolute;
            z-index: 0;
            top: 0;
            right: -2500px;
            bottom: 0;
            left: -2500px;

            display: block;

            content: '';

            background-color: $a_background;
        }
        &::after {
            @include media('<tabport') {
                position: absolute;
                z-index: 1;
                top: 0;
                right: -50px;
                left: -50px;

                display: block;

                height: 3px;

                content: '';

                background-color: $a_accent;
            }
        }
        .single-block {
            position: relative;
            z-index: 3;

            padding: 0 32px;

            @include media('<1200px') {
                padding: 12px 32px;
            }
            @include media('<tabport') {
                padding: 0 8px 0 12px;
            }
            .block-title {
                font-size: 25px;
                line-height: 30px;

                float: left;

                position: relative;
                top: 0; // @TODO Possibly Acca only?
                
                @if ($site == lsos or $site == solicitors){
                    border-left: 3px solid $a_accent;
                }

                padding: 12px 32px;

                color: $white;

                @include Font(Proxima-Thin);
                @include media('<wide-tab') {
                    font-size: 20px;
                    line-height: 30px;
                }
                @include media('<tabport') {
                    line-height: 24px;

                    float: none;

                    margin-bottom: 15px;
                    padding-left:16px;
                    
                }
                @include media('<=phoneland') {
                    font-size: 18px;
                    line-height: 22px;

                    @if ($site == accountants) {
                        padding-left: 24px;
                    }

                    @include Font(Proxima-Light);
                }
            }
            .search-form-wrap {
                float: left;
                padding:12px 0;

                @include media('<1200px') {
                    float: right;

                }
                @include media('<tabport') {
                    float: none;
                    padding:0;
                }
                .form-field {
                    float: left;
                }
                .search-field-wrap { // @TODO see if this is used? Looks like field inside is though
                    @include media('<tabport') {
                        
                        @if ($site == lsos or $site == solicitors){
                            border-left:3px solid $a_accent;
                        }
                        
                        padding:10px 0 50px 15px;
                        margin-top:-28px;
                        float: none;
                        
                    }
                    @include media('<=phoneland') {
                        @if ($site == accountants){
                            margin-top: -20px;
                            padding: 10px 0 50px 0;
                        }
                    }
                    .search-field {
                        font-size: 16px;
                        line-height: 30px;

                        box-sizing: border-box;
                        width: 349px;
                        height: 30px;
                        padding: 0 10px;

                        color: $greyish-brown;
                        border: solid 1px $greyish-brown;
                        background-color: $white;

                        @include Font(Proxima-Light);
                        @include media('<wide-tab') {
                            width: 273px;
                        }
                        @include media('<tabport') {
                            width: 100%;
                        }
                        &:focus { // TODO Check this is the right focus colour
                            border: 1px solid rgba(0,134,139,.5);
                            box-shadow: inset 0 0 5px rgba(0,134,139,.3);
                        }
                    }
                }
                .submit-btn-wrap {
                    margin-left: 15px;

                    @include media('<tabport') {
                        float: right;
                        margin-top:-35px;
                    }
                    .submit-btn {
                        height: 30px;
                    }
                }
            }
        }
    }
}
