.client-list{
    .inline-form {
        display: inline-block;
    }
    td.deleted {
        text-decoration: line-through;
        color: #ff0000;
    }
}
.client-edit{
	& > div {
	    position: relative;
	}
	.jsonForm {
	    position: static;
	}
}
.client-edit-login {
	&.access-full{
		#permissions{
			display:none;
		}
	}
    & > div:not(#headband) {
        padding-bottom: 110px;
    }
}