.pf-admin{
	&.home{
        background: #F1F2F2;
        h1,
        h2 {
            font-size: 22px;
            font-weight: 400;

            margin: 20px 0;

            text-transform: uppercase;

            color: #717171;
        }
        section{
            padding-top: 20px;

            background: #F1F2F2;
	    }
	    section + section {
	        padding-top: 20px!important;
	        padding-bottom: 100px;

	        border-top: 1px solid white;
	    }
	    // footer {
	    //    margin-top: 0;
	    //}
	}

    // Lower menu (big square buttons)
    ul.home {
        display: block;

        list-style: none;

        text-align: center;
        font-size: 0;

        position: relative;

        margin: 10px -16px;

        @include media("<=1180px") {
        	margin-right: -10px;
            margin-left: -10px;
        }
        @include media("<=970px") {
            width:100%;
        }

    }
    ul.home > li {
        background: #EEEEEE;
        font-size: 15px;

        display: inline-block;
        float: left;

        width: 25%;
        margin: 0;
        padding: 16px;

        background: transparent;

        @include media("<=1180px") {
        	padding: 16px 10px;
        }
    }

    ul.home > li:nth-child(3n) {
        clear: left;
    }
    ul.home > li a {
        position: relative;

        display: block;
        overflow: hidden;

        box-sizing: border-box;
        width: 100%;
        padding: 20px;

        transition: all .3s !important;
        text-align: center;
        vertical-align: middle;
        text-transform: uppercase;

        color: #595A5C;
        border: 1px solid #E6E7E8;
        background: white;
        height: 100%;
    }
    ul.home > li a:hover {
        color: #1C75BC;
        border-color: #1C75BC;
    }
    ul.home > li.create {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 50%;
        height: 100%;
    }

    ul.home > li a:after {
        display: block;

        padding-top: 100%;

        content: '';
    }
    ul.home > li a .square-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display:flex;
        align-items:center;
        justify-content:center;
        height:100%;
    }
    ul.home > li a .square-content div {
        flex: 0 0 auto;
    }
    ul.home > li a .square-content span {
        text-align: center;
    }
}