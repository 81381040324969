.news-detail-page {

	.main-section-container {
		position: relative;
		padding: 52px 0 30px;

		@include media("<tabport") {
			padding: 27px 0 146px;
		}

		.bounds {
			.main-section {
				position: static;
				float: left;
				width: 74%;

				@media only screen and (max-width: 1199px) {
					width: 65.5%;
				}
				@include media("<wide-tab") {
					width: 63.5%;
				}
				@include media("<tabport") {
					float: none;
					width: 100%;
				}

				p {
					margin-bottom: 20px;
				}

				.main-article {
					padding-bottom: 8px;

					@include media("<tabport") {
						padding-bottom: 17px;
					}

					.article-image-wrap {
						position: relative;
						float: left;
						max-width: 45%;
						height: auto;
						margin: 0 28px 24px 0;

						@media only screen and (max-width: 1199px) {
							margin: 0 0 25px;
							max-width: none;
							width: 100%;
						}
						@include media("<=phoneland") {
							margin: 0 0 22px;
						}

						img {
							width: 100%;
							height: auto;
						}

						.image-block-text {
							color: $white;
							@if ($site != mobility){
								position: absolute;
								left: 0;
								right: 0;
								bottom: 0;
								height: 31px;
								padding: 0 16px;
								@include Font(Proxima-Reg);
								font-size: 16px;
								line-height: 31px;
								color: $white;
								background: $light-burgundy; // @FIXME - colour should be same as newsType
								z-index: 1;


							}
						}
					}
					.article-heading {
						margin: 0 0 15px;
						@include Font(Proxima-Light);
						font-size: 31px;
						line-height: 37px;
						// color: $greyish-brown; // Inherits anyway

						@media only screen and (max-width: 1199px) {
							margin: 0 0 25px;
						}

						@include media("<=phoneland") {
							margin: 0 0 20px;
							font-size: 28px;
							line-height: 32px;
						}
					}
					.date-author-container {
						display: inline-block;
						position: relative;
						padding-left: 43px;
						margin-bottom: 16px;
						font-size: 16px;
  						line-height: 18px;

  						@media only screen and (max-width: 1199px) {
							margin-bottom: 20px;
						}

						.icon {
							position: absolute;
							left: 0;
							top: 2px;
							z-index: 1;
						}
					}
					// .article-subheading {
					// 	margin: 25px 0;
					// 	@include Font(Proxima-Light);
					// 	font-size: 24px;
    	// 				line-height: 28px;
					// 	color: $greyish-brown;

					// 	@include media("<=phoneland") {
					// 		margin: 22px 0;
					// 		font-size: 22px;
	    // 					line-height: 26px;
					// 	}
					// } // Not used?
					ul {
						padding-left: 16px !important;
					}
				}
				.location-section {
					padding-bottom: 12px;
				  	@include media("<=wide-tab") {
						padding-bottom: 30px;
					}
					.map-container {
						float: left;
						width: 45%;
						margin: 0 26px 20px 0;
						@include media("<=wide-tab") {
						    float: none;
						    width: 100%;
						    margin: 0 0 20px;
						}
						.event-map {
							height: 270px;
							@include media("<=desktop") {
								height: 205px;
							}
							@include media("<=wide-tab") {
								height: 250px;
							}
						}
					}
					.content-container {
						position: relative;
						display: inline-block;
						padding-bottom: 40px;
						width: 45%;
						min-height: 270px;
						@include media("<=desktop") {	
				    		min-height: 205px;
						}
						@include media("<=wide-tab") {
							min-height: 0;
							width: 100%;
							padding-bottom: 47px;
						}
						.section-heading {
							margin: 0 0 16px;
							// font-family: 'proxima-nova', sans-serif;
							font-weight: 300;
							font-size: 28px;
							line-height: 34px;
							// color: #4a4a4a;
							@include media("<=phoneland") {
								margin: 0 0 22px;
								font-size: 24px;
								line-height: 29px;
							}
						}
						.event-address {
							padding-bottom: 10px;
							span {
								display: block;
							}
						}
						.sign-up {
							position: absolute;
							left: 0;
							bottom: 0;
							z-index: 2;
						}
					}
				}
				.social-icons-wrap {
					margin-bottom: 35px;

					@include media("<tabport") {
						position: absolute;
						bottom: 84px;
						left: 32px;
						margin: 0;
						z-index: 2;
					}
					@include media("<=phoneland") {
						left: 16px;
					}
				}
				.back-to-page {
					@include media("<tabport") {
						position: absolute;
						bottom: 32px;
						left: 32px;
						z-index: 2;
					}
					@include media("<=phoneland") {
						left: 16px;
					}

					.back-to-page-link {
						display: inline-block;
						padding-left: 36px;

						.back-to-page-arrow {
						    width: 18px;
						    height: 18px;
						}
						.back-to-page-text {
							font-size: 18px;
							line-height: 22px;

							@include media("<=phoneland") {
								font-size: 16px;
							}
						}
					}
				}
			}

			.sidebar {
				float: right;
				width: 23%;

				@include media("<desktop") {
					width: 31%;
				}
				@include media("<=wide-tab") {
					width: 33.5%;
				}
				@include media("<tabport") {
					float: none;
					width: 100%;
				}
				.downloads {
					.icon svg {
						fill: $news-download;
					}
				}
			}
		}
	}
	.news-iframe{
		width:100%;
		height:600px;
		@include media("<=phoneland") {
			height:300px;
		}
	}
}
.sidebar{
	.downloads-widget {
		.downloads {
			padding-left: 0;
			.icon {
				width: 26px;
				height:31px;
			}
		}
	}
	ul.files {
		list-style: none;
		padding: 0;
		li {
			display: block;
			padding-left: 43px;
			position: relative;
			margin-bottom: 10px;
			a {
					color: $link-primary;
				}
		}
	}
}

// Resource types:
$newstypes: news,event,guidance,cpd,proposalform;


@each $type in $newstypes {
	.news-detail-page .main-section-container .bounds.newstype_#{$type} {
    	 .main-section .main-article .article-image-wrap .image-block-text {
        	background-color: map-get($newstype-colors,#{$type});
        	@if($site == mobility) {
                color: transparent!important;
            }
        }
    }
}