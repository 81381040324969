.login-form {

	@media only screen and (max-width: 1199px) {
		padding: 0 15px 0 18px;
	}
	@include media("<wide-tab") {
		padding-right: 0;
	}

	.form-row {
		padding-top: 12px;
		padding-left: 3px;

		@media only screen and (max-width: 1199px) {
			padding-left: 0;
			padding-top: 7px;
		}

		.form-links {
			float: left;
			line-height: 29px;

			@media only screen and (min-width: 569px) and (max-width: 767px) {
				margin-bottom: 10px;
			}

			a {
				position: relative;
				display: inline-block;
				@include Font(Proxima-Light);
			    font-size: 12px;
			    line-height: 15px;
			    color: $a_text;

			    &:hover, &:active {
					text-decoration: none;
					color: $a_accent;
				}

				&:not(:first-child) {
					margin-left: 5px;

					&::before {
						display: block;
						position: absolute;
						content: "";
						left: -5px;
						top: 2px;
						bottom: 3px;
						width: 1px;
						background-color: $a_text;
						z-index: 1;
					}
				}
			}
		}

		.form-submit {
			float: right;
			margin-right: 16px;

			@media only screen and (max-width: 1199px) {
				margin-right: 0;
			}
			@include media("<wide-tab") {
				margin-left: 15px;
			}
		}
	}
	.form-fields {

		.form-field {
			float: left;
			width: 50%;
			padding-right: 16px;
			box-sizing: border-box;

			@media only screen and (max-width: 1199px) {
				float: none;
				width: 100%;
				padding-right: 0;
				margin-bottom: 12px;
			}

			label {
				@include Font(Proxima-Light);
			    font-size: 14px;
			    line-height: 17px;
			    color: $white;
			}
			input {
				width: 100%;
				height: 29px;
				padding: 0 10px;
				margin-top: 3px;
				@include Font(Proxima-Light);
			    font-size: 16px;
			    line-height: 27px;
			    color: #565656;
				background-color: $white;
				border: solid 1px #979797;
				border-radius: 0;
				box-sizing: border-box;
				transition: all 300ms;

				&:focus {
		            border: 1px solid $a_accent;

		            box-shadow: inset 0 0 5px rgba(141,198,63,0.3);
		        }
			}
		}
	}
}