.widget_c {
	padding: 17px 20px 0;
	background-color: $side_background_b;
	color: $side_text_b;
	margin-bottom: 28px;
	border-bottom: 6px solid $side_border;

	.widget-title {
		margin: 0 0 26px;
		@include Font(Proxima-Thin);
		font-size: 28px;
		line-height: 34px;

		@include media("<wide-tab") {
			margin: 0 0 19px;
		}

		@include media("<=phoneland") {
			margin: 0 0 16px;
			font-size: 26px;
			line-height: 32px;
		}
	}
	.widget-content {
		padding: 0 0 18px;
		margin: 0 -20px;

		@include media("<tabport") {
			padding: 0 0 6px;
		}

		.content-block {
			position: relative;
			display: block;
			margin-bottom: 0px;
			padding:12px 50px 12px 20px;
			// padding-left:20px;
			// padding-right:50px;
			border-top: solid 1px rgba($silver-two,0.1);

			&:last-child{
				border-bottom: solid 1px rgba($silver-two,0.1);
			}

			.image-wrap {
				float: left;
				width: 34px;
				height: 34px;
				overflow: hidden;

				img {
					height: 100%;
					width: auto;
				}
			}
			.resource-title {
				padding-left: 48px;
				@include Font(Proxima-Light);
				font-size: 15px;
				line-height: 16px;
				color:$white;
			}

			&:hover, &:active {
				.resource-title {
					color: $a_accent;
				}
			}
			.icon{
				position:absolute;
				right:28px;
				top:16px;
				fill: $white-two;
				svg{
					width:18px;
					height:16px;
				}
				&.icon-linkto{
					right:4px;
					top:2px;
					svg{
						width:20px;
						height:20px;
					}
				}
			}
		}
	}
}