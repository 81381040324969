.wizard {
  .wiz-content {
    section {
      display: none;
    }
  }
  .wiz-nav-pane {
    .page-index {
      display: none;
    }
  }
}

/******************** Wizard styles *************************/

.mp-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: #4a4a4a;
    background-color: #ffffff;
    box-sizing: border-box;

    @include media("<=phoneland") {
        font-size: 16px;
        line-height: 1.31;
    }
}

.wizard {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: #ffffff;

    .wiz-content {
        display: block;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        .mp-section {
            min-height: 100%;
            padding: 72px 88px 178px;
            font-family: 'proxima-nova', sans-serif;
            background-color: #ffffff;
            box-sizing: border-box;

            @include media("<=tabport") {
                padding: 45px 44px 142px;
            }
            @include media("<=phoneland") {
                padding: 22px 17px 140px;
            }

            .step-title {
                margin: 0 0 17px;
                padding: 0;
                font-family: 'proxima-nova', sans-serif;
                font-size: 40px;
                font-weight: 100;
                line-height: 49px;
                text-align: left;
                color: #005e9c;

                @include media("<=phoneland") {
                    font-size: 30px;
                    line-height: 37px;
                }
            }

            .mp-content-wrap {
                margin: 64px 0 0;

                @include media("<=tabport") {
                    margin: 42px 0 0;
                }
                @include media("<=phoneland") {
                    margin: 20px 0 0;
                }
            }
        }
    }

    .wiz-nav-panel {
        position: absolute;
        top: -webkit-calc(100% - 106px);
        top: calc(100% - 106px);
        bottom: 0;
        left: 0;
        right: 0;
        flex: none;
        overflow: hidden;
        background-color: #f8f9f7;
        transition: top 0.4s ease-out;
        z-index: 5;
        box-sizing: border-box;

        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;

        @include media("<=phoneland") {
            top: -webkit-calc(100% - 86px);
            top: calc(100% - 86px);
        }

        &.opened {
            top: 0;

            .wiz-nav li.index a::before {
                width: 28px;
                height: 27px;
                margin-bottom: 12px;
                background-image: url("../img/modules/wizard/close-menu-icon.svg");

                @include media("<=phoneland") {
                    margin-bottom: 6px;
                }
            }
        }
    }

    .wiz-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
        height: 106px;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #5c767e;
        box-sizing: border-box;

        @include media("<=phoneland") {
            height: 86px;
        }

        li {
            display: flex;
            flex: 0 1 450px;
            flex-wrap: nowrap;
            align-items: flex-start;
            box-sizing: border-box;

            a {
                display: inline-flex;
                flex-wrap: nowrap;
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
                color: #aab7bb;
                text-decoration: none;
                outline: 0;
                border: 0;
                transition: all 0.2s ease-out;
                box-sizing: border-box;

                &:hover, &:focus, &:active {
                    text-decoration: none;
                    outline: 0;
                }
                &:hover {
                    color: #ffffff;
                    cursor: pointer;
                }
            }

            &.index {
                flex: 0 0 200px;
                width: 200px;
                padding-bottom: 13px;
                text-align: center;
                align-self: flex-end;
                justify-content: center;

                @include media("<=phoneland") {
                    flex: 0 0 100px;
                    width: 100px;
                }

                a {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    text-transform: uppercase;
                    color: #ffffff;

                    @include media("<=phoneland") {
                        font-size: 12px;
                        line-height: 15px;
                    }

                    &::before {
                        display: block;
                        content: "";
                        width: 39px;
                        height: 36px;
                        margin: 0 auto 6px;
                        background-image: url("../img/modules/wizard/index-icon.svg");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;

                        @include media("<=phoneland") {
                            width: 32.5px;
                            height: 30px;
                        }
                    }
                }
            }

            &:first-child {
                justify-content: flex-end;
                padding-left: 20px;

                @include media("<=phoneland") {
                    justify-content: flex-start;
                }

                a {
                    position: relative;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;

                    @include media("<=phoneland") {
                        font-size: 0;
                        line-height: 0;
                        color: #5c767e;
                        overflow: hidden;
                    }

                    &:hover {
                        &::after {
                            box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
                            background-image: url("../img/modules/wizard/left-scroll-button2.jpg");
                        }
                    }

                    &::after {
                        display: block;
                        flex: none;
                        content: "";
                        width: 38px;
                        height: 38px;
                        margin: 0 0 0 25px;
                        background-image: url("../img/modules/wizard/left-scroll-button.svg");
                        background-image: url("../img/modules/wizard/left-scroll-button.svg"), url("../img/modules/wizard/left-scroll-button2.jpg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        transition: all 0.2s ease-out;

                        @include media("<=phoneland") {
                            width: 34px;
                            height: 34px;
                            margin: 0;
                        }
                    }
                }
            }

            &:last-child {
                justify-content: flex-start;
                padding-right: 20px;

                @include media("<=phoneland") {
                    justify-content: flex-end;
                }

                a {
                    position: relative;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    @include media("<=phoneland") {
                        font-size: 0;
                        line-height: 0;
                        color: #5c767e;
                        overflow: hidden;
                    }

                    &:hover {
                        &::before {
                            background-image: url("../img/modules/wizard/right-scroll-button2.jpg");
                            box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
                        }
                    }

                    &::before {
                        display: block;
                        flex: none;
                        content: "";
                        width: 38px;
                        height: 38px;
                        margin: 0 25px 0 0;
                        background-image: url("../img/modules/wizard/right-scroll-button.svg");
                        background-image: url("../img/modules/wizard/right-scroll-button.svg"), url("../img/modules/wizard/right-scroll-button2.jpg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        transition: all 0.2s ease-out;

                        @include media("<=phoneland") {
                            width: 34px;
                            height: 34px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .wiz-nav-details {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        overflow-y: auto;
        box-sizing: border-box;

        .mp-section {
            padding: 47px 88px 84px;

            @include media("<=tabport") {
                padding: 45px 44px 63px;
            }
            @include media("<=phoneland") {
                padding: 22px 17px 58px;
            }
        }
    }
}