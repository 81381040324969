// Data table Listing Page Filter
.dtListFilter{
	.dtListFilter-flex{
		display:flex;
		flex-wrap:wrap;
		max-width:600px;
	}
	.dtListFilter-block{
		flex: 1 1 22%;
		margin-bottom:6px;

		@include media("<phoneland") {
			flex-basis:100%;
		}
	}
	.dtListFilter-label{
		line-height:34px;
	}
	.dtListFilter-select{
		flex-basis:60%;	 	
	}
}