#icon-logo_mobility {
    .one{
        fill: #87756A;
    }
    .two{
        fill: #8A786D;
    }
    .three{
        fill: #09357A;
    }
    .four{
        fill: #92C020;
    }
}

.icon-logo_mobility svg{
    width: 136px!important;
    height: 85px!important;

    @include media("<=phoneland") {
        width: 90px!important;
        height: 56px!important;        
    }
}