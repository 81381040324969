
.pagination-container {
	.pagination {
		display: inline-block;

		.pagination-text, li, a, span {
			@include Font(Proxima-Light);
			font-size: 20px;
			line-height: 24px;
			color: $body-text;
		}
		.pagination-text {
			float: left;
		}
		ul {
			padding: 7px 16px 7px 25px;
			border: 1px solid $mid-grey;
			// float: left;
			list-style: none;
			font-size: 0;
			line-height: 0;

			li {
				display: inline-block;
				margin: 0 3px;
				
				a {
					display: block;
					padding: 0 5px;
				}

				&.next, &.prev {
					margin: 0 1px;

					a {
						padding: 0;
					}
				}
			}
		}
	}
}