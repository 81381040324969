.contacts-mobile-only {
	@media only screen and (min-width: 768px) {
		display: none;
	}
	@include media("<tabport") {
		position: relative;
		display: block;
		width: 100%;
		padding: 27px 16px 32px;
		background: url("../img/building.jpg") center top no-repeat;
		background-size: cover;
		color: $b_text;
		overflow-x: hidden;
		border-top: 3px solid $b_accent;
		box-sizing: border-box;

		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $b_background;
			z-index: 0;
		}

		.content-wrap {
			position: relative;
			z-index: 5;
			border-left:3px solid $b_accent;
			padding-left:12px;

			.title {
				margin: 0 0 15px;
				// font-family: $LatoLight;
				// font-weight: 300;
				font-size: 20px;
				line-height: 1.2;
			}
			.info {
				margin: 0;
				// font-family: $LatoLightIt;
				// font-weight: 300;
				font-style: italic;
				font-size: 15px;
				line-height: 21px;
				
				span {
					display: block;
				}
			}
		}
	}
}