.dataTable {
    thead th{
        border: 0 none!important;
        font-weight:300;
        font-size:18px;
        background: map_get($datatables,thBg);
        color: map_get($datatables,thText);
        line-height:22px;
        padding: 16px;
    }
    .sorting_asc,
    .sorting_desc{
        background: lighten(map_get($datatables,thBg),10%);

        &::after{
            color: map_get($datatables,thText);
        }
    }
    tr::nth-child(odd){
        background:$pale-grey-91;
    }
    tr:nth-child(even){
        background:rgba($pale-grey-three,0.5);
    }
    select{
        width:100%;
    }
    tbody {
        td{
            padding: 16px;
        }
        tr.shown + tr {

            padding: 0;

            background: white;
            td {
                padding: 0;

                border: 1px solid #E6E7E8;
                background: white;
                > div {
                    padding: 0 .85rem;
                }
                table {
                    background: white;
                    tr td {
                        padding: .625rem .85rem;

                        border-top: 1px solid #E6E7E8;
                        background: white;
                    }
                }
            }
        }
    }
}

.dt-actions{
    list-style:none;
    white-space: nowrap;
    .dt-actions-item{
        display:inline-block;
        margin-right:0.5em;
        svg{
            fill: $link-primary;
        }
        a{
            display:inline-block;
        }
        a:hover {
            svg {
                fill: $hover-primary;
            }
        }
        .icon-edit{
            height:28px;
            width:28px;
        }
        .icon-notes{
            height:28px;
            width:21px;
        }
        .icon-view-files{
            height:28px;
            width:23px;
        }
        &:last-child{
            margin-right:0;
        }
    }
}