
body.homepage {
    .article-heading { //Not used on LSOS/Solicitors
        font-size: 28px;
        font-weight: 300;
        line-height: 1.39em;

        position: relative;

        margin-top: 0;
        padding-left: 32px;
        @include media("<=phoneland") {
            font-size: 28px;
    
            line-height: 1.14em;

            @if ($site == mobility) {
                font-size: 26px;
                line-height: 1.15em;
            }
        }
    }
    .homepage-top-section {
        .two-column-grid {
            & > div {
                padding-bottom: 38px;

                @include media('<1200px') {
                    padding-bottom: 22px;
                }
                @include media('<wide-tab') {
                    padding-bottom: 55px;
                }
                @include media('<=phoneland') {
                    padding-bottom: 27px;
                }
            }
            .left-column {
                @include media('>=1200px') {
                    max-width: 44%;
                }
                @include media('<1200px') {
                    max-width: 47%;
                }
                @include media('<=phoneland') {
                    max-width: none;
                }
            }
        }
    }
    .carousel-section {
        min-height: 312px;
        margin: 9px 0;

        @include media('<wide-tab') {
            margin: 6px 0;
        }
        @include media('<tabport') {
            margin: 6px 0 0;
        }
    }
    .main-content {
        background: map-get($homepage,main-content-bg);
        
        @if($site == accountants){
            flex-basis: 66%;
        }

        @if($site == mobility or $site == accountants){
            flex-basis: 70%;
        }
        
        @if ($site == solicitors) {
            .bounds {
                @include media(">1220px") {
                    max-width: 1216px;
                }
            }
        } // This is just to balance columns


        @if($site == mobility) {
            
            ul {
                @extend %list_default;
                padding:0;
                li{
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.56em;
            
                    @include media("<=phoneland") {
                        font-size: 16px;
                        line-height: 1.25em;
                    }
                }
            }
            
            font-size: 18px;
            font-weight: 300;
            line-height: 1.56em;
            
            @include media("<=phoneland") {
                font-size: 16px;
                line-height: 1.25em;
            }
        }
        @if($site == accountants) {
            @include media('<=tabport') {
                flex-basis: 50%;
            }
            .tabbed {
                border: 0 none;
                background: none;
                > ul {
                    display: flex;

                    background: none;
                    li {
                        text-align: center;

                        border-right: 1px $white solid;

                        flex: 1 1 10%;
                        &.active {
                            a {
                                background: $accent-primary;
                            }
                        }
                        &:hover {
                            a {
                                background: $accent-secondary;
                            }
                        }
                        &:last-child {
                            border-right: 0 none;
                        }
                    }
                }
                > section {
                    border: 0 none;
                    background: $pale-grey;
                    > div > div {
                        padding: 30px 36px 48px;

                        border-bottom: 0;
                    }
                }
                &[max-width~="400px"] {
                    padding:0;
                    border:1px solid black;
                    border-width:0px;
                    > ul {
                        display:none;
                    }
                    > section {
                        display:block;
                        background-color:none;
                        border:0;
                    }
                    > section > a {
                        display:block;
                        border:1px solid $accent-secondary;
                        border-width:1px 0;
                        background: rgba($accent-secondary,.6);
                        // padding:20px;
                        // background:#eee;
                        text-decoration:none;
                    }
                    > section.active > a {
                        background: $accent-primary;
                    }
                }
            }
            .tabbed >ul li a, .tabtrigger {
                font-size: 24px;

                width: 100%;
                margin: 0;
                padding: 16px;

                text-align:center;

                text-transform: uppercase;

                opacity: 1;
                color: $white;
                border: 0 none;
                border-radius: 0;
                background: rgba($accent-secondary,.6);

                @include Font(Proxima-Black);
            }
            ul {
                list-style-type: none;
            }
            > p {
                font-size: 20px;
            }
        } // End acca

        .two-column-grid { // Solicitors
            color: $black-two;

            @include media('<wide-tab') {
                display: block;
            }
            @include media('<=phoneland') {
                color: $tundora;
            }
            & > .block {
                @include media('<wide-tab') {
                    display: block;

                    width: 100%;

                        -ms-flex: none;
                    -webkit-flex: none;
                            flex: none;
                }
            }
            ul{
                list-style-type:none;
            }
            .left-column {
                p,
                ul,
                li,
                a {
                    font-size: 18px;
                    line-height: 18px;

                    @include media('<=phoneland') {
                        font-size: 14px;
                    }
                }
                ul {
                    li {
                        position: relative;

                        margin-bottom: 20px;
                        padding-left: 20px;
                        .list-icon {
                            line-height: .9;

                            position: absolute;
                            z-index: 1;
                            top: 3px;
                            left: 0;

                            display: block;

                            width: 12px;
                            height: 14px;

                            color: $link-primary;

                            @include Font(Proxima-Black);
                            @include media('<=phoneland') {
                                font-size: 20px;
                                line-height: 18px;

                                top: 0;
                            }
                            svg {
                                display: block;

                                width: 100%;
                                height: 100%;

                                fill: $link-primary;
                            }
                        }
                    }
                }
            }
            .right-column {
                &.hide-for-mobile {
                    @include media('<=phoneland') {
                        display: none;
                    }
                }
                p,
                ul,
                li,
                a {
                    font-size: 16px;
                    line-height: 18px;

                    @include media('<=phoneland') {
                        font-size: 14px;
                    }
                }
                p {
                    padding-left: 5px;

                    @include media('<wide-tab') {
                        padding-left: 0;
                    }
                }
                h5 {
                    padding-left: 10px;

                    @include media('<wide-tab') {
                        margin-top: 0;
                        padding-left: 0;
                    }
                }
                ul {
                    li {
                        position: relative;

                        margin-bottom: 17px;
                        padding-left: 40px;
                        .counter {
                            line-height: 21px;

                            position: absolute;
                            z-index: 1;
                            top: -1px;
                            left: 0;

                            display: block;

                            box-sizing: border-box;
                            width: 30px;
                            height: 21px;

                            text-align: center;

                            color: $white;
                            background: $link-primary;
                        }
                    }
                }
            }
            .row {
                text-align: center;
                .btn {
                    margin-bottom: 14px;

                    @include media('<1200px') {
                        margin-bottom: 26px;
                    }
                    @include media('<wide-tab') {
                        margin: 15px 0 49px;
                    }
                }
            }
            p {
                @include media('<=phoneland') {
                    margin-bottom: 1.2em;
                }
            }
        }
    }
    .sidebar {
        flex-basis: 33%;
        @if($site == accountants) {
            @include media('<=tabport') {
                flex-basis: 50%;
            }
        }
        @if($site == mobility) {
            flex-basis: 30%;
        }
    }
    #footer{
        margin-top:5em;
    }
}

