.account-details {
    .jsonForm section.newSec {
        padding: 15px 0;
    }
    .jsonForm > section {
        padding: 0;
    }
    .title-section {
        margin: 0;
    }
}