// Summary Tables - Where are these used?
.pf-admin, .pf-dashboard{
	.summary-table table {
        width: 100%;

        border-spacing: 0;
    }
    .summary-table table tbody tr td {
        padding: 5px;
        position: relative;
    }
    .summary-table table tbody tr td:first-of-type {
        font-size: 20px;
        font-weight: 700;

        width: 50px;
        padding-left: 15px;
    }
    .summary-table table tbody tr td:nth-of-type(3n) {
        position: relative;

        width: 100px;
    }
    .summary-table table tbody tr td:last-of-type {
        width: 60px;
    }
    .summary-table .button {
        font-family: 'Roboto Condensed',sans-serif;
        font-size: 18px;
        font-weight: 700;

        display: block;

        padding: 5px 10px;

        text-align: center;
        text-transform: uppercase;

        color: white;
        background: #27AAE1;
    }
    .summary-table .progressBar {
        line-height: 0;

        position: absolute;
        left: 0;

        overflow: hidden;

        width: 100px;
        margin-top: -4px;

        border: 1px solid transparent;
        border-radius: 2px;
    }
    .summary-table .progressBar b {
        display: block;

        width: 0;
        height: 8px;

        transition: all 1s !important;

        background-color: rgb(0, 214, 255);
        box-shadow: 0 0 1px 0 rgba(0,0,0,.6) inset;
    }
    .summary-table .progressBar .title {
        font-size: 0;
    }
    .summary-table .progressBar b.complete {
        background-color: #009444;
    }
    .summary-table .progressBar b.incomplete {
        background-color: #F7941E;
    }
    .summary-table .progressBar b.errors {
        background-color: #BE1E2D;
    }
    a.downloads {
        position: relative;

        display: block;

        padding: 16px 0 8px 32px;

        text-transform: lowercase;
    }
    a.downloads .svg-icon {
        position: absolute;
        top: 50%;
        left: 0;

        display: inline-block;

        height: 30px;
        margin-top: -12px;

        fill: #922545;
    }
    .doc-table a.downloads .svg-icon {
        left: 50%;

        margin-left: -12px;

        fill: #0086BF;
    }
    a.downloads .dlText {
        display: none;
    }

    /*Dashboard Table*/ //THink this is admin only
    .dashboard .summary-table table thead:after {
        display: none;
    }
    .dashboard .summary-table table thead th {
        padding: 7px 5px;

        text-align: left;

        color: white;
        border: 0;
        background: $slate-grey-96;
    }
    .dashboard .summary-table table thead th:first-of-type {
        padding-left: 10px;

        text-align: left;
    }
    .dashboard .summary-table table thead th:last-of-type {
        padding-right: 10px;
    }
    .dashboard .summary-table table tbody tr.open,
    .dashboard .summary-table table tbody tr.status-wrap {
        background: #F1F2F2;
    }
    .dashboard .summary-table table tbody tr.status-wrap td {
        text-align: right;

        border-bottom: 1px solid #939598;
    }
    .dashboard .summary-table table tbody tr.status-wrap td span.status-title {
        font-weight: 600;

        text-transform: uppercase;
    }
    .dashboard .summary-table table tbody tr.status-wrap td .button {
        display: inline-block;

        width: auto;
        margin-left: 20px;

        transition: all .3s !important;
    }
    .dashboard .summary-table table tbody tr.status-wrap td .button:hover {
        background: #009444;
    }
}
.pf-dashboard{
	 /*Dashboard Table*/
    
    .dashboard .summary-table table tbody tr td {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        height:70px;

        box-sizing: border-box;
        width: auto;
        padding: 10px 5px;

        border: 0;

    }
    .dashboard .summary-table table tbody tr td:first-of-type {
        text-align: left;
    }
    .dashboard .summary-table table tbody tr td:last-of-type {
        padding: 0 70px 0 0;

        text-align: right;
    }
    .dashboard .summary-table.doc-table table tbody tr td:last-of-type {
        padding-right: 10px;
    }
}
.pf-admin{ // Don't think this is used
	.summary-table {
        padding-right: 40px;
        @include media("<=850px") {
            padding-right: 0;
        }
    }
        /*Dashboard Table*/
    .dashboard .summary-table table {
        border: 1px solid #939598;
    }
    
    .dashboard .summary-table table tbody tr {
        background: #E6E7E8;
    }
    .dashboard .summary-table table tbody tr td {
        font-size: 16px;
        font-weight: 400;

        width: auto;
        padding: 10px 5px;

        border: 0;
    }
    .dashboard .summary-table table tbody tr td:first-of-type {
        padding-left: 10px;

        text-align: left;
    }
    .dashboard .summary-table table tbody tr td:last-of-type {
        padding-right: 10px;

        text-align: right;
    }
}