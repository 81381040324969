
// REM Conversion for font sizes (better acessibility)
//http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// Animations and keyframes
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

// Trandistions
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/

@mixin transition($args...) { // Apparently ... means it allows multiple $args
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

//6. Cross browser opacity
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
// Oly relevant if going back to IE8, o maybe not a big deel?

// Box-shadow
@mixin box-shadow($shadow...) {
-webkit-box-shadow: $shadow;
-moz-box-shadow: $shadow;
box-shadow: $shadow;
}

// Box-sizing
@mixin box-sizing($sizing...) {
-webkit-box-sizing: $sizing;
-moz-box-sizing: $sizing;
box-sizing: $sizing;
}