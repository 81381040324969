
/* ==========================================================================
   Normalise Forms
   ========================================================================== */

/**
 * Correct margin displayed oddly in IE 6/7.
 */


.watermark {display:none;}
.readonlyModeNotice {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    /* width: 60%; */
    margin: 0 auto;
    text-align: center;
    background: rgba(255, 0, 0, 0.12);
    padding: 5px;
    /*pointer-events:none;*/
    line-height: 16px;
}
.readonlyModeNotice a {
    pointer-events: all;
    text-transform: uppercase;
    padding: 1px 4px 0px;
    font-weight: 600;
    border: 1px solid #1c75bc;
}
.readonlyModeNotice a:hover {
    background: rgba(255,255,255,.5);
    color: #009444;
    border-color: #009444;
}
#forceSubmissionBlock {
    display:none;
}
.ready ~ #forceSubmissionBlock {
    display:block;
}
#forceSubmissionBlock .bounds {
    padding: 10px;
    background: #DDEEF7;
}
#forceSubmissionBlock .bounds:after {
    content: "";
    display: table;
    clear: both;
}
#forceSubmissionBlock p {
    margin: 7px 0;
    float:left;
}
#forceSubmissionBlock button {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
    font-family:"proxima-nova",sans-serif;
    background-color:white;
    border-radius:0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #1C75BC;
    text-indent: 0;
    border: 1px solid #1C75BC;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    color: white;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
    padding: 0 15px;
    line-height: 30px;
    width: auto;
    text-decoration: none;
    text-align: center;
    float: right;
    text-transform: uppercase;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
#forceSubmissionBlock button:hover {
    background-color: #009444;
    border: 1px solid #009444;
}
#forceSubmissionBlock #invalidMessage {
    display:none;
}
.invalid #forceSubmissionBlock #invalidMessage {
    display:inline;
    color:#BE1E2D;
}
.invalid #forceSubmissionBlock button {
    background-color: #999;
    border: 1px solid #999;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-opacity: 0.6;
    -o-opacity: 0.6;
    opacity: 0.6;
    cursor:default;
    pointer-events:none;
}

#fileListWrap {
    border: 1px solid #bbb;
    margin-top: 30px;
}
#fileListWrap h3 {
    border-bottom: 1px solid #bbb;
    margin: 0;
    padding: 15px;
    line-height: 1em;
    background-color: #eee;
}
#fileListWrap ul {
    margin: 0;
    padding: 15px 30px;
    background-color:#fafafa;
}
#fileListWrap ul li {
    padding: 5px 0px;
}
#fileListWrap ul li + li {
}
#fileListWrap ul li a {
    color: #27AAE1;
}

.jsonForm {
    margin: 0;
    font-size: 100%;
    font-family:"proxima-nova",sans-serif;
}

.jsonForm *, .jsonForm *:before, .jsonForm *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

/**
 * Define consistent border, margin, and padding.
 */

.jsonForm fieldset {
    border: 1px solid #ddd;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
.jsonForm fieldset fieldset {
    border:none;
    margin: 0;
    padding: 0;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

.jsonForm legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
    font-weight: bold;
}

/* ends in -i we should style it to look more attached */
.jsonForm fieldset[id*='-i'] {
    margin-top: -20px;
}
.jsonForm fieldset[id*='-i'] legend {
    /* font-weight: normal; */
    background: #fff;
    /*padding: 2px 4px;*/
    /*border: 1px solid #eee;*/
}

.jsonForm fieldset .intro {
    /*font-size:1.1em;*/
    margin: 0 0 0 1em;
}

.jsonForm fieldset .intro {
    /*margin: -10px -10px 10px;*/
    /*background: #FAFAFA;*/
    padding: 10px 0;
    margin:0;
    display:inline-block;
}

.jsonForm .help-trigger {
    font-size:0;
    vertical-align:middle;
    width:32px;
    height:32px;
    cursor:pointer;
}

.jsonForm .help {
    color: rgb(63, 136, 168);
    color: #1C75BC;
    border:1px solid #1C75BC;
    /* font-weight: bold; */
    margin: 10px auto;
    padding: 10px 16px 10px 40px;
    position: relative;
    display: block;
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    /*background: rgba(28, 117, 188, 0.2);*/
    background:#E9EDF7;
    /* border-radius: 20px; */
    /* padding-left: 40px; */
    /* max-width: 10px; */
    overflow: hidden;
    /* white-space: nowrap; */
    box-sizing: border-box;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    opacity:0;
    height:0;
    padding:0 16px 0 40px;
    margin:0;
}

.jsonForm .help-trigger:hover + .help {
    margin: 10px auto;
    padding: 10px 16px 10px 40px;
    opacity:1;
    height:auto;
}

.xxxhelp:before {
    content: "?";
    position: absolute;
    /* display: none; */
    left: 4px;
    top: 0;
    bottom: 0;
    background: rgb(63, 136, 168);
    margin: auto;
    color: white;
    padding: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 15px;
    text-align: center;
}


/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
.jsonForm button,
.jsonForm input,
.jsonForm select,
.jsonForm textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
    font-family:"proxima-nova",sans-serif;
    background-color:white;
    border-radius:0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    /* */
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

@-moz-document url-prefix() { /* targets Firefox only */
    .jsonForm select {
        padding-left: 2px!important;
    }
    .jsonForm select option {
        padding: 5px 4px!important;
    }
}

/* hovering save progress button */
button#main_summary,
button#main_submit_float {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    z-index: 9999999;
    cursor:pointer;
}

.jsonForm ul.jf-page-control li a[href="#summaryScreen"],
button#main_summary,
button#main_submit_float {
    background-color: #eeeeee;
    text-indent: 0;
    border: 1px solid #666666;
    color: #666666;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 1em;
    font-weight: 700;
    font-style: normal;
    padding: 0 10px;
    line-height: 28px;
    width: auto;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
button#main_submit_float{
    width:116px;
    padding-left:0;
    padding-right:0;
    text-align:center;
}
button#main_submit_float.unsaved{
    background-color:#1C75BC;
    border-color:#1C75BC;
    color:#ffffff;
    cursor:pointer;
    pointer-events:auto;
}
button#main_summary,
button#main_submit_float{
    background-color:#eee;
    border-color:#eee;
    color:#666666;
    cursor:default;
    /*pointer-events:none;*/
}

button#main_summary {
    right:133px;
    padding-left:30px;
    color:white;
    min-height:30px;
    min-width:45px;
}

button#main_summary:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(247, 148, 30, 0);
    border-top-color: #F7941E;
    border-width: 9px;
    margin-left: -9px;
}

.jsonForm ul.jf-page-control li a[href="#summaryScreen"] {background:#95BD3E; color:white; border:1px solid #95BD3E!important;
    -webkit-transition: background-color .3s;
    -moz-transition: background-color .3s;
    -ms-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
}
.jsonForm ul.jf-page-control li:hover a[href="#summaryScreen"] {background:#f1f2f2; color:white; border:1px solid #f1f2f2!important; color:#6D6E71; }
.jsonForm ul.jf-page-control li.current a[href="#summaryScreen"] {margin:1px 1px 1px 0; border-width:1px!important;}

/*
    range style
*/
.xxxxxjsonForm input[type="range"]{
    -webkit-appearance:none;
    -moz-apperance:none;
    box-shadow:0 0 5px 6px #fff inset;
    background:#ffffff;
    background: -moz-linear-gradient(top, #ffffff 40%, #666666 49%, #333333 50%, #666666 51%, #ffffff 60%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(40%,#ffffff), color-stop(49%,#666666), color-stop(50%,#333333), color-stop(51%,#666666), color-stop(60%,#ffffff)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 40%,#666666 49%,#333333 50%,#666666 51%,#ffffff 60%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 40%,#666666 49%,#333333 50%,#666666 51%,#ffffff 60%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 40%,#666666 49%,#333333 50%,#666666 51%,#ffffff 60%); /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 40%,#666666 49%,#333333 50%,#666666 51%,#ffffff 60%); /* W3C */
    height: 1.6em;
}
.jsonForm input[type="range"]{
    -webkit-appearance:none;
    -moz-apperance:none;
    cursor: ew-resize;
    height: 1.6em;
    padding:0 4px;
}
.jsonForm input[type="range"]:focus{
    outline:none;
}
.jsonForm input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}
.jsonForm input[type=range]::-webkit-slider-runnable-track {

    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
.jsonForm input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
.jsonForm input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
    /*remove default tick marks*/
    color: transparent;
}
.jsonForm input[type=range]::-webkit-slider-thumb {
    -webkit-appearance:none;
    width:10px; height:15px;
    margin-top: -5px;
    -webkit-border-radius:5px; -moz-border-radius:5px; -ms-border-radius:5px; -o-border-radius:5px; border-radius:5px;
    background-color:#aaa;
}
.jsonForm input[type=range]::-moz-range-thumb {
    -moz-apperance:none;
    width:10px; height:15px;
    margin-top: -5px;
    -webkit-border-radius:5px; -moz-border-radius:5px; -ms-border-radius:5px; -o-border-radius:5px; border-radius:5px;
    background-color:#aaa;
}
.jsonForm input[type=range]::-ms-thumb {
    width:10px; height:15px;
    margin-top: -5px;
    -webkit-border-radius:5px; -moz-border-radius:5px; -ms-border-radius:5px; -o-border-radius:5px; border-radius:5px;
    background-color:#aaa;
}



.jsonForm .rangeslider-wrapper {position:relative; height: 1.6em;
    padding:0;}
.jsonForm .rangeslider-wrapper > .rangeslider-marks {
    display:block; position:absolute; bottom:50%; left:10px; right:10px;
    z-index:100;
}
.jsonForm .rangeslider-wrapper > .rangeslider-marks tt {
    width:100px; text-align:center; margin-left:-50px;
    display:block; position:absolute; top:0; left:0;
    z-index:101; color:rgba(0,0,0,.6); font-size:11px; line-height:15px; pointer-events:none;
}
.jsonForm .rangeslider-wrapper > .rangeslider-marks tt + tt {
    left:100%;
}
.jsonForm input[type=range] + .rangeslider-marks > .rangemarker {
    content:"";
    display:none; position:absolute; bottom:100%; margin-bottom:20px; left:0; width:3em; margin-left:-1.5em; padding:0.25em 0 0.2em; background:#555; border-radius:3px;
    box-shadow: 0px 3px 5px 2px rgba(0,0,0,.4);
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;;
    line-height:1em;
    text-align: center;
    text-shadow: 0px 1px 1px #333333;
}
.jsonForm input[type=range] + .rangeslider-marks > .rangemarker:after {
    content:"";
    display:block; position:absolute; top:100%; left:50%; margin-left:-10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #555;
}
.jsonForm input[type=range]:focus {
    outline: none;
}
.jsonForm input[type=range]:active + .rangeslider-marks > .rangemarker {
    display:block;
}


.jsonForm input[type=date],
.jsonForm input[type=month] {

    font-size: 1em;
    line-height: 28px!important;
    width: 100%;
    padding: 0 1px;
}
.jsonForm input[type=date]::-webkit-datetime-edit-month-field,
.jsonForm input[type=month]::-webkit-datetime-edit-month-field {
    /*color: red;*/
}
.jsonForm input[type=date]::-webkit-datetime-edit-day-field,
.jsonForm input[type=month]::-webkit-datetime-edit-day-field {
    /*color: white;*/
}
.jsonForm input[type=date]::-webkit-datetime-edit-year-field,
.jsonForm input[type=month]::-webkit-datetime-edit-year-field{
    /*color: blue;*/
}
.jsonForm input[type=date]::-webkit-clear-button,
.jsonForm input[type=month]::-webkit-clear-button {
    font-size: 1em;
    line-height: 30px;
    margin:0;
    /*  position: relative;
      right: 5px;
      margin-right: 4px;*/
}
.jsonForm input[type=date]::-webkit-inner-spin-button,
.jsonForm input[type=month]::-webkit-inner-spin-button {
    /*height: 1em;*/
    margin:0;
}
.jsonForm input[type=date]::-webkit-calendar-picker-indicator,
.jsonForm input[type=month]::-webkit-calendar-picker-indicator {
    /*font-size: 18px;*/
}
.jsonForm input[type=date]::-webkit-calendar-picker-indicator:hover,
.jsonForm input[type=month]::-webkit-calendar-picker-indicator:hover {
    /*background-color: #959595;
    color: #e6e6e6;
    cursor: pointer;*/
}
.jsonForm input[type=date]::-webkit-calendar-picker-indicator:active,
.jsonForm input[type=month]::-webkit-calendar-picker-indicator:active {
    /*color: blue;*/
}


/* Chosen() style overwrites */
.jsonForm .chosen-container {
    max-width:70%;
    font-size: inherit;
    line-height:32px;
}
.jsonForm .chosen-container-single .chosen-single {
    height:auto;
    min-height:32px;
    line-height:30px;
    background:white;
    border-radius:0;
    box-shadow:none;
    border:1px solid rgba(0, 0, 0, 0.3);
}
.jsonForm .chosen-container-single .chosen-single div b {
    background-position:0 5px;
}

.jsonForm .chosen-container-multi{
    width: 100%!important;
    max-width: 70%!important;
}

.jsonForm .chosen-container-multi .chosen-drop {
    width: 100% !important;
}
#my_select_chzn .chzn-drop .chosen-search {
    width: 99% !important;
}
#web_template_exploit_name_chzn .chosen-drop .chosen-search input {
    width: 98% !important;
}

.jsonForm .chosen-container-multi .chosen-choices {
    border-radius:0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    /*background-color:rgba(255,255,255,0.8);
    box-shadow: 0 2px 4px -2px #999999 inset;*/
    background-image:none;
}

.jsonForm .chosen-container-active.chosen-with-drop .chosen-single {
    background:white;
    border-radius:0;
}

.jsonForm .chosen-container .chosen-drop,
.jsonForm .chosen-container-single .chosen-drop {
    border-radius:0;
    box-shadow:none;
    background:white;
    z-index:999999999;
}

.jsonForm .chosen-container .chosen-with-drop .chosen-single {
    background:white;
}

.jsonForm .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position:-18px 5px;
}

.jsonForm .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: auto;
    min-height: 20px;
    padding: 4px;
}

.jsonForm .chosen-container-active {
    border:none;
}



.jsonForm .chosen-container-multi .chosen-choices {
    /*
    background: -webkit-linear-gradient: (center top , #FFFFFF 20%, #F6F6F6 50%, #EEEEEE 52%, #F4F4F4 100%) repeat scroll 0 0 padding-box rgba(255,255,255,0.8);
    background: -moz-linear-gradient(center top , #FFFFFF 20%, #F6F6F6 50%, #EEEEEE 52%, #F4F4F4 100%) repeat scroll 0 0 padding-box rgba(255,255,255,0.8);
    */
}

.jsonForm .chosen-container-active .chosen-single,
.jsonForm .chosen-container-active .chosen-choices {
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: none;
    background-color:#fff;
}






.jsonForm *:focus {
    outline:1px dotted rgba(0,0,0,.5);
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

.jsonForm button,
.jsonForm input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

.jsonForm button,
.jsonForm select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

.jsonForm button,
html .jsonForm input[type="button"], /* 1 */
.jsonForm input[type="reset"],
.jsonForm input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

.jsonForm button[disabled],
html .jsonForm input[disabled],
.jsonForm *:disabled,
.jsonForm button[read-only],
html .jsonForm input[read-only],
.jsonForm button[readonly],
html .jsonForm input[readonly],
.jsonForm input.readonly {
    cursor: default;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-opacity: 0.6;
    -o-opacity: 0.6;
    opacity: 0.6;
    box-shadow: none;
    pointer-events:none;
}

.jsonForm input[disabled] + label.onoffswitch-label {
    cursor: default;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-opacity: 0.6;
    -o-opacity: 0.6;
    opacity: 0.6;
    box-shadow: none;
    pointer-events:none;
    -webkit-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
}


.readonly a.button.upload, .readonly a.button.download + span {
    display: none;
}
body.readonlyMode .rowbuttonwrapper.jf-row {
    opacity: 0.3;
    pointer-events:none;
    filter: grayscale(1);
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

.jsonForm input[type="checkbox"],
.jsonForm input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

.jsonForm input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

.jsonForm input[type="search"]::-webkit-search-cancel-button,
.jsonForm input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

.jsonForm button::-moz-focus-inner,
.jsonForm input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

.jsonForm textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
    resize: vertical;
    min-height: 26px;
    height: 6em;
}

.jsonForm textarea.ckeditor {
    min-height: 50px;
}



/**
 * 1. Firefox 18-
 * 2. Firefox 19+
 */
.jsonForm *::-webkit-input-placeholder {
    color: #dddddd;
}
.jsonForm *:-moz-placeholder { /* 1 */
    color: #dddddd;
}
.jsonForm *::-moz-placeholder {  /* 2 */
    color: #dddddd;
}
.jsonForm *:-ms-input-placeholder {
    color: #dddddd;
}



/* apply a natural box layout model to all elements */
.jsonForm *, .jsonForm *:before, .jsonForm *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.jsonForm {
    margin:0;
    position:relative;
    -webkit-transition: opacity .25s;
    -moz-transition: opacity .25s;
    -ms-transition: opacity .25s;
    -o-transition: opacity .25s;
    transition: opacity .25s;
    opacity:0.3;
}
.jsonForm.ready {opacity:1;}
.jsonForm.ready .jsonForm {opacity:1;}
.jsonForm.ready .jf-header-section,
body > form.jsonForm.ready > section > fieldset {max-height:inherit; overflow:visible;}

.jsonForm .jf-header-bounds,
.jsonForm > section > header > h2,
.jsonForm > section > .intro,
.jsonForm > section > fieldset,
.jsonForm > section > .not-applicable {
    width:90%;
    max-width: 1300px;
    margin-left:auto;
    margin-right:auto;
    padding-left:10px;
    padding-right:10px;
}


.jf-header-section {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 999999;
}
.jf-header-section.fixed {position:fixed; top:0;}
.jf-header-section.fixed + section {margin-top:52px;}

.jsonForm .jf-header-bounds {position:relative;}

.jsonForm ul.jf-page-control {list-style:none; display:table; /*position: fixed;*/margin:0; text-align:left; /*width: 100%;*/background: #fff;/*border-bottom: 1px solid #ddd;*/z-index: 999999;padding:0; line-height:0;
    margin-left: -3px;}
.jsonForm ul.jf-page-control:before {
    /* background: #EEEEEE; */
    /* border-radius: 10px; */
    /* content: ""; */
    display: block;
    height: 16px;
    left: -10px;
    margin-top: -10px;
    position: absolute;
    right: -10px;
    top: 50%;
    z-index: -1;
}
.jsonForm ul.jf-page-control *:focus {outline:none;}
.jsonForm ul.jf-page-control li {display:inline-block; margin: 0px 3px; padding:7px 1px; border-top:3px solid transparent; border-bottom:solid 3px transparent; vertical-align:middle;}
.jsonForm ul.jf-page-control li:first-of-type {padding-left:0;}
.jsonForm ul.jf-page-control li a {border: 1px solid #6D6E71; display:block; min-width: 30px; /* height:40px; */ font-size: 14px; line-height:28px; overflow:hidden; text-align:center; background: #fff; /* border-radius:50%; */ padding:0; margin:0; color:#666; text-decoration:none;}
/*.jsonForm ul.jf-page-control li.current a,*/
.jsonForm ul.jf-page-control li:hover a {background:#f1f2f2; border-color:#6D6E71;}
.jsonForm ul.jf-page-control li.current {border-bottom:solid 3px #6D6E71; padding:6px 0px;}
.jsonForm ul.jf-page-control li.current a {/*border-color: #83C5D8; background-color: #ECFDFF;*/ color:#222; border-width:2px; min-width:32px;}

.jsonForm ul.jf-page-control li a.valid {border-color: #009444;}
.jsonForm ul.jf-page-control li a.unfilled {border-color: #F7941E;}
.jsonForm ul.jf-page-control li a.invalid {border-color: #BE1E2D;}
.jsonForm ul.jf-page-control li a.na {border-color: #cccccc;}

.jsonForm ul.jf-page-control-title {
    display: block;
    position:absolute;
    top: 13px;
    right: 195px;/* 10px; */
    z-index: 9999999;
    list-style-type: none;
    text-indent: inherit;
    margin:0;
    padding:0;
    text-align:right;
}
.jsonForm ul.jf-page-control-title li {
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0;
    max-height: 0px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    font-weight: 100;
}

.jsonForm ul.jf-page-control-title li.current {
    font-size: 18px;
    max-height: 40px;
    opacity: 1;
    line-height: 25px;
}
.jsonForm ul.jf-page-control-title li a {
    text-decoration:none;
    color: #A1A1A1;
}

.readonly.jsonForm ul.jf-page-control-title {
    right: 10px;
}

.jsonForm .jf-pages-cassette {
    background: #fff;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
}

/*Issues Summary*/
button#main_summary {display:none; outline: none;}
.invalid button#main_summary {background:#BE1E2D; display:block;}
.invalid button#main_summary:after {
    border-color: rgba(190, 30, 45, 0);
    border-top-color: #BE1E2D;
}
.incomplete button#main_summary {background:#F7941E; display:block;}
.incomplete button#main_summary:after {
    border-color: rgba(247, 148, 30, 0);
    border-top-color: #F7941E;
}
.complete button#main_summary {background:#95BD3E; display:block;}
.complete button#main_summary:after {
    border-color: rgba(149, 189, 62, 0);
    border-top-color: #95BD3E;
}

#issues .issues,
#issues .todo,
#issues .complete {height:0; overflow:hidden; padding:0;}

#issues .title {text-transform:uppercase; font-weight:600; font-size:18px;}
#issues ul {list-style-type:none; padding-left:30px;}
#issues ul li {position:relative; margin-bottom:20px;}
#issues ul li a {color:#414042; font-size:16px;}
#issues ul li a span {color:#BE1E2D; width:100%; display:none;}
#issues ul li a span + span {background:#BE1E2D; color:white; text-transform:uppercase; font-size:14px; width:auto; padding:2px 18px 2px 8px; position:relative;}

.invalid #issues .issues {height:100%; overflow:auto; background:#F7E9E3; background:rgba(247,233,227,0.5); padding:20px 0 50px 0;}
.invalid #issues .issues ul li a span {display:inline-block;}
.invalid #issues .issues ul li:before {color:#BE1E2D;}

.incomplete #issues .todo {height:100%; overflow:auto; background:#FBB040; background:rgba(251,176,64,0.15); padding:20px 0 50px 0;}
.incomplete #issues .todo ul li:before {color:#F7941E;}

.complete #issues .complete {height:100%; overflow:auto; background:#95BD3E; background:rgba(149,189,62,0.3); padding:20px 0 50px 0;}



.jsonForm section {padding:0 0 30px ;margin: 0 auto 0 auto;}
.jsonForm section.current {
    /*overflow: auto;*/
    background:#FEFEFE;
}
.jsonForm > section:last-of-type {padding-bottom:60px;}
.jsonForm fieldset {margin:10px 0px; padding:10px; background: transparent;position: relative; border:0;}

.jsonForm > section > header {background:#eee;}

.jsonForm section h2 {
    /*background:#eee;*/
    padding: 10px;
    margin:0 auto;
}
.jsonForm section h2:before {
    content: attr(data-page-prefix);
}
#declaration .jsonForm section h2:before {
    content: "";
}

.jsonForm .jf-row > .collection {
    padding:0;
    vertical-align: middle;
    /* 060116 display: inline-block; */
    display: block; margin-bottom: 10px;
    position:relative;
}
.jsonForm .jf-row > .collection > label {
    background-color:#FFFFFF;
    /*background-color:rgba(255,255,255,0.8);*/
    border: 0 none;
    border-radius: 3px;
    display: inline-block;
    margin: 0 3px 0 0;
    min-width: 60px;
    padding: 0 4px;
    line-height:32px;
    vertical-align: -webkit-baseline-middle;
}
.jsonForm .jf-row > .collection > label:focus, .jsonForm .jf-row > .collection > label:active {
    background-color:#FFFFFF;
}
.jsonForm .jf-row > .collection > label > input {
    margin: 0 6px 0 0;
}

.jsonForm .linkedFormWrapper .jf-row > .collection {
    white-space:nowrap;
}

.jsonForm input[type="hideme"] {
    display:none!important;
}

.jsonForm input[type="checkbox"],
.jsonForm input[type="radio"] {
    width:auto;
}

.jsonForm dfn {
    font-style:normal;
    /*border-bottom: 1px dotted #1C75BC;*/
    position:relative;
    cursor: help;
    /*text-decoration: o;*/
    white-space:nowrap;
}
.jsonForm dfn:hover {
    /*background-color: #EBF6FC;*/
    background:#E9EDF7;
    cursor: help;
}
.jsonForm dfn:after {
    /*content: "(i)";*/
    color: #1C75BC;
    font-size:24px!important;
    width:22px;
    height:22px;
    /*margin-top:-3px;*/
    line-height:24px!important;
}

.jsonForm dfn:hover:before {
    content: attr(data-title);
    font-weight:normal;
    display:inline-block;
    position:absolute;
    z-index:999;
    top:100%;
    left:0;
    margin:0.3em 0 0 auto;
    min-width:300px;
    width:300px;
    /*background:#D8ECFA;*/
    /*border:1px solid #4AAFF7;*/
    padding:10px;
    -webkit-box-shadow:5px 5px 10px 0px rgba(0,0,0,0.6);
    -moz-box-shadow:5px 5px 10px 0px rgba(0,0,0,0.6);
    box-shadow:5px 5px 10px 0px rgba(0,0,0,0.6);
    font-size:.9em;
    line-height: 1.2em;
    text-align:left;
    white-space:normal;

    color: rgb(63, 136, 168);
    color: #1C75BC;
    border:1px solid #1C75BC;
    background:#E9EDF7;
}

.jsonForm th dfn {
    display: inline;
    white-space: nowrap;
}


.jsonForm input, .jsonForm textarea {
    /*background-color:rgba(255,255,255,0.8);*/
}
.jsonForm input:focus,
.jsonForm textarea:focus {
    outline: none;
    background-color:#fff;
    box-shadow: 0 0 6px #1C75BC;
    border-color: #1C75BC;
}


.jsonForm *:required:focus {
    box-shadow: 0 0 6px #F7941E;
}
.jsonForm input:required:focus:invalid,
.jsonForm textarea:required:focus:invalid,
.jsonForm input.error:required:focus,
.jsonForm textarea.error:required:focus {
    box-shadow: 0 0 6px #BE1E2D;
    border-color: #BE1E2D;
}
.jsonForm input:required:focus:valid,
.jsonForm textarea:required:focus:valid,
.jsonForm input.valid:required:focus,
.jsonForm textarea.valid:required:focus {
    box-shadow: 0 0 6px #009444;
    border-color: #009444;
}


.jsonForm *:required,
.jsonForm .linkedFormWrapper fieldset *:required {
    border-left:3px solid #F7941E;
}
.jsonForm [data-answered=true]:required:valid,
.jsonForm [data-answered=true].valid:required  {
    border-left:3px solid #009444;
}
.jsonForm [data-answered=true]:required:invalid,
.jsonForm [data-answered=true].error:required {
    border:1px solid #BE1E2D;
    border-left-width:3px;
}

/* 220317 added logic for invalid but unrequired fields */
.jsonForm [data-answered=true]:invalid {
    border: 1px solid #BE1E2D;
    border-left-width: 3px;
}
.jsonForm [data-answered=true]:focus:invalid {
    box-shadow: 0 0 6px #BE1E2D;
    border-color: #BE1E2D;
}

/* 220317 added logic for chosen dropdowns */
.jsonForm .chosen-container .chosen-single {
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.jsonForm select:required + .chosen-container .chosen-single {
    border-left-width: 3px;
    border-left:3px solid #F7941E;
}
.jsonForm [data-answered=true]:required:valid + .chosen-container .chosen-single,
.jsonForm [data-answered=true].valid:required + .chosen-container .chosen-single {
    border-left-color: #009444;
}
.jsonForm [data-answered=true]:invalid + .chosen-container .chosen-single {
    border-left-color: #BE1E2D;
}
.jsonForm [data-answered=true]:focus:invalid + .chosen-container .chosen-single {
    box-shadow: 0 0 6px #BE1E2D;
    border-color: #BE1E2D;
}

/* for nested forms */
.jsonForm .linkedFormWrapper fieldset *:required {
    border:1px solid rgba(0, 0, 0, 0.3);
}
.jsonForm .linkedFormWrapper fieldset[data-hasanswers=true] *:required {
    border-left:3px solid #F7941E;
}
.jsonForm .linkedFormWrapper fieldset[data-hasanswers=true] [data-answered=true]:required:valid,
.jsonForm .linkedFormWrapper fieldset[data-hasanswers=true] [data-answered=true].valid:required  {
    border-left:3px solid #009444;
}
.jsonForm .linkedFormWrapper fieldset[data-hasanswers=true] [data-answered=true]:required:invalid,
.jsonForm .linkedFormWrapper fieldset[data-hasanswers=true] [data-answered=true].error:required {
    border:1px solid #BE1E2D;
    border-left-width:3px;
}






/*.jsonForm label.error:before {
    content: "";
    display:none;
    position:absolute;
    left: 50%;
    width: 0px;
    height: 0px;
    top: 100%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #CA1F1F;
}*/
.jsonForm label.error:before {
    content: "";
    display: none;
    position: absolute;
    left: 0px;
    width: 0px;
    height: 0px;
    top: 100%;
    margin-left: 0px;
    border: 5px solid transparent;
    border-top-color: #CA1F1F;
}

.jsonForm label.error {
    background-color: #CA1F1F !important;
    color: #ffffff;
    right: -200px;
    /*left: -200px;*/
    left: 0px;
    margin: 0 auto -1em auto !important;
    font-size: 0px;
    padding: 0!important;
    cursor: help;
    position: absolute;
    bottom: 100%;
    z-index: 999;
    max-height: 0%;
    min-width: 100px!important;
    min-height: 0!important;
    white-space: nowrap;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
    line-height: 28px!important;
    height: 28px;
    box-shadow: 0px 0px 10px #000;
    display: none;
    text-align: center !important;
}
.jsonForm label.error {
    background-color: #CA1F1F !important;
    color: #ffffff;
    /* right: -200px; */
    /* left: -200px; */
    left: 0px;
    right: auto;
    border-radius: 0!important;
    margin: 0 auto -1em auto !important;
    font-size: 0px;
    padding: 0!important;
    cursor: help;
    position: absolute;
    bottom: 100%;
    z-index: 999;
    max-height: 0%;
    min-width: 100px!important;
    min-height: 0!important;
    white-space: nowrap;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
    line-height: 28px!important;
    height: 28px;
    box-shadow: 0px 10px 6px -8px rgba(0,0,0,0.5);
    display: none!important;
    text-align: center !important;
}
.jsonForm *:focus + label.error,
.jsonForm .collection:hover ~ label.error,
    /*.jsonForm .collection:hover label.error,*/
.jsonForm .collection label:hover ~ label.error,
.jsonForm * + label.error:hover,
.jsonForm .focusfade + label.error,
.jsonForm .onoffswitch:hover ~ label.error {
    max-height:100%;
    font-size: 12px;
    /*v2 padding: 0 10px !important;*/
    padding: 0px;
    margin-bottom:-0.6em;
    max-width: 100%;
    white-space: normal;
    display:block!important;
}

.jsonForm [data-answered=true].error:focus + label.error,
.jsonForm [data-answered=true].error:focus ~ label.error,
.jsonForm [data-answered=true].error:hover ~ label.error {

}
/* overwrite js calc'ed width if its in a collection as these are full side anyway) */
.jsonForm .collection ~ label.error[style],
.jsonForm .collection label.error[style] {
    width:auto!important;
    padding:0 10px!important;
}

.jsonForm .collection ~ label.error,
.jsonForm .collection label.error,
.jsonForm td label.error {
    margin-bottom:0em!important;
}
/**/
.jsonForm *:focus + label.error:before,
.jsonForm .collection:hover ~ label.error:before,
    /*.jsonForm .collection:hover label.error:before,*/
.jsonForm .collection label:hover ~ label.error:before,
.jsonForm * + label.error:hover:before,
.jsonForm .focusfade + label.error:before,
.jsonForm .onoffswitch:hover ~ label.error:before {
    display:block;
}

/*
.jsonForm td .focusfade + label.error {
    margin: 0 -200px;
    min-width: 500px!important;
}
*/
/*
.jsonForm .filled + label.error,
.jsonForm .focusfade + label.error {
    display:block;
}
*/
.jsonForm .valid + label.error,
.jsonForm :valid + label.error {
    display: none!important; /* this wont work because jquery.validate sets display:block inline and doesnt set.valid until on blur */
    visibility:hidden!important; /* use this instead */
    opacity:0!important;
}


.jsonForm .not-applicable,
.jsonForm .major-prop-not-applicable {
    display: block;
    position:relative;
    /*background: rgba(255,255,55,.1);*/
    background: rgba(171, 202, 91, 0.2);
    padding: 10px;
    border-radius: 3px;
    color: rgba(0,0,0,.8);
}
.jsonForm .not-applicable:before {
    content:"";
    display:block;
    position:absolute;
    left:10px;
    width:0;
    height:0;
    bottom:100%;
    margin:auto;
    border: 8px solid transparent;
    border-bottom-color:rgba(255,255,255,.3);
}



.jsonForm .jf-row {
    display:block;
    position:relative;
    margin:4px 0;
    padding:0px;
    /* background:rgba(0,0,0,.05); */
    width:100%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.jsonForm .jf-row > label,
.jsonForm .jf-row > input,
.jsonForm .jf-row > textarea,
.jsonForm .jf-row > select {
    display:inline-block;
    vertical-align: middle;
    width:100%;
    max-width:inherit;
    padding:4px;
    min-height: 1em;
}
.jsonForm .jf-row > .chosen-container {/*220317*/
    display:inline-block;
    vertical-align: middle;
    width:100%;
    max-width:inherit;
}

.jsonForm .jf-row > input,
.jsonForm .jf-row > select{
    /*padding:0 4px;*/
    line-height:30px; min-height:32px;
    padding: 0 2px 0 4px;
}

.jsonForm .jf-row > .chosen-container {/*220317*/
    display:inline-block;
    vertical-align: middle;
    /*width:100%;
    max-width:inherit;*/
}

.dialog-box .jsonForm .jf-row > .chosen-container{
    width: 100%!important;
}

.jsonForm .jf-input-type-textarea > label,
.jsonForm .jf-input-type-textarea > textarea {
    vertical-align: top;
}

.jsonForm .jf-input-type-textarea ~ .jf-input-type-file {
    margin-top:10px;
}

.jsonForm .jf-row > div.cke {
    display:inline-block;
    vertical-align:middle;
    width:100%;
    padding:0;
    margin:0;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    overflow: hidden;
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.jsonForm .jf-row > div.cke.cke_chrome {
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.jsonForm .jf-row > label {
    padding:4px 10px 4px 0;
    display:inline-block;
    min-width:30%;
    max-width:70%;
    width:auto;
}

.jsonForm .jf-row > label.inline-append {
    visibility: hidden;
}
.jsonForm #wrapper-for-i1-4-2 > label.inline-append {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}
.jsonForm #wrapper-for-i1-4-2 .collection {margin-top:-10px;}
.jsonForm .linkedFormWrapper .collection {margin:0px!important;}

.jsonForm .jf-row label + input,
.jsonForm .jf-row label + textarea,
.jsonForm .jf-row label + select,
.jsonForm .jf-row label + div.cke {
    width:100%;
}
.jsonForm .jf-row label + input[type=radio],
.jsonForm .jf-row label + input[type=checkbox]{
    /* width:auto; */
}

.jsonForm .jf-row .input-wrapper {
    display:inline-block;
    vertical-align: middle;
    /*width:70%;*/
}
.jsonForm .jf-row .input-wrapper input {
    width:100%;
}


/*110316 hide unless prev equals */
.jsonForm .disabled + .onoffswitch-label,
.jsonForm .disabled {
    opacity: 0.1;
    pointer-events: none;
}
.jsonForm .disabled + .onoffswitch-label .ison,
.jsonForm .disabled + .onoffswitch-label .isoff {
    background-color:#666;
}



/* radio / checkbox custom button style > */
.jsonForm .focb:before {
    content: "\00a0";  /*nbsp*/
    display: inline-block;
    width: 1.1em;
    height: 1.1em;
    margin: auto 6px auto 0;
    /*position: absolute;
    left: 0;  */
    background-color: #fff;

    font-size: 1.1em;
    font-weight:bold;
    text-align: center;
    line-height: 1.1em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);

    border:1px solid rgba(0, 0, 0, 0.3)
}

.jsonForm input[type=radio].focbed {
    /*display: none;  */
    opacity:0.001;
    position:absolute;
    height:2em;
    width:2em;
}
.jsonForm input[type=radio] + .focb:before {
    border-radius: 50%;
}
.jsonForm input[type=radio]:checked + .focb:before {
    /*content: "\2022";  bullet*/
    background-color: #333;
    box-shadow: 0 0 0 4px #FFFFFF inset, 0 0 1px 0px #333;
    position: relative;
}
.jsonForm input[type=radio]:required:checked + .focb:before {
    box-shadow: 0 0 0 4px #FFFFFF inset, 0 0 1px 1px #99CC00;
}


.jsonForm input[type=checkbox].focbed {
    /*display: none;  */
    opacity:0.001;
    position:absolute;
    height:2em;
    width:2em;
}
.jsonForm input[type=checkbox] + .focb {
    border-radius: 3px;
}
.jsonForm input[type=checkbox]:checked + .focb:before {
    content: "\2713";  /* tick*/
    color: #333333;
}
.jsonForm input[type=checkbox].filled:required + .focb:before {
    box-shadow: 0 0 1px 1px red;
}
.jsonForm input[type=checkbox]:required:checked + .focb:before {
    box-shadow: 0 0 1px 1px green;
}


.jsonForm input[data-answered=false]:required + .focb:before{
    border-color:#F7941E;
}
.jsonForm input[data-answered=true]:required:checked + .focb:before{
    border-color:#009444;
}

.jsonForm [data-isanswered=true] input:required + .focb:before{
    border-color:#cccccc;
}
.jsonForm [data-isanswered=true] input:required:checked + .focb:before{
    border-color:#009444;
}
.jsonForm .linkedFormWrapper fieldset[data-hasanswers=false] input[type=radio]:required {
    border-color:#cccccc
}

/* < END radio / checkbox style */




/* 170316 Notes field */
.jsonForm .notesDiv {

}
.jsonForm .notesDiv label.notesLabel {
    width: auto;
    min-width: 0;
    text-align: right;
    color: #1E76BC;
    position: absolute;
    top: -2.5em;
    height: 2em;
    line-height: 2em;
    right: 0;
    padding: 0 10px;
    background: #EFF3F5;
    font-size: .8em;
    color: #999;
}
.jsonForm .notesDiv label.notesLabel {
    background-color: #eee;
    background:transparent url('/proposalform/v2016v2/jsonForm.2.0.0/img/notes.svg') center right no-repeat;
    text-indent: 25px;
    max-width: 20px;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 0px;
    padding: 0 25px;
    box-sizing: border-box;
}
.jsonForm .notesDiv label.notesLabel:hover {
    max-width:300px;
}
.jsonForm .notesDiv textarea[data-notes-field] {
    display: block;
    border: 1px dashed rgb(204, 204, 204);
    margin-top: 0px;
    margin-bottom: 20px;
    height: 120px;
    background-color: white;
    font-family: Georgia;
    font-style: italic;
    color: #1C86BC;
    max-height:480px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jsonForm .notesDiv textarea[data-notes-field]:focus {
    border-radius: 0;
    box-shadow: 0 0 6px #EFF3F5;
}
.jsonForm .notesDiv textarea.ishidden {
    max-height: 0px;
    min-height: 0px;
    resize: none;
    border-width: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px!important;
}
.notesPencilIconOnly {
    display: inline-block;
    vertical-align: middle;
    background-color: #eee;
    background: transparent url(/proposalform/v2016v2/jsonForm.2.0.0/img/notes.svg) center right no-repeat;
    text-indent: 25px;
    max-width: 20px;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 0px;
    padding: 0;
    box-sizing: border-box;
    min-height: 20px;
}


.jsonForm input[type="submit"] {

    background-color:#1C75BC;
    text-indent:0;
    border:1px solid #1C75BC;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    display:inline-block;
    color:white;
    font-family: 'Roboto Condensed', sans-serif;
    font-size:17px;
    font-weight:700;
    font-style:normal;
    padding:/*10px*/ 0 15px;
    /*line-height:1em;*/
    line-height:30px;
    width:auto;
    text-decoration:none;
    text-align:center;
    /*text-shadow:1px 1px 0px #ffffff;*/
    margin-top: 10px;
    float:right;
    text-transform:uppercase;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jsonForm input[type="submit"]:hover {

    background-color:#009444;
    border:1px solid #009444;
}
.jsonForm input[type="submit"]:active {
    position:relative;
    top:1px;
    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
    box-shadow:inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
}




.jsonForm .jf-error-msg {color:red;}

/* */
.jsonForm .onoffswitch {
    position: relative; width:92px!important;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.jsonForm .onoffswitch-checkbox {
    /*display: none;*/
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: 1px;
}

.jsonForm label.focb{
    /*display:none;*/ /*pointer-events:none;*/
    color: rgba(50, 50, 50, 0.5);
    padding-top: 6px;
}

.jsonForm .collection label.focb{
    pointer-events:none;
}

.jsonForm .onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border:1px solid #999999; border-radius: 0; margin:0;
}

.jsonForm .onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    -moz-transition: margin 0.1s ease-in 0s; -webkit-transition: margin 0.1s ease-in 0s;
    -o-transition: margin 0.1s ease-in 0s; transition: margin 0.1s ease-in 0s;
}
.jsonForm .onoffswitch .ison, .jsonForm .onoffswitch .isoff {
    display: block; float: left; width: 50%; height:30px; padding: 0; line-height: 30px;
    font-size: 1em; color: white; /*font-weight: bold;*/ font-style:normal;
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
    box-shadow:0 2px 4px -2px rgba(0,0,0,.3) inset
}
.jsonForm .onoffswitch .ison{
    padding-right: 25%;
    background-color: #009444; color: #FFFFFF;
    text-align: center;
}
.jsonForm .onoffswitch .isoff  {
    padding-left: 25%;
    background-color: #BE1E2D; color: #FFFFFF;
    text-align: center;
}
.jsonForm .onoffswitch-switch {
    display: block; width: 50%; margin: 0px;
    background: #E6E7E8;
    border:1px solid rgba(0,0,0,0.3); border-radius: 0;
    position: absolute; top:0px; bottom:0px;
    right: 56px;
    right: 100%;
    margin-right: -50%;
    box-shadow: inset 0 0 0px 1px #fff;
    -moz-transition: all 0.1s ease-in 0s; -webkit-transition: all 0.1s ease-in 0s;
    -o-transition: all 0.1s ease-in 0s; transition: all 0.1s ease-in 0s;
}
.jsonForm .onoffswitch-switch:after,
.jsonForm .onoffswitch-switch:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 16px;
    width: 6px;
    margin: auto;
    border: 0px solid rgba(0,0,0,0.3);
    border-width: 0 1px;
    color: rgb(204, 204, 204);
}
.jsonForm .onoffswitch-switch:before {
    width: 16px;
}
.jsonForm .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.jsonForm .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
    margin-right: 0;
}
.jsonForm .onoffswitch-placeholder {
    display: inline-block;
    color: rgba(0,0,0,0.4);
    /*padding: 0 0 0 10px;*/
    padding:0;
    vertical-align: top;
    line-height: 1.9em;
}




.jsonForm .fileuploader {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: middle; /* 3 */
    *vertical-align: middle; /* 3 */
    font-family:"proxima-nova",sans-serif;
    background-color:white;
    color:#aaa;
    font-size:0.8em;
    min-width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    position:relative;
}
.jsonForm .fileuploader .thumb {
    width: auto;
    height: 26px; /* 32 */
    /* margin: 1px;*/
    margin: 1px 1px 1px 3px;
    vertical-align: middle;
}
.jsonForm .fileuploader button {
    background-color:#1C75BC;
    text-indent:0;
    border:1px solid #1C75BC;
    /*-webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;*/
    display:inline-block;
    color:white;
    font-family:"Roboto Condensed",sans-serif;
    font-size:1.2em;
    font-weight:700;
    font-style:normal;
    padding:0 5px;
    line-height:28px;
    width:auto;
    text-decoration:none;
    text-align:center;
    /*text-shadow:1px 1px 0px #ffffff;*/
    vertical-align: middle;
    /*min-height: 32px;*/
    text-transform:uppercase;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jsonForm .fileuploader button:hover,
.jsonForm .fileuploader .hover {
    background-color:#009444;
    border:1px solid #009444;
}
.jsonForm .fileuploader button:active {
    position:relative;
    -moz-box-shadow:inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
    -webkit-box-shadow:inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
    box-shadow:inset 0px 1px 0px 0px #ffffff, 0 0 3px -2px #000000;
}
.jsonForm .fileuploader button:focus {
    outline:none;
}
.jsonForm .fileuploader .chooseFile {
    /*overflow: hidden;*/
    white-space: nowrap;
    text-overflow: ellipsis;
    position:relative;
    min-height: 30px;
    line-height: 30px;
    vertical-align: middle;
}
.jsonForm .fileuploader button.deleteFileButton {
    background: #5B89B2;
}
.jsonForm .fileuploader .chooseFile span {
    padding: 0 4px;
    display: inline-block;
    /* line-height: 30px; */
    float: none;
    overflow: hidden;
    max-width: 150px;
    text-overflow: ellipsis;
    vertical-align: middle;
}
.jsonForm .fileuploader .chooseFile span.warning {
    margin:0px; display:block; padding:0px 0px 0px 24px; color:#666;
    background:rgb(255, 245, 192) url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="23px" height="16px" viewBox="0 0 23 16" enable-background="new 0 0 23 16" xml:space="preserve"><path fill="#FF9900" d="M8,2.48L2.612,14h10.777L8,2.48z M8,0L8,0c0.345,0,0.689,0.233,0.951,0.698l6.828,13.61 C16.303,15.239,15.857,16,14.791,16H1.208c-1.067,0-1.512-0.761-0.989-1.691l6.829-13.61C7.31,0.233,7.654,0,8,0L8,0z M7,12 c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S7,12.552,7,12z M8,6c0.552,0,1,0.448,1,1l-0.312,3H7.312L6.999,7 C6.999,6.448,7.447,6,8,6L8,6z"/></svg>') 4px center no-repeat;
}
.jsonForm .fileuploader .cropDisplay {
    display: block;
    background: rgb(240, 240, 240);
    border: 1px solid #ccc;
    margin: 0px 2px 2px;
    border-radius: 2px;
    padding: 0px;
}
.jsonForm .fileuploader .cropDisplay .cropWarning {
    margin:4px; padding:4px 4px 4px 24px; color:#666; min-height:16px;
    background:rgb(255, 245, 192) url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="23px" height="16px" viewBox="0 0 23 16" enable-background="new 0 0 23 16" xml:space="preserve"><path fill="#FF9900" d="M8,2.48L2.612,14h10.777L8,2.48z M8,0L8,0c0.345,0,0.689,0.233,0.951,0.698l6.828,13.61 C16.303,15.239,15.857,16,14.791,16H1.208c-1.067,0-1.512-0.761-0.989-1.691l6.829-13.61C7.31,0.233,7.654,0,8,0L8,0z M7,12 c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S7,12.552,7,12z M8,6c0.552,0,1,0.448,1,1l-0.312,3H7.312L6.999,7 C6.999,6.448,7.447,6,8,6L8,6z"/></svg>') 4px center no-repeat;
}
.jsonForm .fileuploader .cropDisplay .imageInput,
.jsonForm .fileuploader .cropDisplay .imageOutput {
    display:inline-block;
    vertical-align:top;
    width:50%;
    width: calc(50% - 6px);
    margin:4px 0px 4px 4px;
    /*border:4px solid rgb(240, 240, 240);*/
    min-height:60px;
    /*-webkit-box-shadow: 0 0 0 1px #ccc inset;
    box-shadow: 0 0 0 1px #ccc inset;*/
    background-image:
            -moz-linear-gradient(45deg, #ddd 25%, transparent 25%),
            -moz-linear-gradient(-45deg, #ddd 25%, transparent 25%),
            -moz-linear-gradient(45deg, transparent 75%, #ddd 75%),
            -moz-linear-gradient(-45deg, transparent 75%, #ddd 75%);
    background-image:
            -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, #ddd), color-stop(.25, transparent)),
            -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, #ddd), color-stop(.25, transparent)),
            -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.75, transparent), color-stop(.75, #ddd)),
            -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.75, transparent), color-stop(.75, #ddd));

    -moz-background-size:20px 20px;
    background-size:20px 20px;
    -webkit-background-size:20px 20.03px; /* override value for shitty webkit */

    background-position:0 0, 10px 0, 10px -10px, 0px 10px;
}
.jsonForm .fileuploader .progressBar {
    /*margin: 0 2px 2px 2px;*/
    box-shadow: 0 0 0 1px #ccc inset;
    background-color: #eee;
    border-radius:2px;
    overflow:hidden;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
}
.jsonForm .fileuploader .progressBar b {
    width: 0%;
    display: block;
    background-color: rgb(0, 214, 255);
    height: 6px;
    box-shadow: 0 0 1px 0px rgba(0,0,0,0.6) inset;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
}
.jsonForm .fileuploader .otherButtons {
    position: absolute;
    right: 0px;
    top: 0%;
    margin: 0;
    bottom: 0px;
    font-size:0;
}
.jsonForm .fileuploader .otherButtons button {
    margin: 0;
    height: 100%;
    font-size:14px;
}
.jsonForm .fileuploader .otherButtons button.uploadFileButtonNOTSUREIFWEWANTTHIS:after {
    content: "← click to upload file";
    position: absolute;
    left: 100%;
    padding-left:6px;
    color: red;
}
.jsonForm .fileuploader .otherButtons button.deleteFileButtonNOTSUREIFWEWANTTHIS {
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="4 4 32 32" enable-background="new 4 4 32 32" xml:space="preserve"><polygon fill="#aa0000" points="24.95,12.222 20.001,17.171 15.051,12.222 12.222,15.05 17.172,20 12.222,24.95 15.051,27.777 20.001,22.829 24.95,27.777 27.777,24.95 22.829,20 27.777,15.05 "/></svg>');
    background-position:center center;
    background-repeat:no-repeat;
    width:32px;
    text-indent:-1000px;
    overflow:hidden;
}
/*
.jsonForm .fileuploader .otherButtons button.cancelFileButton {
    border:1px solid #BCBEC0; background:#BCBEC0;
}
.jsonForm .fileuploader .otherButtons button.cancelFileButton:hover {
    border:1px solid #BE1E2D; background:#BE1E2D;
}
*/
.jsonForm .fileuploader .foEl {
    position: absolute;
    top: -5000px;
    left: -5000px;
}
.jsonForm .fileuploader .foEl.error, .jsonForm .fileuploader .foEl.valid {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    width: 1px!important;
    /* height:100%; */
    min-height:100%; /* new jan height fix */
    background: none;
    overflow: hidden;
    border-top: none;
    border-bottom: none;
    border-right: none;
    pointer-events: none;
}
/* for ie9 (not sure wether to bother)
.jsonForm .fileuploader .foEl.error {border-left-color: #BE1E2D;}
.jsonForm .fileuploader .foEl.valid {border-left-color: #009444;}
*/
.jsonForm .fileuploader .otherButtons button.cancelFileButton {
    background-color:#ffffff;
    /*background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 40 40"><path fill="#BCBEC1" d="M26,20 L35.708,10.292 C35.813,10.187 35.888,10.065 35.937,9.935 C36.07,9.579 35.994,9.164 35.708,8.878 L31.122,4.292 C30.836,4.006 30.42,3.931 30.065,4.063 C29.935,4.111 29.813,4.187 29.708,4.291 L20,13.999 L10.292,4.291 C10.187,4.187 10.065,4.111 9.935,4.063 C9.579,3.93 9.164,4.006 8.878,4.292 L4.292,8.878 C4.006,9.164 3.931,9.58 4.063,9.935 C4.112,10.065 4.187,10.187 4.292,10.292 L14,20 L4.292,29.708 C4.188,29.813 4.112,29.935 4.063,30.065 C3.93,30.42 4.006,30.836 4.292,31.122 L8.878,35.708 C9.164,35.994 9.58,36.069 9.935,35.937 C10.065,35.888 10.187,35.813 10.292,35.708 L20,26 L29.708,35.708 C29.813,35.813 29.935,35.888 30.065,35.937 C30.421,36.07 30.836,35.994 31.122,35.708 L35.708,31.122 C35.994,30.836 36.07,30.42 35.937,30.065 C35.888,29.935 35.813,29.813 35.708,29.708 C35.708,29.708 26,20 26,20 Z"></path></svg>');*/
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/cancelFile.svg');
    background-position:center center;
    background-repeat:no-repeat;
    width: 30px;
    text-indent: -1000px;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid #eee;
}
.jsonForm .fileuploader .otherButtons button.cancelFileButton:hover,
.jsonForm .fileuploader .otherButtons button.cancelFileButton.hover {
    cursor:pointer;
    /*background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 40 40"><path fill="#1C75BC" d="M26,20 L35.708,10.292 C35.813,10.187 35.888,10.065 35.937,9.935 C36.07,9.579 35.994,9.164 35.708,8.878 L31.122,4.292 C30.836,4.006 30.42,3.931 30.065,4.063 C29.935,4.111 29.813,4.187 29.708,4.291 L20,13.999 L10.292,4.291 C10.187,4.187 10.065,4.111 9.935,4.063 C9.579,3.93 9.164,4.006 8.878,4.292 L4.292,8.878 C4.006,9.164 3.931,9.58 4.063,9.935 C4.112,10.065 4.187,10.187 4.292,10.292 L14,20 L4.292,29.708 C4.188,29.813 4.112,29.935 4.063,30.065 C3.93,30.42 4.006,30.836 4.292,31.122 L8.878,35.708 C9.164,35.994 9.58,36.069 9.935,35.937 C10.065,35.888 10.187,35.813 10.292,35.708 L20,26 L29.708,35.708 C29.813,35.813 29.935,35.888 30.065,35.937 C30.421,36.07 30.836,35.994 31.122,35.708 L35.708,31.122 C35.994,30.836 36.07,30.42 35.937,30.065 C35.888,29.935 35.813,29.813 35.708,29.708 C35.708,29.708 26,20 26,20 Z"></path></svg>');*/
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/cancelFile.hover.svg');
}
.jsonForm .fileuploader .otherButtons button.cancelFileButton:hover:after,
.jsonForm .fileuploader .otherButtons button.cancelFileButton.hover:after {
    content: "Cancel";
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 0%;
    background: #eee;
    border: 1px solid #ccc;
    padding: 3px 5px;
    color: #333;
    font-size: 12px;
    text-indent: 0;
    height: auto;
    line-height: 1em;
    margin: 0 0 6px 12px;
    opacity: 0.9;
    z-index: 88;
}
.jsonForm .fileuploader .otherButtons button.cancelFileButton:hover:before,
.jsonForm .fileuploader .otherButtons button.cancelFileButton.hover:before {
    content: " ";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0%;
    /* background: #eee; */
    border: 4px solid transparent;
    border-top-color: #ccc;
    padding: 0;
    height: 0px;
    width: 0px;
    overflow:hidden;
    margin: 0 0 -2px 12px;
    opacity: 0.9;
    z-index: 88;
}
/* */
.jsonForm .fileuploader .otherButtons button.replaceFileButton {
    background-color:#ffffff;
    /*background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 40 40"><path fill="#BCBEC1" d="M11.032 28.448c-4.524-4.866-4.444-12.49 0.254-17.222 1.924-1.936 4.328-3.092 6.828-3.472l-0.138-4.154c-3.508 0.426-6.904 1.992-9.594 4.7-6.298 6.344-6.372 16.58-0.246 23.064l-3.482 3.506 11.020 0.602-0.030-11.668-4.612 4.644zM24.324 4.53l0.030 11.668 4.614-4.646c4.524 4.866 4.446 12.49-0.254 17.222-1.924 1.938-4.328 3.092-6.83 3.472l0.138 4.152c3.51-0.426 6.906-1.992 9.596-4.702 6.296-6.342 6.374-16.58 0.244-23.062l3.482-3.506-11.020-0.598z"></path></svg>');*/
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/replaceFile.svg');
    background-position:center center;
    background-repeat:no-repeat;
    width: 30px;
    text-indent: -1000px;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid #eee;
}
.jsonForm .fileuploader .otherButtons button.replaceFileButton:hover,
.jsonForm .fileuploader .otherButtons button.replaceFileButton.hover {
    cursor:pointer;
    /*background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 40 40"><path fill="#1C75BC" d="M11.032 28.448c-4.524-4.866-4.444-12.49 0.254-17.222 1.924-1.936 4.328-3.092 6.828-3.472l-0.138-4.154c-3.508 0.426-6.904 1.992-9.594 4.7-6.298 6.344-6.372 16.58-0.246 23.064l-3.482 3.506 11.020 0.602-0.030-11.668-4.612 4.644zM24.324 4.53l0.030 11.668 4.614-4.646c4.524 4.866 4.446 12.49-0.254 17.222-1.924 1.938-4.328 3.092-6.83 3.472l0.138 4.152c3.51-0.426 6.906-1.992 9.596-4.702 6.296-6.342 6.374-16.58 0.244-23.062l3.482-3.506-11.020-0.598z"></path></svg>');*/
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/replaceFile.hover.svg');
}
.jsonForm .fileuploader .otherButtons button.replaceFileButton:hover:after,
.jsonForm .fileuploader .otherButtons button.replaceFileButton.hover:after {
    content: "Replace File";
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 0%;
    background: #eee;
    border: 1px solid #ccc;
    padding: 3px 5px;
    color: #333;
    font-size: 12px;
    text-indent: 0;
    height: auto;
    line-height: 1em;
    margin: 0 0 6px 12px;
    opacity: 0.9;
    z-index: 88;
}
.jsonForm .fileuploader .otherButtons button.replaceFileButton:hover:before,
.jsonForm .fileuploader .otherButtons button.replaceFileButton.hover:before {
    content: " ";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0%;
    /* background: #eee; */
    border: 4px solid transparent;
    border-top-color: #ccc;
    padding: 0;
    height: 0px;
    width: 0px;
    overflow:hidden;
    margin: 0 0 -2px 12px;
    opacity: 0.9;
    z-index: 88;
}

.jsonForm .fileuploader .otherButtons button.deleteFileButton {
    background-color:#ffffff;
    /*background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 40 40"><path fill="#BCBEC1" d="M6.778 15.824l2.204 19.418c0.118 0.922 4.57 3.954 11.018 3.958 6.448-0.004 10.902-3.036 11.020-3.958l2.204-19.418c-3.368 1.884-8.402 2.776-13.224 2.776s-9.856-0.892-13.222-2.776zM26.334 3.824l-1.718-1.906c-0.662-0.942-1.382-1.118-2.784-1.118h-3.664c-1.4 0-2.12 0.176-2.784 1.118l-1.716 1.906c-5.14 0.898-8.868 3.28-8.868 5.036v0.342c0 3.092 6.804 5.6 15.2 5.6s15.2-2.508 15.2-5.6v-0.342c0-1.756-3.726-4.138-8.866-5.036zM24.138 8.278l-2.504-2.678h-3.268l-2.504 2.678h-3.4c0 0 3.724-4.44 4.222-5.042 0.38-0.46 0.77-0.636 1.274-0.636h4.086c0.504 0 0.892 0.176 1.274 0.636 0.496 0.602 4.222 5.042 4.222 5.042h-3.402z"></path></svg>');*/
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/deleteFile.svg');
    background-position:center center;
    background-repeat:no-repeat;
    width: 30px;
    text-indent: -1000px;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid #eee;
}
.jsonForm .fileuploader .otherButtons button.deleteFileButton:hover,
.jsonForm .fileuploader .otherButtons button.deleteFileButton.hover {
    cursor:pointer;
    /*background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 40 40"><path fill="#1C75BC" d="M6.778 15.824l2.204 19.418c0.118 0.922 4.57 3.954 11.018 3.958 6.448-0.004 10.902-3.036 11.020-3.958l2.204-19.418c-3.368 1.884-8.402 2.776-13.224 2.776s-9.856-0.892-13.222-2.776zM26.334 3.824l-1.718-1.906c-0.662-0.942-1.382-1.118-2.784-1.118h-3.664c-1.4 0-2.12 0.176-2.784 1.118l-1.716 1.906c-5.14 0.898-8.868 3.28-8.868 5.036v0.342c0 3.092 6.804 5.6 15.2 5.6s15.2-2.508 15.2-5.6v-0.342c0-1.756-3.726-4.138-8.866-5.036zM24.138 8.278l-2.504-2.678h-3.268l-2.504 2.678h-3.4c0 0 3.724-4.44 4.222-5.042 0.38-0.46 0.77-0.636 1.274-0.636h4.086c0.504 0 0.892 0.176 1.274 0.636 0.496 0.602 4.222 5.042 4.222 5.042h-3.402z"></path></svg>');*/
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/deleteFile.hover.svg');
}
.jsonForm .fileuploader .otherButtons button.deleteFileButton:hover:after,
.jsonForm .fileuploader .otherButtons button.deleteFileButton.hover:after {
    content: "Delete File";
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 100%;
    background: #eee;
    border: 1px solid #ccc;
    padding: 3px 5px;
    color: #333;
    font-size: 12px;
    text-indent: 0;
    height: auto;
    line-height: 1em;
    margin: 0 0 6px -18px;
    opacity: 0.9;
    z-index: 88;
}
.jsonForm .fileuploader .otherButtons button.deleteFileButton:hover:before,
.jsonForm .fileuploader .otherButtons button.deleteFileButton.hover:before {
    content: " ";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 100%;
    /* background: #eee; */
    border: 4px solid transparent;
    border-top-color: #ccc;
    padding: 0;
    height: 0px;
    width: 0px;
    overflow:hidden;
    margin: 0 0 -2px -18px;
    opacity: 0.9;
    z-index: 88;
}

::-webkit-file-upload-button {
    cursor:pointer;
}


/* child / linked forms */
.linkedFormWrapper {
    margin:1px 0;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
}
.linkedFormAddNew {
    /* margin-top:20px; */
}
.linkedFormWrapper .jsonForm {
    width: 100%;
    /* display: table; */
    /* overflow: auto; */
}
.linkedFormWrapper .jsonForm fieldset {
    margin: 0px;
    padding: 0px;
    border:0;
    display: table;
    width: 100%;
    border:0;
}
.linkedFormWrapper .jsonForm .jf-row {
    /*width: 40%;
    display: inline-block;*/
    display:table-cell;
    padding:4px;
    width: auto;
    vertical-align:bottom;
    /* background: none; */
}
.linkedFormWrapper .jsonForm .jf-row:first-of-type {padding-left:0;}
.linkedFormWrapper .jsonForm .jf-row:last-of-type {padding-right:0;}
.linkedFormWrapper .jsonForm .jf-input-type-submit {
    white-space: nowrap;
}
.linkedFormWrapper .jsonForm input[type="submit"] {
    margin:0;
    padding: 0 10px;
    float:none;
}
.linkedFormWrapper .jsonForm input[type="submit"] + input[type="submit"] {
    margin-left:8px;
}
.linkedFormWrapper .jsonForm input[type="submit"].flat {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    filter: none;
    /*background: #ccc;*/
    background:#1C75BC;
    pointer-events:none;
}
.linkedFormWrapper .jsonForm input[value="Add New"],
.linkedFormWrapper .jsonForm input[value="Save +"],
.linkedFormWrapper .jsonForm input[value="Add +"] {
    min-width: 5.6em;
}
.linkedFormWrapper .jsonForm .jf-row > label {
    /*position:absolute;
    margin-top:-2em;*/
    display:block;
    width:inherit;
    padding: 0;
    max-width: inherit;
}
.linkedFormWrapper + .linkedFormWrapper .jsonForm .jf-row > label {
    display: block;
    height: 0px;
    overflow: hidden;
    margin: 0 0 -1em 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
    /* font-size: 0; */
    line-height: 0;
    pointer-events: none;
}
.linkedFormWrapper .jsonForm .jf-row label + input,
.linkedFormWrapper .jsonForm .jf-row label + textarea,
.linkedFormWrapper .jsonForm .jf-row label + select,
.linkedFormWrapper .jsonForm .jf-row label + div.cke,
.linkedFormWrapper .jsonForm .chosen-container {
    max-width: 100%;
    width: 100%;
}

.linkedFormWrapper .jsonForm .jf-row label + textarea {
    height:32px;
}

.linkedFormWrapper .jsonForm .jf-row label + .fileuploader {
    min-width:185px;
}
.linkedFormWrapper .chosen-single {
    /* unify the width of chosen dropdowns within a linked form */
    width: 150px;
}

/* lockton specific presentation of specific subforms */
.linkedFormWrapper .jsonForm #mergers .jf-row:first-of-type {
    width: 50%;
}
.linkedFormWrapper .jsonForm #offices .jf-row:first-of-type {
    width: 30%;
}
.linkedFormWrapper .jsonForm #offices .jf-row input[name=addressPostcode] {
    width: 5em;
}

/* lockton specific presentation of report upload fields */
.linkedFormWrapper .jsonForm #regulatoryIssues,
.linkedFormWrapper .jsonForm #regulatoryIssuesMonitoringVisit,
.linkedFormWrapper .jsonForm #regulatoryIssuesSraEngagement {
    width: 100%;
}
.linkedFormWrapper .jsonForm #regulatoryIssues .jf-row,
.linkedFormWrapper .jsonForm #regulatoryIssuesMonitoringVisit .jf-row,
.linkedFormWrapper .jsonForm #regulatoryIssuesSraEngagement .jf-row {
    width: inherit;
}
.linkedFormWrapper .jsonForm #regulatoryIssues .jf-row:first-of-type,
.linkedFormWrapper .jsonForm #regulatoryIssuesMonitoringVisit .jf-row:first-of-type,
.linkedFormWrapper .jsonForm #regulatoryIssuesSraEngagement .jf-row:first-of-type {
    width:4em;
}
.linkedFormWrapper .jsonForm #regulatoryIssues .jf-row.jf-input-type-file,
.linkedFormWrapper .jsonForm #regulatoryIssuesMonitoringVisit .jf-row.jf-input-type-file,
.linkedFormWrapper .jsonForm #regulatoryIssuesSraEngagement .jf-row.jf-input-type-file {
    max-width:200px;
}


/* v2 220916 display each repeated repeatable region in a row */
fieldset[data-layout-style=rows] .jf-row label {
    vertical-align:bottom;
    min-width:99%;
    max-width:1px; /* 030117 hack to stop the labels from making the columns misalign */
}
fieldset[data-layout-style=rows] .jf-row label dfn:after {
    position:absolute;
}
fieldset[data-layout-style=rows] + fieldset[data-layout-style=rows] .jf-row > label {
    display: none;
}
fieldset[data-layout-style=rows] .jf-row {
    display: table-cell;
    width: 10%;
    vertical-align: bottom;
    padding:0 0 2px 0;
}
fieldset[data-layout-style=rows] .jf-row + .jf-row  {
    padding-left:2px;
}
fieldset[data-layout-style=rows] .jf-input-type-checkbox {
    width: 0.1%;
}
fieldset[data-layout-style=rows] .rowbuttonwrapper.jf-row {
    width: 0.1%;
    min-width: 81px!important;
    max-width: 81px!important;
}
fieldset[data-layout-style=rows] .rowbuttonwrapper span.button {
    position: absolute;
    bottom: 2px;
    right: -1px;
}
.rowbuttonwrapper.jf-row {
    text-align: right;
    margin:0;
}
/* 020217
fieldset[data-layout-style=rows] ~ .rowbuttonwrapper.jf-row {
    position:absolute;
    width: 100px;
    right: -1px;
}*/
fieldset[data-layout-style=rows] ~ .rowbuttonwrapper.jf-row {
    /* position: absolute; */
    width: 100px;
    /* right: -1px; */
    display: inline-block;
    margin-left: 0;
    text-align: left;
}
.rowbuttonwrapper span.button.addrow {
    background: #27AAE1;
}
.rowbuttonwrapper span.button {
    background: #b5b5b5;
    line-height: 32px;
    font-size: 16px;
    padding: 0;
    position: relative;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    font-family: "Roboto Condensed",sans-serif;
    width: 81px;
    text-align: center;
    cursor:pointer;
    display:inline-block;
}
fieldset[data-layout-style=rows] .jf-row > .collection {
    width: 100%;
    min-width: 85px;
    vertical-align: bottom!important;
    margin: 0;
}
fieldset[data-layout-style=rows] .jf-row .chosen-container {
    vertical-align: bottom;
    min-width:100%;
}
[data-repeatableregionident] .jf-input-type-select:after {
    width: 0;
    height: 0;
    content: "";
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #999999;
    margin-top: -3px;
    position:absolute;
    bottom:15px;
    right:10px;
    pointer-events:none;
}



fieldset[data-layout-style=rows] {
    margin:1px 0;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
}
fieldset[data-layout-style=rows] .jf-row label + textarea {
    height:32px;
}

fieldset[data-layout-style=rows] .jf-row label + .fileuploader {
    min-width:185px;
}
fieldset[data-layout-style=rows] .chosen-single {
    /* unify the width of chosen dropdowns within a linked form */
    width: 150px;
}
/* limit the width of input type number */
fieldset[data-layout-style=rows] .jf-input-type-number {
    width: 6%;
}
fieldset[data-layout-style=rows] .jf-input-type-number > label {
    width: 60px;
}


/* lockton specific presentation of specific subforms */
div[data-repeatableregionident="locktonPf_form_mergers"] fieldset[data-layout-style=rows] .jf-row:first-of-type {
    width: 50%;
}
div[data-repeatableregionident="locktonPf_form_office"] fieldset[data-layout-style=rows] .jf-row:first-of-type {
    width: 30%;
}
div[data-repeatableregionident="locktonPf_form_office"] fieldset[data-layout-style=rows] .jf-row input[name=addressPostcode] {
    width: 5em;
}
div[data-repeatableregionident="locktonPf_form_regulatoryIssues"] fieldset[data-layout-style=rows] .jf-input-type-textarea {
    width: 90%;
}
div[data-repeatableregionident="locktonPf_form_foreignLawAdvice"] fieldset[data-layout-style=rows] .jf-input-type-textarea {
    width: 30%;
}
div[data-repeatableregionident="locktonPf_form_claims"] fieldset[data-layout-style=rows] .jf-input-type-text {
    width: 70%;
}
div[data-repeatableregionident="locktonPf_form_regulatoryIssues"] fieldset[data-layout-style=rows],
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesMonitoringVisit"] fieldset[data-layout-style=rows],
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesSraEngagement"] fieldset[data-layout-style=rows] {
    width: 100%;
}
div[data-repeatableregionident="locktonPf_form_regulatoryIssues"] fieldset[data-layout-style=rows] .jf-row,
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesMonitoringVisit"] fieldset[data-layout-style=rows] .jf-row,
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesSraEngagement"] fieldset[data-layout-style=rows] .jf-row {
    width: inherit;
}
div[data-repeatableregionident="locktonPf_form_regulatoryIssues"] fieldset[data-layout-style=rows] .jf-row:first-of-type,
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesMonitoringVisit"] fieldset[data-layout-style=rows] .jf-row:first-of-type,
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesSraEngagement"] fieldset[data-layout-style=rows] .jf-row:first-of-type {
    width:4em;
    min-width: 80px;
}
div[data-repeatableregionident="locktonPf_form_regulatoryIssues"] fieldset[data-layout-style=rows] .jf-row.jf-input-type-file,
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesMonitoringVisit"] fieldset[data-layout-style=rows] .jf-row.jf-input-type-file,
div[data-repeatableregionident="locktonPf_form_regulatoryIssuesSraEngagement"] fieldset[data-layout-style=rows] .jf-row.jf-input-type-file {
    max-width:200px;
}








/* in columns */
.layout-style-columns  {
    display:table;
    padding-left: 130px!important;
}
.layout-style-columns .linkedFormWrapper + .linkedFormAddNew {
    padding-left: 20px;
}
.layout-style-columns .linkedFormWrapper {
    display:table-cell;
}
.layout-style-columns .linkedFormWrapper .jsonForm .jf-row {
    display:block;
    padding:4px;
    width:auto;
    vertical-align:bottom;
    text-align:right;
    margin:0px 2px 0px 0px;
}
.layout-style-columns .linkedFormWrapper .jsonForm .jf-row {

}
.layout-style-columns .linkedFormWrapper + .linkedFormWrapper .jsonForm .jf-row {
    padding-left:4px; min-width:inherit;
}
.layout-style-columns .linkedFormWrapper .jsonForm .jf-row > label {
    position: absolute;
    margin: 0;
    width: 125px;
    top: 0;
    bottom: 1px;
    white-space: nowrap;
    right: 100%;
    text-align: left;
    border-radius: 2px;
    background: rgba(0,0,0,0.05);
}
.layout-style-columns .linkedFormWrapper + .linkedFormWrapper .jsonForm .jf-row > label {
    display:none;
}

.jsonForm table {
    font-size:14px;
    width: 100%;
    border-spacing: 0;
}
.jsonForm td, .jsonForm th {

}
.jsonForm th {
    padding: 2px;
    font-weight: normal;
}
.jsonForm table tr th:first-child {
    text-align:left;
}
.jsonForm table td {
    text-align: right;
    position:relative;
}
.jsonForm table td input {
    border: 1px solid #939598;
    box-shadow: none;
    background-color: #fff;
    height: 100%;
    border-radius: 0;
    width: 100%;

    min-height: 27px;
    line-height:27px;
}
.jsonForm td input[type=number],
.jsonForm td input[type=cash],
.jsonForm td input[type=percent] {
    text-align: right;
}
/*
.jsonForm td input[data-aofb-as-percent],
.jsonForm td input[data-aofb-as-cash]{
    color: #aaa;
}
.jsonForm td input[data-aofb-as-percent].fixed,
.jsonForm td input[data-aofb-as-cash].fixed {
    color:#333;
}
*/
.jsonForm th[data-collapsed='true'] {
    cursor:e-resize;
}
.jsonForm th[data-collapsed='false'] {
    cursor:w-resize;
}
.jsonForm th > span {
    display:inline-block;
    text-overflow:ellipsis;
}
.jsonForm th[data-collapsed='true'] > span {
    overflow:hidden;
}
.jsonForm tr:first-child th, .jsonForm td, .jsonForm th > span {
    max-width: 8em;
    -webkit-transition: max-width .5s;
    -moz-transition: max-width .5s;
    -ms-transition: max-width .5s;
    -o-transition: max-width .5s;
    transition: max-width .5s;
}
.jsonForm th[data-collapsed='true']{
    max-width: 1.25em;
    overflow:hidden;
}
.jsonForm th[data-collapsed='true'] > span {
    max-width: 1.25em;
    white-space:nowrap;
}
.jsonForm td.collapsed {
    max-width: 1.25em;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.jsonForm td.collapsed input {
    width: 100%;
}

.jsonForm th[data-show-linkage~="lastCompletedYear"],
.jsonForm td #lastCompletedYear {
    /* top left */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='6px' height='6px' viewBox='0 0 6 6' overflow='inherit' xml:space='preserve'><polygon fill='#83C5D8' points='0,0 6,0 0,6 ' /></svg>");
    background-position:0 0;
    background-repeat:no-repeat;
}

.jsonForm tr th:first-of-type {padding-left:5px;}
.jsonForm tr[data-is-total-row="true"] th {color: #32809D;/* font-weight: normal; *//* text-align: right!important; */font-size: .8em;}
.jsonForm tr[data-is-total-row="true"] th,
.jsonForm tr[data-is-total-row="true"] td {
    /*border-top: 3px double #eee;*/
    background-color: #EFF9FC;
}
.jsonForm th a {
    /*color: #ABABAB;*/
    color:#888888;
    font-size: 0.8em;
    text-decoration: none;
}
.jsonForm th a:hover {
    color: #32809D;
}


.doughnutChartKey {font-size:0.8em; color:#666; text-align: center;}
.doughnutChartKey span {
    color: #6D6E71;
    font-size: 2em;
    line-height: 0.5em;
    vertical-align: text-top;
}



/* forwardQuestionButton */
.jsonForm .forwardQuestionButton {
    display: inline-block;
    background-color: #eee;
    padding: 3px 6px;
    border-radius: 2px;
    color: #666;
    cursor: pointer;
    position: absolute;
    top: -3px;
    left: 3.5em;
    /*background:transparent url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 57.5 57.5" enable-background="new 0 0 57.5 57.5" xml:space="preserve"><path fill="#aaaaaa" d="M43.125,45.425H5.751V19.551h7.46c0,0,1.98-2.576,6.239-5.75H2.876c-1.587,0-2.875,1.288-2.875,2.875V48.3 c0,1.59,1.288,2.875,2.875,2.875H46c1.59,0,2.875-1.285,2.875-2.875v-10.77l-5.75,4.729L43.125,45.425L43.125,45.425z M38.416,25.444V35.65L57.5,20.698L38.416,6.326v8.999c-23.178,0.003-23.178,22.914-23.178,22.914 C21.799,27.462,25.835,25.444,38.416,25.444L38.416,25.444z"/></svg>') 3px 0 no-repeat;*/
    background:transparent url('/proposalform/v2016v2/jsonForm.2.0.0/plugins/ForwardQuestion/forwardQuestion.svg') 3px 0 no-repeat;
    text-indent: 26px;
    max-width: 25px;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 10px;
    box-sizing: border-box;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.forwardQuestionIconOnly {
    display: inline-block;
    vertical-align: middle;
    background-color: #eee;
    padding: 3px 6px;
    border-radius: 2px;
    color: #666;
    /*background:transparent url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 57.5 57.5" enable-background="new 0 0 57.5 57.5" xml:space="preserve"><path fill="#aaaaaa" d="M43.125,45.425H5.751V19.551h7.46c0,0,1.98-2.576,6.239-5.75H2.876c-1.587,0-2.875,1.288-2.875,2.875V48.3 c0,1.59,1.288,2.875,2.875,2.875H46c1.59,0,2.875-1.285,2.875-2.875v-10.77l-5.75,4.729L43.125,45.425L43.125,45.425z M38.416,25.444V35.65L57.5,20.698L38.416,6.326v8.999c-23.178,0.003-23.178,22.914-23.178,22.914 C21.799,27.462,25.835,25.444,38.416,25.444L38.416,25.444z"/></svg>') 3px 0 no-repeat;*/
    background:transparent url('/proposalform/v2016v2/jsonForm.2.0.0/plugins/ForwardQuestion/forwardQuestion.svg') 3px 0 no-repeat;
    text-indent: 26px;
    max-width: 25px;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
}
.jsonForm .forwardQuestionButton:hover {
    background-color:#eee;
    max-width:600px;
}
form.forwardQuestion {
    text-align: left;
    margin: 0;
    padding: 0;
}
form.forwardQuestion p {
    font-size: 1.2em;
    margin: 0 0 10px 0;
}
form.forwardQuestion p small {
    margin: 1em 0;
    display: block;
    font-size: 0.8em;
    color: #666;
}
form.forwardQuestion p.fcfoot, form.forwardQuestion p.fcfoot small {margin-bottom:0;}
form.forwardQuestion fieldset {border:0; padding:0; margin:0;}
form.forwardQuestion fieldset label {display:block;}
form.forwardQuestion fieldset label i {font-style: normal;}
form.forwardQuestion fieldset input, form.forwardQuestion fieldset textarea {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 4px;
    margin: 1px 0;
    width:100%;
}
form.forwardQuestion fieldset input[type=checkbox]{
    width: inherit;
    font-size: 1.2em;
    margin-right: 10px;
}
form.forwardQuestion fieldset input:focus, form.forwardQuestion fieldset textarea:focus {
    outline:none;
}
form.forwardQuestion fieldset label.error {
    background-color: #BE1E2D;
    color: #fff;
}
form.forwardQuestion fieldset .error input, form.forwardQuestion fieldset.error textarea {
    box-shadow: 0 0 6px #BE1E2D;
    border: 1px solid #BE1E2D;
    border-color: #BE1E2D;
}
form.forwardQuestion fieldset textarea {height: 10.5em;}
form.forwardQuestion fieldset .emailto {position:relative;}
form.forwardQuestion fieldset .emailto span {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #ccc;
    width: 3em;
    margin: 1px;
    padding: 4px;
    line-height: 1.5em;
}
form.forwardQuestion fieldset .error span {
    background-color: #BE1E2D;
    color:#fff;
}
form.forwardQuestion fieldset .emailto input {
    text-indent: 4em;
}


#restore-notification {
    position:fixed;
    z-index:9999;
    top:0; left:0; right:0; bottom:0;
    padding:20px;
    background:rgba(0,0,0,.6);
    font-weight:bold;
}




/*020217
.csvio {
    display: inline-block;
    margin: 5px 0;
    background-color: #eee;
    padding: 5px;
}*/
.csvio {
    display: inline-block;
    margin: 5px 0;
    background-color: #eee;
    padding: 5px;
    position: absolute;
    right: -1px;
    margin-top: calc(-10px - 1.5em)!important;
}
.csvio {
    margin: 2px 0;
    padding: 6px;
}
.csvio a {margin:10px 0;}
.csvio a + a {margin:10px;}
.csvio iframe {
    height:1px; width:1px;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
}
.csvio form {display:block; position:relative; margin-top:8px;}
.csvio form input[type=submit] {
    float:none!important;
    margin:0;
}
.csvio form input[type=file] {
    line-height: 10px;
    vertical-align: bottom;
    width: 260px;
    margin: 0;
    padding: 6px 0 0 0px;
    height: 32px;
}
.csvio form .choosefile{
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    z-index: 9;
    background: #1C75BC;
    color: white;
    width: 80px;
    pointer-events: none;
    line-height: 30px;
    /* height: 30px; */
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    margin: 0;
}








/* ------------------- NEW STUFF ---------------- */



/* NEW flexy header thing
 * only enable this on supporting browsers???

#jf_jf1 {
    padding-top: 200px;
}
.jf-header-section.fixed + section {
    margin-top: 0px;
}
#mainheader {height:150px; overflow:hidden;}
#mainheader {position:fixed; z-index:99999; background:#ffffff;}
.jf-header-section {position:fixed; z-index:99995; background:#ffffff; border-top: 1px solid #dddddd;}
#mainheader img {
    width: auto;
}
#mainheader.shallow {}
#mainheader * {
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
}
#mainheader form,
#mainheader.maximised * {
    -webkit-transition: none 0s;
    -moz-transition: none 0s;
    -ms-transition: none 0s;
    -o-transition: none 0s;
    transition: none 0s;
}
#mainheader .logo, #mainheader .acc-contact-details-wrapper img {
    height: 73px;
    width: auto;
}
#mainheader.shallow .logo, #mainheader.shallow .acc-contact-details-wrapper img {
    height: 30px;
}
#mainheader > .bounds {
    text-align: left;
    display: table;
    padding:0;
    height:100%;
}
#mainheader .logo {
    display: table-cell;
    float: none;
    position: relative;
    width: 20%;
    text-align: left;
    vertical-align:middle;
    padding-left:12px;
}
#mainheader #headerbuttons {
    display: table-cell;
    width: 60%;
    text-align: center;
    vertical-align: middle;
}
#mainheader .acc-contact-details-wrapper {
    display: table-cell;
    width: 300px;
    min-width: 220px;
    text-align: left;
    vertical-align: middle;
    padding-top: 6px;
    overflow: hidden;
    white-space: nowrap;
}
#mainheader.shallow .acc-contact-details .contact-select {
    padding-left: 37px;
}
#mainheader .acc-contact-details .contact-select .chosen-container,
#mainheader .acc-contact-details .phone {
    max-height:2em;
    opacity:1;
    overflow:hidden;
}
#mainheader.shallow .acc-contact-details .contact-select .chosen-container,
#mainheader.shallow .acc-contact-details .phone {
    max-height:0;
    opacity:0;
}
#mainheader.shallow .acc-contact-details .text-wrap {
    padding-top: 0px;
}
#mainheader #headerbuttons .headerbuttons-item, #mainheader .icon-exit, .icon-logout {display:inline-block; float:none; position:relative; right:inherit; top:inherit;}
#mainheader .do-maximise-header {
    opacity: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top:0px;
    right: 10px;
    padding: 0px;
    font-size: 0px;
    height: 0px;
    color:#666;
    text-indent:-100px;
    overflow:hidden;
    width:30px;
    background:#eeeeee url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 46" enable-background="new 0 0 23 16" xml:space="preserve"><path d="M26,7 L26,20 L21,15 L15,21 L12,18 L18,12 L13,7 L26,7 Z M8,28 L2,34 L7,39 L-6,39 L-6,26 L-1,31 L5,25 L8,28 Z" fill="#aaaaaa" transform="translate(10.000000, 23.000000) rotate(-45.000000) translate(-10.000000, -23.000000) "></path></svg>') center center no-repeat;
    background-color:#ffffff;
    border:1px solid #eee;
    border-width:1px 0px;
}
#mainheader.shallow .do-maximise-header {
    height:30px;
    margin-top:-16px;
    opacity:1;
}
#mainheader.shallow .do-maximise-header:hover {
    background-color:#eee;
}
@media only screen and (max-width: 487px) {
    #mainheader {height:250px;}
    #mainheader > .bounds {
        display: block;
    }
    #mainheader .logo {
        display: block;
        width: 100%;
        text-align:center;
    }
    #mainheader #headerbuttons {
        display: block;
        width: 100%;
    }
    #mainheader .acc-contact-details-wrapper {
        display: block;
        width: 100%;
        text-align:center;
    }
}
 */
#jf_jf1 {
    padding-top: 203px;
}
.readonlyMode form#jf_jf1 {
    padding-top: 227px;
}
.readonlyMode form#jf_jf1 input, .readonlyMode form#jf_jf1 label {
    pointer-events:none;
}
.jf-header-section.fixed + section {
    margin-top: 0px;
}
#mainheader {height:150px; overflow:hidden;}
#mainheader {position:fixed; z-index:99999; z-index:9999994; background:#ffffff;}
.jf-header-section {position:fixed; z-index:9999995; background:#ffffff; border-top: 1px solid #dddddd;}

/*
#declarationPage .jf-header-section {
    position:relative!important;
}
*/
#jf_jfdec {
    padding-top: 203px;
}
/*.lsos #jf_jfdec {*/
    /*padding-top: 50px;*/
/*}*/
body.readonlyMode #jf_jfdec {
    padding-top: 0px;
}
#jf_jfdec section h2:before {
    display:none;
}

form#jf_jf1.inIframe {
    padding-top: 52px;
}
form#jf_jf1.inIframe .jf-header-section, form#jf_jf1.inIframe .jf-header-section[style]{
    top:0!important;
}




#mainheader {padding-top:50px;}
#mainheader > .bounds {
    text-align: right;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
}
#mainheader .logo {
    font-size: 0;
    display: inline-block;
    position: relative;
    float: left;
    /*z-index: 99999550;*/
    padding-top: 7px;
}
#headband {
    position:fixed;
    top:0;
    left:0; right:0;
    height: 50px;
    width:100%;
    border-bottom: 1px solid #ddd;
    display:table;
    background:#ffffff;
    z-index:99999550;
    box-sizing:border-box;
    text-align:right;
    -webkit-transition: border-color 0.5s;
    -moz-transition: border-color 0.5s;
    -ms-transition: border-color 0.5s;
    -o-transition: border-color 0.5s;
    transition: border-color 0.5s;
}
#mainheader.shallow + #headband {
    border-bottom-color:transparent;
}
#headband .rightgroup {
    height: 50px;
    box-sizing:border-box;
    vertical-align: middle;
    margin: auto;
    background:#ffffff;
    display: inline-table;
}
#headband .lockton {
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    margin: auto;
    height: 24px;
    opacity:0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}
#mainheader.shallow + #headband .lockton {
    opacity:1;
}
#headband .lockton img {
    display:inline-block;
    height: 24px;
}
#mainheader #logomask {display:block; position:fixed; top:0; left:0; width:300px; height:13px; background:#ffffff; z-index:99999555;}
#headband #accManBasic,
#headband #headerbuttons {
    padding-left:10px;
    display: table-cell;
    vertical-align: middle;
    position:relative;
}
#headband #accManBasic {
    padding-right:0px;
    opacity:0; white-space:nowrap; max-width:1px; overflow:hidden;
    -webkit-transition: max-width 1.25s;
    -moz-transition: max-width 1.25s;
    -ms-transition: max-width 1.25s;
    -o-transition: max-width 1.25s;
    transition: max-width 1.25s;
}
#mainheader.shallow + #headband #accManBasic {padding-right:12px; opacity:1; max-width:400px; color: #cccccc;}
#headband #accManBasic:after {content:""; display:block; position:absolute; top:0; bottom:0; right:0; width:1px; height:20px; border-right:1px solid #ddd; margin:auto;}
#headband #accManBasic .acc-contact {
    display:inline-block;
    color: #1C75BC;
    /* text-transform: uppercase; */
}
#headband #accManBasic .acc-contact .phone {
    color: #AFAFAF;
}


#mainheader + #headband #headerbuttons .headerbuttons-item,
.headericon-exit, .headericon-logout {display:inline-block; float:none; position:relative; right:inherit; top:inherit;
    margin-left: 10px;
    text-transform: uppercase;
    /* line-height: 10px; */
    font-size: 12px;
}
.headericon-logout {
    margin-left: 0px;
}
.headericon-user:before {
    font-size: 16px;
    line-height: 16px;
}
#headband #headerbuttons .headerbuttons-item:hover, .headericon-logout:hover,
#headband #headerbuttons .headerbuttons-item.active {color:#009444;}

#mainheader .logo img, #mainheader .acc-contact-details-wrapper img {
    height: 68px;
    width: auto;
}


#mainheader {
    opacity:1;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}
#mainheader.shallow {
    opacity:0;
}

#mainheader + #headband a.do-maximise-header {
    display: inline-block;
    width: 0px;
    height: 0;
    font-size: 0px;
    border: 5px solid transparent;
    border-top-color: #ccc;
    border-top-width: 7px;
    margin: 0px 5px;
    vertical-align: middle;
}

.readonlyModeNotice {position:fixed; top:0; height:25px; z-index:99999550; vertical-align:middle; background:#FEE0E0; white-space:nowrap; overflow:hidden;}
.readonlyModeNotice ~ #mainheader,
.readonlyModeNotice ~ #headband,
.readonlyModeNotice ~ form .jf-header-section {margin-top:25px;}

.readonlyNotice {color:red;}

/* lsos */
.lsoslogo {
    display:none!important;
    margin-left:10px;
}
.lsos .lsoslogo {
    display: inline-block!important;
}
.lsos #headband .lsoslogo {
    margin: -7px 0 -8px 10px;
    height: 40px;
}
/* if switched so lsos then lockton logos
.lsos #headband .lsoslogo {
    margin: -13px 10px 0px 0;
    height: 50px!important;
}
.lsos #headband .locktonlogo {
    margin-bottom: 15px;
    height: 19px!important;
}
.lsos #mainheader .lsoslogo {
    margin: -16px 20px 16px 0px;
    height: 88px!important;
}
.lsos #mainheader .locktonlogo {
    margin: 0px 0px 27px 0px;
    height: 60px!important;
}
 */

@media only screen and (max-width: 955px) {
    .lsos #mainheader.shallow + #headband #accManBasic  .acc-contact {display:none;}
    .lsos #mainheader + #headband a.do-maximise-header {margin-left:-6px;}
}
@media only screen and (max-width: 850px) {
    #jf_jf1 {
        padding-top: 240px;
    }
    #mainheader.shallow + #headband #accManBasic .acc-contact {
        line-height: 1em;
        margin-top: 3px;
    }
    #mainheader.shallow + #headband #accManBasic .acc-contact .phone {
        display: block;
    }
}

/* END flexy header thing  */








/* NEW collapsable linked form */
.linkedFormWrapper .jsonForm .jf-input-type-submit input {
    white-space: nowrap;
    overflow:hidden;
    min-width: 0;
    width: 4.1em;
    padding: 0;
    margin:0!important;
    -webkit-transition: width .25s linear 0s;
    -moz-transition: width .25s linear 0s;
    -ms-transition: width .25s linear 0s;
    -o-transition: width .25s linear 0s;
    transition: width .25s linear 0s;
    box-sizing: content-box;
}
.linkedFormWrapper:not(.linkedFormAddNew) .jsonForm input + input {
    margin-left: -2px!important;
    background-color:#76ACD7;
    border-color:#76ACD7;
}
.linkedFormWrapper:not(.linkedFormAddNew) .jsonForm input + input:hover {
    background-color:#009444;
    border-color:#009444;
}
.linkedFormWrapper:not(.linkedFormAddNew) .jsonForm input.flat {
    width: 0;
    background-color:#76ACD7;
    border-color:#76ACD7;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
}
.linkedFormWrapper:not(.linkedFormAddNew) .jsonForm input:not(.flat) + input {
    width: 0;
    background-color:#76ACD7;
    border-color:#76ACD7;
    border-left-color:#1C75BC;
    border-right-color:#1C75BC;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
}
.linkedFormAddNew .jsonForm input.flat {
    background-color:#999999!important;
    border-color:#999999!important;
}
/* END collapsable linked form */

/* pad unhighlighted required fields so they are the same width as when they have been validated */
.jsonForm .linkedFormAddNew fieldset *:required {
    padding-left:6px;
}
.jsonForm .linkedFormAddNew fieldset[data-hasanswers=true] *:required {
    padding-left:4px;
}
/* END pad unhighlighted required fields */



/* bits */
.jsonForm dfn {
    white-space: normal;
}
#summaryScreen .bounds {padding:20px 10px;}
/* END bits */



/* close button for ALL panels
.panelCloseButton {
    background-color:transparent;
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/panelCloseButton.svg');
    background-position:center center;
    background-repeat:no-repeat;
    width: 40px;
    height:40px;
    border-radius:50%;
    text-indent: -1000px;
    overflow: hidden;
}
.panelCloseButton:hover {
    cursor:pointer;
    background-image: url('/proposalform/v2016v2/jsonForm.2.0.0/img/panelCloseButton.hover.svg');
}
 */
.panelCloseButton {
    background: #FAFAFA;
    background: rgba(255, 255, 255, 0.75);
    border: 1px solid #ccc;
    border-radius:0;
    text-indent: 0;
    width: auto;
    height: auto;
    padding: 7px 10px;
    color: #888;
    text-transform: uppercase;
    font-family: "Roboto Condensed",sans-serif;
    font-weight: bold;
    line-height: 1em;
    top: 32px!important;
    right: 10px!important;
}
.panelCloseButton:hover {background:#ddd; border-color:#555; color:#333;}
#issues .panelCloseButton {
    top: 0px!important;
    right: 10px!important;
}
/* END close button for ALL panels */



/* help panel */
#helpPanel {
    background-color: #FBFBFB;
    border-top: 1px solid #ddd;
}
#helpPanel .bounds {padding-top:20px;}
#helpPanel .bounds p {margin:2em 0;}
#helpPanel .browser-logos {display:table; margin:1em 0;}
#helpPanel .browser-logos a {display:table-cell;}
#helpPanel .browser-logos a + a {padding-left: 20px;}
#helpPanel .browser-logos a img {width:100%; max-width:96px;}
#helpPanel .icon-help {color:#1C75BC; display:inline-block; vertical-align:middle;}

kbd {
    -moz-border-radius:3px;
    -moz-box-shadow:0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    -webkit-border-radius:3px;
    -webkit-box-shadow:0 1px 0 rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    background-color:#EFEFEF;
    border:1px solid #C7C7C7;
    border-radius:3px;
    box-shadow:0 1px 1px rgba(0,0,0,0.2),0 0 0 2px #fff inset;
    color:#333;
    display:inline-block;
    font-family: 'proxima nova',sans-serif;
    font-size:1.1em;
    line-height:1.4em;
    margin:0 .1em;
    padding:.1em .6em;
    text-shadow:0 1px 0 #fff;
    font-family: monospace;
}



.OLD input ~ span.tt {
    display: none;
    position: absolute;
    z-index: 999;
    top: 100%;
    margin: -5px 5px;
    right: 0;
    padding: 1px 2px;
    background: #E9EDF7;
    border: 1px solid #1C75BC;
    color: #1C75BC;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.6);
}
input ~ span.tt {
    display: none;
    position: absolute;
    z-index: 999;
    bottom: 100%;
    margin: -3px 5px;
    right: 0;
    padding: 2px 4px 1px;
    background: #FFFCD9;
    border: 1px solid #ADADAD;
    color: #1D1D1D;
    border-radius: 3px;
    font-size: 0.85em;
    box-shadow: 0px 5px 14px 0px rgba(0,0,0,0.4);
}
span.tt:after {
    content: "";
    position: absolute;
    top: 100%;
    border: 6px solid transparent;
    left: 50%;
    margin-left: -6px;
    border-top-color: #FFFCD9;
}
td:hover input ~ span.tt,
input:hover ~ span.tt {
    display: block;
}
/* ------------------- END NEW STUFF ---------------- */













@media only screen and (max-width: 1340px) {
    .jsonForm ul.jf-page-control-title {
        display: none;
    }
}

@media only screen and (max-width: 955px) {
    .jsonForm .jf-header-bounds {
        /* padding-right: 190px; */
        padding-bottom: 39px;
    }
    .jsonForm ul.jf-page-control-title {
        display: block;
        bottom: 10px;
        top: inherit;
        right: inherit;
        left: 10px;
        text-align: left;
    }
    button#main_summary,
    button#main_submit_float {
        top:auto;
        bottom:9px;
    }
}

@media only screen and (max-width: 850px) {
    .linkedFormWrapper .jsonForm .jf-row {
        display:block; padding:4px 0; width:100%!important;
    }

    .linkedFormWrapper .jsonForm #offices .jf-row input[name=addressPostcode] {
        width: inherit;
    }

    .linkedFormWrapper + .linkedFormWrapper .jsonForm .jf-row > label {
        height: auto;
        overflow: hidden;
        margin:0;
        opacity:1;
        line-height:normal;
    }

    .linkedFormWrapper .chosen-single {
        width: auto;
    }

    .linkedFormWrapper + .linkedFormWrapper {
        border-top: 3px double #eee;
        padding-top: 10px;
    }

    .jsonForm dfn {
        white-space: normal;
    }
}

@media only screen and (max-width: 680px) {
    .jsonForm ul.jf-page-control-title {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .jsonForm {width:100%;}
    .jsonForm .jf-row {
        padding:3px;
    }
    .jsonForm .jf-row > input,
    .jsonForm .jf-row > textarea,
    .jsonForm .jf-row > select,
    .jsonForm .jf-row > div.cke {
        display:block;
        width:100%!important;
    }
    .jsonForm .jf-row > label {
        display:block;
        width:100%;
        max-width:100%;
    }
    .jsonForm .chosen-container {max-width:100%;}

    .jsonForm .jf-row .input-wrapper {
        display:block;
        width:100%!important;
    }
    .jsonForm .jf-row .input-wrapper input {
        width:100%;
    }

    .jsonForm .fileuploader .cropDisplay .imageInput,
    .jsonForm .fileuploader .cropDisplay .imageOutput {
        display:block;
        width:auto;
        margin:4px;
    }
    .jsonForm .fileuploader .cropDisplay .imageOutput {
        margin-top:0px;
    }
}

@media only screen and (max-width: 487px) {

    .jsonForm .jf-header-bounds {
        padding: 0 10px 40px 10px;
    }

    .logo {float:none; text-align:center; width:100%; margin:10px 0;}
}



.jsonForm.inIframe {}
.jsonForm.inIframe .jf-header-bounds,
.jsonForm.inIframe > section > header > h2,
.jsonForm.inIframe > section > .intro,
.jsonForm.inIframe > section > fieldset,
.jsonForm.inIframe > section > .not-applicable {
    width:100%;
}
.jsonForm.inIframe.jsonForm ul.jf-page-control-title {right:10px;}

/* Hacky fix for primary-secondary radio button field */
.jsonForm [id*="anotherPractice"].jf-input-type-radio{
    min-width: 190px;
    width: 2%;
}

.jsonForm #f8a th,
.jsonForm #f8a td,
.jsonForm #f8aa th,
.jsonForm #f8aa td,
.jsonForm #f9a th,
.jsonForm #f9a td{
    width: 33%;
}

/* 240617 */
/* Contact system buttons (the contact form style is in the modal (see styledAlert.js) */

.jsonForm .locktonContactActions{
    position: absolute;
    /*top: 1px;
    right: 1px;*/
    bottom: -4px;
    right: 25px;
}

.jsonForm .locktonContactAddButton,
.jsonForm .locktonContactEditButton {
    line-height: 30px;
    padding: 0;
    vertical-align: middle;
    display: inline-block;


    position:relative;
    margin-left: 0.25em;
}

.jsonForm select[data-type='contact'] ~ .locktonContactActions .locktonContactEditButton {
    display:none
}
.jsonForm select[data-type='contact'][data-answered='true'] ~ .locktonContactActions .locktonContactEditButton {
    display: inline-block;
}
.jsonForm .jf-input-type-repeatable .locktonContactActions{
    position: absolute;
    z-index: 9;
    bottom: 0;
    top: auto;
    /* margin: 0; */
    margin-bottom: 8px;
    right: 4px;
    text-align: center;
    max-width: 0;
    padding: 0;
    border:0;
    overflow: hidden;
    white-space: nowrap;
    background: #ffffff;
    display: inline-block;
    height: 20px;
}
.jsonForm .jf-input-type-repeatable .jf-row:hover .locktonContactActions{
    max-width: none;
}
.jsonForm .jf-input-type-repeatable .locktonContactAddButton {
    /* right: auto;
    left: 0; */
}
.jsonForm .jf-input-type-repeatable .locktonContactAddButton {
    /*    right: auto;
       left: 0; */
}
.jsonForm.readonly .locktonContactActions {
    display:none!important;
}

/* Contact system icons */
.jsonForm .locktonContactActions svg[class*=icon]{
    width: 20px;
    height: 20px;
    /*fill:  #4a4a4a;*/
    fill:  #888888;
    color: #ffffff;
}
.jsonForm .locktonContactActions a:hover svg[class*=icon]{
    fill: #1C75BC;
}
.jsonForm .locktonContactActions svg[class*=icon]:before{
    content: '';
}
#hidden-for-renewal-f1-1b{
    display: none;
}
