
.three-column-grid-container {
	.three-column-grid {
		@media only screen and (max-width: 1199px) {
			padding-bottom: 35px;
		}
		@include media("<tabport") {
			padding-bottom: 0;
		}

		.block {
			position: relative;
			padding-bottom: 62px;
			-ms-flex: 1 1 33.33%;
			-webkit-flex: 1 1 33.33%;
			flex: 1 1 33.33%;

			max-width:33.33%;

			@media only screen and (max-width: 1199px) {
				-ms-flex: 1 1 50%;
				-webkit-flex: 1 1 50%;
				flex: 1 1 50%;
				padding-bottom: 52px;

				max-width:50%;
			}
			@include media("<tabport") {
				-ms-flex: none;
				-webkit-flex: none;
				flex: none;
				display: block;
				width: 100%;
				padding-bottom: 25px;
			}

			.image-wrap {
				position: relative;
				display: block;
				margin-bottom: 20px;
				overflow: hidden;

				img {
					width: 100%;
					height: auto;
					min-height: 110px;
				}

				.block-title {
					display: block;
					position: absolute;
					padding: 8px 20px;
					@include Font(Proxima-Reg);
					font-size: 18px;
					line-height: 22px;
					color: $white;
					z-index: 1;
					transition:all 250ms;

					&.left {
						left: 0;
						top: 29px;

						@include media("<wide-tab") {
							top: 15px;
						}
					}
	
					@include media("<=phoneland") {
						padding: 7px 16px;
						font-size: 16px;
						line-height: 20px;
					}

					&.bottom {
						left: 0;
						right: 0;
						bottom: 0;
						height: 31px;
						padding: 7px 16px;
						@include Font(Proxima-Reg);
						font-size: 16px;
						line-height: 17px;

						
					}

					
				}

				&:hover, &:active {
					.block-title {
						background: $charcoal-grey;
					}
				}
			}
			.read-more-link {
				position: absolute;
				bottom: 41px;
				left: 16px;
				@include Font(Proxima-Reg);
				z-index: 1;

				@media only screen and (max-width: 1199px) {
					bottom: 31px;
				}
				@include media("<tabport") {
					position: static;
				}
			}

/************** Alternative grid *********************/

			&.excerpt-wrap {
				.excerpt {
					display: block;

					p {
						color: $greyish-brown;
					}
					.read-more-link {
						transition:all 300ms;
					}
				}

				&:hover, &:active {
					cursor: pointer;
					
					.excerpt {
						.read-more-link {
							color: $warm-grey-two;
							@if($site == mobility) {
								color: $white;
							}
						}	
						.image-wrap .block-title {
							background: $charcoal-grey;
						}	
					}		
				}
			}
		}
	}
}

@media only screen and (max-width: 1199px) {
	.ie11 .three-column-grid-container .three-column-grid .block, .ie10 .three-column-grid-container .three-column-grid .block {
	-ms-flex-preferred-size: 47%;
	-webkit-flex-basis: calc(50% - 24px);
	        flex-basis: calc(50% - 24px);
	}
}
@media only screen and (max-width: 767px) {
	.ie11 .three-column-grid-container .three-column-grid .block, .ie10 .three-column-grid-container .three-column-grid .block {
	-ms-flex-preferred-size: 100%;
	-webkit-flex-basis: calc(100% - 16px);
	        flex-basis: calc(100% - 16px);
	}
}