.pf-dashboard {
    .header-alert .svg-icon {
        display: inline-block;

        margin: 0;

        vertical-align: middle;

        fill: $b_text;
    }

	.notify-bounds{
        overflow-x: hidden;
        // left:-46px;
        position:absolute!important;
        left:calc(50% + 6px);
        top:-16px;
        width:100%;
	}

    .notification {
        position: relative;
        z-index: 1;

        padding: 16px 0 8px 0;

	    h1 {
	        font-size: 18px;

	        color: #0086BF;
	    }
	    .delete {
	        position: absolute;
	        top: 16px;
	        right: 0;

	        display: block;

	        padding: 0;

	    }
    }

    #headband div.notify-block {
        position: absolute;

        overflow: hidden;

        width: 348px;
        max-height: 0;
        padding: 0 32px;

        transition: all .25s ease-out;

        color: #222222;
        background: #F8F9F7;
        background-clip: padding-box;
        @include media("<=phoneland") {
        	width: 300px;
        }
    }
	

    #headband div.notify-block:after {
        position: absolute;
        top: -18px;
        left: 23px;

        display: none;

        width: 0;
        height: 0;
        margin-left: 0;

        content: ' ';
        pointer-events: none;

        border: solid transparent;
        border-width: 0 12px 18px 12px;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #F8F9F7;
    }
    #headband div.notify-block h1 {
        font-size: 16px;

        margin: 0;
        padding: 0;

        transition: opacity .5s ease-out;

        opacity: 0;
        color: #0086BF;
    }
    #headband div.notify-block .button {
        margin-top: 32px;

        transition: opacity .5s ease-out;

        opacity: 0;
    }
    #headband div.notify-block .notification {
        position: relative;

        margin-top: 8px;

        transition: opacity .5s ease-out;

        opacity: 0;
        border-bottom: 1px solid #979797;
    }

    #headband div.notify-block .notification a {
        display: block;

        padding: 8px 0 16px 0;

        color: #222222;
    }
    #headband div.notify-block .notification .delete {
        position: absolute;
        top: 0;
        right: 0;

        display: block;

        padding: 0;

        color: #979797;
    }
    #headband.headband-notify div.notify-block {
        overflow: visible;

        max-height: 1000px;
        padding: 32px;
    }
    #headband.headband-notify div.notify-block:after {
        display: block;
    }
    #headband.headband-notify div.notify-block .notification,
    #headband.headband-notify div.notify-block h1,
    #headband.headband-notify div.notify-block .button {
        transition: opacity .25s ease-in;

        opacity: 1;
    }

    #headband .header-alert {
        font-size: 14px;
        font-weight: 300;
        display: table-cell;
        vertical-align: middle;
        line-height: 54px;
        transform: translateZ(0);
    }

    .header-alert .alert-num {
        font-size: 12px;
        line-height: 20px;

        position: relative;

        display: inline-block;

        box-sizing: border-box;
        width: 20px;
        height: 20px;

        text-align: center;

        color: #FFFFFF;
        border-radius: 50%;
        background: $a_accent;
    }
    #headband .header-alert span {
        display: inline-block;

        padding-left: 10px;

        vertical-align: middle;


        @include media("<=phoneland") {
            display: none;
        }


    }
    #modal-notifications {
    	.modal-contentWrap{
	    	text-align:left;
	    	min-width:600px;
	    	padding:16px 32px;
	    	@include media("<=phoneland") {
	    		min-width:260px;
	    		padding:16px;
	    	}
    	}
    	.modal-close{
    		border-radius:0;
    		background-color:transparent;
    		top:16px;
    		.icon-close{
    			width:20px;
    			height:20px;
    		}
    	}
    }

}