$invert-contacts: false;

@if($site == lsos){
	$invert-contacts: true;
}

.page-header{
    position: relative;
    z-index: 1;

    overflow-x: hidden;

    color: $a_text;

    @if($invert-contacts == true) {
    	@at-root{
    		.contactPage &{
    			color: $b_text;
    		}
    	}
    }
    background: url($topsection-bg-img) center top no-repeat;
    background-size: cover;
    &::before { // Left stripe
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 0;

        display: block;

        width: 3px;


		@if ($site != mobility) {
        	content: '';
        }

        background-color: $a_accent;

        @if ($site == accountants) {
            background-color: $a_other;
        }

        @if($invert-contacts == true) {
        	@at-root{
        		.contactPage &{
        			background-color: $b_accent;
        		}
        	}
        }

		@if (($site != accountants) and ($site != mobility)) {
	        @include media('<=phoneland') {
	            top: 0;
	            right: 0;
	            bottom: auto;
	            left: 0;

	            width: auto;
	            height: 3px;
	        }
	    }
    }
    .flex-parent {
        padding-top: 0;
        padding-bottom: 0;

        @include media('<=phoneland') {
            display: block;

            width: 100%;
            padding-right: 13px;
            padding-left: 13px;

                -ms-flex: none;
            -webkit-flex: none;
                    flex: none;
        }
        .block {
            position: relative;

            padding-top: 40px;
            padding-bottom: 32px;

            flex-basis:66%;

            @at-root{
            	#home &{
            		flex-basis:44%;
            		@include media('>1200px') {
            			padding-top:56px;
            		}
            	}
            	.ie11 body:not(.resources-page) &{
            		flex-basis:61%;
            	}
            	.ie11 #home &{
            		flex-basis:38%;
            	}
            }

			@include media('<=1200px') {
            	padding-top: 32px;
            }

            @include media('<=wide-tab') {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            @include media('<=phoneland') {
                display: block;

                width: 100%;
                padding-bottom: 27px;

                    -ms-flex: none;
                -webkit-flex: none;
                        flex: none;
            }
            .content-wrap {
                font-size: 16px;
                line-height: 20px;

                padding-left: 20px;

                @if ($site == accountants) {
                    padding-left: 32px;
                }

                @include Font(Proxima-Light);
                
                @if ($site != accountants) {

                    @include media('<=1200px') {
                        padding-left: 15px;
                    }
                    @include media('<=wide-tab') {
                        padding-left: 12px;
                    }
                }
                p {
                    @include media('<=1200px') {
                        @at-root{
                        	.homepage &{
		                        font-size: 14px;
		                        line-height: 17px;
                        	}
                        }
                    }
                }

            }
        	.additional-content {
                position: relative;

                margin-top: -3px;
                padding-left: 23px;

                @include media('<=1200px') {
                    padding-right: 15px;
                }
                @include media('<wide-tab') {
                    margin-top: 0;
                    padding-right: 0;
                    padding-left: 15px;
                }
				ul {
			        list-style-type: none;

			        @include media('<1200px') {
			            padding-top: 5px;
			        }
			        li {
			            font-size: 14px;
			            line-height: 18px;

			            padding-bottom: 7px;

			            @include Font(Proxima-Reg);

			            @if ($site != lsos) {
			                @include media('<1200px') {
			                    padding-bottom: 20px;
			                } // @TODO - Why is this just LSOS?
			            }

			            span {
			                line-height: .9;

			                display: inline-block;

			                width: 9px;
			                height: 10px;
			                margin-right: 5px;

			                color: $b_accent; // @TODO Is this colour relevant anymore?

			                @include Font(Proxima-Black);
			                svg {
			                    display: block;

			                    width: 100%;
			                    height: 100%;

			                    fill: $a_accent;
			                }
			            }
			        }
			    }
            }
            &:first-child{
            	padding-right: 32px;

	            @include media('<wide-tab') {
	                padding-left: 0;
	            }
	            @include media('<=phoneland') {
	                padding-right: 7px;
	            }

				@if($site == mobility) {
	            	text-align:center;
				}

				@at-root{
					.pf-admin &,
					.pf-dashboard &{
						text-align:left;

						@if($site == mobility) {
							.content-wrap{
								padding-left:0;
							}
						}
					}
				}

	            &::before {
	                position: absolute;
	                z-index: 0;
	                top: 0;
	                right: 0;
	                bottom: 0;

	                display: block;

	                width: 2500px;

	                content: '';

	                background-color: $a_background;

	                @if($invert-contacts == true){
			        	@at-root{
			        		.contactPage &{
			        			background-color: $b_background;
			        		}
			        	}
			        }

	                @include media('<=phoneland') {
	                    right: -32px;
	                }
	            }

				&::after {
					@include media('<tabport') {
		                position: absolute;
		                z-index: 0;
		                top: 0;
		                bottom: 0;
		                left: 100%;

		                display: block;

		                width: 2500px;

		                content: '';

		                background-color: $a_background;

		                @if($invert-contacts == true){
				        	@at-root{
				        		.contactPage &{
				        			background-color: $b_background;
				        		}
				        	}
				        }
		            }
				}

	            .content-wrap {
	                @if (($site != accountants) and ($site != mobility)) {
	                    border-left: 3px solid $a_accent;

	                    @if($invert-contacts == true){
				        	@at-root{
				        		.contactPage &{
				        			border-left-color: $b_accent;
				        		}
				        	}
				        }
	                }
	            }
            }
            &:last-child{
            	&::after { // right bg
	                position: absolute;
	                z-index: 0;
	                top: 0;
	                bottom: 0;
	                left: 100%;

	                display: block;

	                width: 2500px;

	                content: '';

	                background-color: $a_background;

	                @if($invert-contacts == true){
			        	@at-root{
			        		.contactPage & {
			        			background-color: $b_background;
			        		}
			        	}
			        }

	                @include media('<=phoneland') {
	                    left: -32px;
	                }
	            }
	            &.actions-block{
	            	padding-left:16px;
	            	&::before{
	            		display:none;
	            	}
	            	&::after{
						@if($site == mobility){
							display:none;		
						}
						background-color: $a_background;
	            	}
	            	.content-wrap{
	            		border:0 none;
	            	}
	            }

            }
            & + .block{
            	flex-basis:34%;
            	
            	@at-root{
	            	#home &{
	            		flex-basis:56%;
	            	}
	            	.ie11 body:not(.resources-page) &{
	            		flex-basis:27%;
	            	}
	            	.ie11 #home &{
	            		flex-basis:51%;
	            	}
	            }
            	padding-left: 52px;

            	color: $b_text;

            	@if($invert-contacts == true){
		        	@at-root{
		        		.contactPage &{
		        			color: $a_text;
		        		}
		        	}
		        }



	            @include media('<1200px') {
	                padding-left: 23px;
	            }
	            @include media('<tabport') {
	                display:none;
	                @at-root{
		            	#home &{
		            		display:inline-block;
		            	}
		            }

	            }
	            @include media('<=phoneland') {
	                padding-right: 4px;
	                padding-left: 0;
	            }
	            &::after { // right bg
	                background-color: $b_background;

					@if($invert-contacts == true){
			        	@at-root{
			        		.contactPage &{
			        			background-color: $a_background;
			        		}
			        	}
			        }

	                left:0;

	                @include media('<=phoneland') {
	                    left: -32px;
	                }
	            }
	            &::before { // left border
	                position: absolute;
	                z-index: 3;
	                top: 0;
	                bottom: 0;
	                left: 0;

	                display: block;

	                width: 3px;

	                @if ($site == accountants){
	                    width: 6px;
	                }

	                content: '';

	                background-color: $b_accent;

	                @if($invert-contacts == true){
			        	@at-root{
			        		.contactPage &{
			        			background-color: $a_accent;
			        		}
			        	}
			        }

	                @if ($site == accountants){
	                    background-color: $b_other;
	                }

	                @include media('<=phoneland') {
	                    right: -32px;
	                    bottom: auto;
	                    left: -32px;

	                    width: auto;
	                    height: 3px;
	                }
	            }
	            .content-wrap {
	                @if (($site != accountants) and ($site != mobility)) {
	                    border-left: 3px solid $b_accent;

	                    @if($invert-contacts == true){
				        	@at-root{
				        		.contactPage &{
				        			border-left-color: $a_accent;
				        		}
				        	}
				        }
	                }
	                button{
				    	margin-top:0;
				    }
	            }
	            a {
	                color: $b_accent;
	                @if( $site == accountants) {
	                    color: $a_text;
	                }

					@if($invert-contacts == true){
			        	@at-root{
			        		.contactPage &{
			        			color: $a_text;
			        		}
			        	}
			        }


	                &:hover {
	                    color: $b_text;
	                    @if( $site == accountants) {
	                        color: $a_accent;
	                    }

	                    @if($invert-contacts == true){
				        	@at-root{
				        		.contactPage &{
				        			color: $a_accent;
				        		}
				        	}
				        }
	                }
	            }
	            .additional-content {
	                ul {
	                    li {
	                        span {
	                            svg {

	                                fill: $b_accent;
	                            }
	                        }
	                    }
	                }
	            }
	            .row {
	                @include media('<1200px') {
	                    padding-top: 27px;
	                    padding-right: 15px;
	                }
	                @include media('<wide-tab') {
	                    padding-top: 0;
	                    padding-right: 0;
	                }
	            }
	            .row .btn {
	                display: block;
	                float: right;

	                @include media('<wide-tab') {
	                    display: inline-block;
	                    float: none;

	                    margin-left: 30px;
	                }
	                @include media('<=phoneland') {
	                    margin-bottom: 25px;
	                    margin-left: 16px;
	                }
	            }
	        }
        }
        .actions-block{
        	text-align:right;
        }
        & > div > div {
            position: relative;
            z-index: 5;
        }
        .column-title {
            font-size: 24px;
            line-height: 28px;

			margin:0;
			

            @if ($site == mobility) {
            	font-size: 32px;
				line-height: 1.22em;

				margin-bottom: 0;
            }

            display:block;

            @include Font(Proxima-Light);
            
            @include media('<=phoneland') {
                font-size: 18px;
            }
        }
        .block-content{
        	margin-top:12px;
        }
        .column-content{
        	@if ($site == mobility) {
				display:none;
		        @at-root {
			        .pf-dashboard &{
				        display:block;
			        }
		        }
        	}
        }
    }
    
}