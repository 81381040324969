
.sidebar-news-widget {
	margin-bottom: 15px;

	.carousel-button-wrapper {
		@media only screen and (min-width: 768px) {
			display: none;
		}
	}

	.widget-title {
		padding: 7px 18px;
		margin-bottom: 16px;
		min-height: 32px;
		@include Font(Proxima-Light);
		font-size: 18px;
		line-height: 18px;
		color: $white;
		box-sizing: border-box;
	}
	.news-widget {
		&>ul {
			font-size: 0;
			border: 0;

			@media only screen and (min-width: 768px) {
				height: auto !important;
			}
			@include media("<tabport") {
				padding-bottom: 15px;
				margin-bottom: 20px;
				border-bottom: solid 1px #dedddd;
			}

			&>li {
				font-size: 16px;
				width: 100%;
				padding-bottom: 23px;

				@media only screen and (min-width: 768px) {
					display: block;
				}
				@include media("<tabport") {
					padding-bottom: 15px;
				}

				a {
					display: block;
					color: $greyish-brown;

					&:hover, &:active {
						color: $greyish-brown;
					}
					.image-wrap {
						float: left;
						width: 125px;
						padding: 0 15px 15px 0;
						box-sizing: border-box;

						@include media("<=phoneland") {
							width: 110px;
							padding: 0 10px 10px 0;
						}

						img {
							width: 100%;
							height: auto;
						}
					}
					.box-title {
						padding: 3px 0 0 125px;
						margin-bottom: 12px;
						font-size: 20px;
						line-height: 20px;
						font-weight:300;

						@include media("<=phoneland") {
							font-size: 18px;
							padding: 0 0 0 110px;
						}
					}
					.date {
						margin-bottom: 12px;
						padding: 0 0 0 125px;
						font-size: 16px;
						line-height: 1.1;
						font-weight:300;

						@include media("<=phoneland") {
							font-size: 15px;
							padding: 0 0 0 110px;
						}
					}
					.read-more {
						display: block;
						padding: 0 0 0 125px;
						@include Font(Proxima-Reg);
						font-size: 15px;
						line-height: 20px;
						color: $link-primary;

						@media only screen and (min-width: 768px) {
							display: none;
						}
						@include media("<=phoneland") {
							padding: 0 0 0 110px;
						}
					}
				}
			}
		}
	}
}
.sidebar_news{
	.widget-title{
		background-color: $newstype-news;
	}
}
.sidebar_guidance{
	.widget-title{
		background-color: $newstype-guidance;
	}
}
.sidebar_proposalform{
	.widget-title{
		background-color: $newstype_proposalform;
	}
}

