// This is for all admin/dashboard jsonForm overrides
.pf-dashboard,
.pf-admin {
    .jsonForm {
        fieldset {
            max-width: 600px;
            padding: 10px 0;
            border: 0 none;
        }
        legend{
        	font-weight:600;
        }
        section h2{
            padding:0;
        }
    }
    .jsonForm button[type="submit"]{
        background-color:#1C75BC;
    }
   
    .jsonForm .jf-header-bounds,
    .jsonForm > section > header > h2,
    .jsonForm > section > .intro,
    .jsonForm > section > fieldset,
    .jsonForm > section > .not-applicable {
        padding-right: 0;
        padding-left: 0;
    }
}

.pf-checkout {
    .jsonForm input[type='submit'] {
        font-size: 14px;
        line-height: 17px;

        margin-top: 0;
        padding: 6px 20px;

        color: $a_btn-text!important; //Overriden by top-section link colour/dashboard link colour otherwise;
        border: solid 1px $a_btn-border;
        background-color: $a_btn-background;

        @include Font('Proxima-Bold');
        &:hover,
        &:active {
            color: $a_btn-hover-text;
            border: solid 1px $a_btn-hover-border;
            background-color: $a_btn-hover-background;
        }
    }
}
.pf-admin{
    .jsonForm > section {
        padding: 0;
    }
    section:last-of-type,
    .jsonForm > section:last-of-type {
        padding: 0;
    }
    .jsonForm textarea {
        height: 12em;
    }
    .jsonForm .chosen-container {
        max-width: 100%;
    }
        // Appears t be used in the login/create and login/edit pages
    .jsonForm .jf-row.jf-input-type-text#permissions .check-wrap {
        display: block;

        width: 100%;
    }
    .jsonForm .jf-row.jf-input-type-text#permissions input {
        clear: left;

        width: 30px;
    }
}
#declarationPage #declaration{
    padding-top:0;
}