.dashboard-contact{
    .chosen-container {
        max-width: 100% !important;
    }
    .jsonForm > section {
        padding: 0;
    }
    section:last-of-type, .jsonForm > section:last-of-type {
        padding: 0;
    }
    .title-section {
        margin: 0;
    }
    .jsonForm textarea {
        height: 12em;
    }
}