.social-icons-wrap {
	ul.social-icons {
		text-align: left;
		list-style-type: none;

		li {
			display: inline-block;
    		vertical-align: middle;
			&+li {
				margin-left: 3px;
			}

			a {
				display: block;

				svg {
					display: block;
					width: 24px;
					height: 25px;
					transition: fill 250ms;
					&.twitter {
					  fill: #55ACEE;
					}
					&.facebook {
					  fill: #3B5998;
					}
					&.linkedin {
					  fill: #0976B4;
					  width: 25px;
					}
					&.googleplus {
					  fill: #DD4B39;
					}
				}
				.icon-ellipse {
					transition: fill 250ms;
				}

				&:hover, &:active {
					.icon-ellipse {
						fill: $charcoal-grey;
					}
					svg{
						fill: $charcoal-grey;	
					}
				}
			}
		}
	}
}

.social-icons-wrap.with-like {
	ul.social-icons {
	  display: inline-block;
	  vertical-align: middle;
	}
	iframe {
	  display: inline-block;
	  vertical-align: middle;
	  margin-left: 8px;
	}
}
