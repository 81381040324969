
.popular-resources-widget {
	padding: 17px 20px 0;
	background-color: $side_background;
	margin-bottom: 28px;
	border-bottom: 6px solid $side_border;

	.widget-title {
		@if($site != accountants) {
			margin: 0 0 26px;
		}
		@include Font(Proxima-Thin);
		font-size: 28px;
		line-height: 34px;
		color: $greyish-brown;

		@if($site != accountants) {
			@include media("<wide-tab") {
				margin: 0 0 19px;
			}
		}

		@include media("<=phoneland") {
			@if($site != accountants) {
				margin: 0 0 16px;
			}
			font-size: 26px;
			line-height: 32px;
		}
	}
	.widget-content {
		padding: 0 0 18px;
		border-bottom: 1px solid $white;

		@include media("<tabport") {
			padding: 0 0 6px;
		}

		.content-block {
			position: relative;
			display: block;
			@if($site != accountants) {
				margin-bottom: 25px;
			} @else {
				font-size: 14px;
	            font-weight: 300;
	            line-height: 1.43em;

	        	background-color: $side_background;

	            padding: 18px 26px;

	            color: $body-text;

				border-top: solid 1px rgba($silver,.2);

	            &:hover {
	                background-color: rgba($accent-secondary,.07);
	            }
			}

			.image-wrap {
				float: left;
				width: 34px;
				height: 34px;
				overflow: hidden;

				img {
					height: 100%;
					width: auto;
				}
				@if($site == mobility) {
					display:none;
				}
			}
			.resource-title {
				padding-left: 48px;
				@include Font(Proxima-Light);
				font-size: 13px;
				line-height: 16px;
				color: $greyish-brown;

				@if($site == mobility) {
					padding-left:0;
				}
			}

			@if($site != accountants) {
				&:hover, &:active {
					.resource-title {
						color: $mid-grey;
					}
				}
			}
		}
	}
}