.pf-checkout {
    ul{
        margin: 1em 0;
    }
    .header-section {
        font-size: 26px;
        line-height: 1.08em;

        position: relative;

        margin: 0 0 .5em;
        padding: 20px 0;

        background: $side_background;
        &:before {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;

            display: block;

            width: 2500px;
            margin-left: -2500px;

            content: '';

            background: $side_background;
        }
    }
    .header-subsection {
        margin-bottom: 0;
        + .checkout-subtitle {
            display: inline-block;

            margin-bottom: 1em;
        }
    }
    main .block {
        flex-basis: 66%;
        @at-root .ie11 &{
            flex-basis: 60%;
            margin-bottom:20px;
        }
    }
    .block.sidebar {
        flex-basis: 33%;
        @at-root .ie11 &{
            flex-basis: 30%;
        }
    }
    .content-block {
        margin-bottom: 16px;

        border-bottom: solid 2px $white-two;
    }
    main .block.paymentselect-option {
        max-width: 50%;

        flex-basis: 30%;

        @if($site == 'lsos'){
            display:flex;
            flex-direction:column;
            .paymentselect-action,
            .paymentselect-header{
                flex:none;
            }
            .paymentselect-content{
                //display:flex;
                flex: 1 1 auto;
            }
        }


        @include media("<desktop") {
            flex-basis:47%;

            padding-bottom:32px;
        }

        @include media("<=phoneland") {
            flex-basis:100%;

            padding-bottom:32px;
            max-width:100%;
        }

        .paymentselect-header {
            padding: 10px 16px;

            text-align: center;
            vertical-align: middle;

            color: $white;
            background: $accent-secondary;
            .paymentselect-title {
                font-size: 22px;
                font-weight: 400;
                line-height: 1.55em;

                margin: 0;

                @include media("<=1100px") {
                    font-size: 18px;
                }
            }
        }
        .checkout-table {
            margin: 0 auto;
            .flex-parent {
                > .ct-label {
                    flex-basis: 33%;
                }
                > .ct-data {
                    flex-basis: 35%;
                }
            }
        }
        .icon-payonline {
            width: 33px;
            height: 28px;

            vertical-align: middle;

            fill: $white;
        }
        .icon-finance {
            width: 33px;
            height: 33px;

            vertical-align: middle;

            fill: $white;
        }
        .icon-invoice {
            width: 26px;
            height: 26px;

            vertical-align: middle;

            fill: $white;
        }
        .paymentselect-content {
            padding: 16px 16px 48px 16px;

            background: $side_background;
            .checkout-table .flex-parent {
                padding-top: 0;
                padding-bottom: 0;
                .block {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
        .paymentselect-list {
            // padding:0;
            margin: 0;

            list-style-type: none;
            .paymentselect-listitem {
                position: relative;

                margin-bottom: 6px;
                padding-left: 25px;
                // font-size: 16px;
                // line-height: 18px;
                .list-icon {
                    position: absolute;
                    z-index: 1;
                    top: 3px;
                    left: 0;

                    display: block;

                    width: 12px;
                    height: 14px;

                    @include media('<=phoneland') {
                        font-size: 20px;
                        line-height: 18px;

                        top: 0;
                    }
                    svg {
                        display: block;

                        width: 100%;
                        height: 100%;

                        fill: $accent-secondary;
                    }
                }
            }
        }
        .btn_a {
            font-size: 16px;
            font-weight: 600;

            width: 100%;
            padding: 10px 16px;

            text-align: center;
            text-transform: uppercase;
        }
        @if($site == mobility){
            .paymentselect-action{
                margin-top: -20px;
                width: 90%;
                margin-left: 5%;
            }
        }
    }
    .contact-info .flex-parent {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29em;

        margin: 0 -32px;
        padding: 8px 16px;
        > .block {
            min-width: 0;
            padding: 8px 16px;
        }
        > .contact-label {
            flex-basis: 28%;
        }
        > .contact-detail {
            font-weight: 600;

            flex-basis: 56%;
            span {
                display: block;
            }
            a {
                overflow: hidden;

                text-overflow: ellipsis;
            }
        }
    }
    address {
        font-style: normal;
    }
    .checkout-table {
        width: 100%;
        max-width: 460px;
        .flex-parent {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.29em;

            margin: 0 -32px;
            padding: 8px 16px;
            > .block {
                min-width: 0;
                padding: 8px 16px;
            }
            > .ct-label {
                @if($site == mobility) {
                    flex-basis: 36%;
                } @elseif($site == lsos) {
                    flex-basis: 55%;
                } @else{
                    flex-basis: 60%;
                }
            }
            > .ct-data {
                font-weight: 600;

                @if($site == mobility) {
                    flex-basis: 56%;
                } @else{
                    flex-basis: 40%;
                }
                @at-root .ie11 &{
                    @if($site == lsos){
                        flex-basis: 30%;
                    } @else {
                        flex-basis: 48%;
                    }
                }
                span {
                    display: block;
                }
                a {
                    overflow: hidden;

                    text-overflow: ellipsis;
                }
            }
        }
        &.ct-totalrow {
            max-width: none;
            .flex-parent {
                justify-content: flex-end;
                .ct-label {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 2.29em;

                    max-width: 200px;

                    text-align: right;
                }
                .ct-data {
                    font-size: 24px;
                    line-height: 1.33em;

                    max-width: 200px;
                }
            }
        }
    }
    .checkout-actions {
        display: flex;

        padding-top: 16px;

        border-top: solid 2px $white-two;

        flex-direction:row-reverse;

        justify-content: space-between;
        form{
            text-align:right;
        }
    }
    .content-block + .checkout-actions,
    .checkout-actions + .checkout-actions {
        border-top: 0 none;
    }
    .info-widget {
        blockquote {
            font-size: 16px;

            margin: 1em 0 0;
            padding: 0;

            @include Font(Proxima-ThinIt);
            .quote-attribution {
                font-size: 13px;
                font-weight: 300;
                font-style: normal;
                line-height: 17px;

                margin-top: .5em;
            }
        }
    }
    .map {
        height: 250px;
    }
    .checkout-subtitle {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.57em;
    }
    .extra-list {
        margin: 16px 0;

        list-style: none;
        li {
            // margin-bottom: 20px;
            font-size: 16px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            line-height: 1.57em;

            position: relative;

            padding-left: 25px;
            .list-icon {
                font-size: 18px;
                line-height: .9;

                position: absolute;
                z-index: 1;
                top: 3px;
                left: 0;

                display: block;

                width: 12px;
                height: 14px;

                color: $link-primary;

                @include Font(Proxima-Black);
                @include media('<=phoneland') {
                    font-size: 20px;
                    line-height: 18px;

                    top: 0;
                }
                svg {
                    display: block;

                    width: 100%;
                    height: 100%;

                    fill: $link-primary;
                }
            }
        }
    }
    .extra-option {
        .jf-input-type-radio {
            display: flex;

            justify-content: space-between;
            > label {
                display: inline-block;

                max-width: 70%;

                flex: 1 1 70%;
                @include media('<=phoneland') {
                    max-width: none;

                    flex-basis: 100%;
                }
                h3 {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.0em;

                    margin: 0;

                    color: $rouge-two;
                }
                span {
                    text-align: right;
                    vertical-align: top;
                }
            }
            > span {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.57;

                text-align: right;
                vertical-align: top;

                flex: 1 1 30%;
                @include media('<=phoneland') {
                    flex-basis: 100%;
                }
            }
        }
    }
    .extra-detail {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43em;

        color: $rouge-two;
    }
    .focb:before {
        font-size: 1.1em;
        font-weight: bold;
        line-height: 1.1em;

        display: inline-block;

        width: 1.1em;
        height: 1.1em;
        margin: auto 6px auto 0;

        content: '\00a0';  /*nbsp*/
        text-align: center;

        border: 1px solid rgba($greyish-brown, .3);
        /*position: absolute;  
        left: 0;  */
        background-color: #FFFFFF;
        text-shadow: 1px 1px 1px rgba($greyish-brown, .2);
    }
    input[type=radio].focbed {
        position: absolute;

        width: 2em;
        height: 2em;
        /*display: none;  */

        opacity: .001;
    }
    input[type=radio] + .focb:before {
        border-radius: 50%;
    }
    input[type=radio]:checked + .focb:before {
        position: relative;
        /*content: "\2022";  bullet*/

        background-color: $rouge;
        box-shadow: 0 0 0 4px #FFFFFF inset, 0 0 1px 0 $greyish-brown;
    }
    input[type=radio]:required:checked + .focb:before {
        box-shadow: 0 0 0 4px #FFFFFF inset, 0 0 1px 1px $greyish-brown;
    }
    &.loginOrCreate {
        .tabbed {
            border: 0 none;
            background: transparent;
            > ul {
                > li {
                    &:first-child {
                        a {
                            padding-right: 1em;
                            padding-left: 0;

                            border-right: 1px solid rgba($greyish-brown,.5);
                        }
                    }
                    > a {
                        margin: 0;
                        padding: 0;
                        padding-left: 1em;

                        color: $greyish-brown;
                        border: 0 none;
                        border-radius: 0;
                        background: transparent;
                    }
                }
            }
            > section {
                border: 0 none;
                background: transparent;
                > div > div {
                    padding: 0;

                    border-bottom: 0 none;
                }
            }
        }
    }
    .faq-section .faq-accordion {
        border: 0 none;
        .accordion-item {
            border: 0 none;
            .accordion-trigger {
                padding: 4px 0;
                .title-tert {
                    font-weight: 400;
                }
                &:after,
                &:before {
                    display: none;
                }
            }
            &.accordion-item-open {
                .accordion-trigger {
                    border-bottom: 0 none;
                    background: transparent;
                    .title-tert {
                        font-weight: 400;
                    }
                }
                .accordion-content {
                    padding: 8px 0 16px;
                    p {
                        margin-bottom: 0;
                        padding-right: 0;
                        padding-left: 0;
                    }
                    p + p {
                        padding-top: 16px;
                    }
                }
            }
        }
    }
    .checkout-error {
        color: $error-color;
        a {
            color: $error-color;
        }
    }
    .trans-success,
    .trans-error {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.78em;

        margin-bottom: 0;
        & + p {
            margin-bottom: 16px;
        }
    }
    .trans-success {
        color: $success-color;
    }
    .trans-error {
        color: $error-color;
        input[type='submit'] {
            font-size: 18px;

            text-decoration: underline;
            // font-weight: 600;

            color: $error-color;
            border: 0 none;
            background: transparent;

            -webkit-appearance: none;
        }
    }
    &.secure-hosting {
        main .block{
            flex-basis:60%; //IE11 fix as browser sniff doesn't happen on secure hosting template
        }
        .block.sidebar {
            flex-basis: 30%;//IE11 fix as browser sniff doesn't happen on secure hosting template
        }
        .checkout-table {
            .flex-parent {
                > .ct-label {
                    flex-basis: 70%;
                }
                > .ct-data {
                    flex-basis: 30%;
                }
            }
        }
        input[type='submit'].trans-back {
            font-size: 16px;
            // font-weight: 600;

            color: $b_btn-text;
            border: 1px solid $b_btn-border;
            background: $b_btn_background;

            @extend .btn;
            -webkit-appearance: none;
            &:hover {
                color: $b_btn-hover-text;
                border: 1px solid $b_btn-hover-border;
                background: $b_btn-hover_background;
            }
        }
        .error-details {
            padding: 16px;

            background: rgba($black,.05);

            margin-top:1em;

            border-bottom: 0 none;

            .header-subsection {
                font-size: 18px;
                font-weight: 600;
                line-height: 1.78em;

                margin-top: 0;
                margin-bottom: .5em;
            }
            &:last-child{
                margin-bottom:0;
            }
        }
    }

}

.pf-dashboard.pf-checkout #headband .header-alert{
    display:none;
}

// TEMPORARY FIX FOR MOBILITY @FIXME
.pf-checkout.checkout-yourquote .content-block {
    border-bottom:0 none;
}
.pf-checkout #mainheader,
.pf-checkout #nav {
    max-height: 0!important;
    overflow: hidden!important;
    margin-top: 86px!important;
}
.pf-checkout #nav {
    margin-top: 32px!important;
}
.pf-checkout main .paymentselect-option form {
    @if($site == mobility) {
        height: 90%;
    }
    .paymentselect-content {
        //height:100%;
    }
}

@if($site == mobility){
    .pf-checkout .jsonForm input[type='submit']{
        border-radius: 28px;
    }
}

.secure-hosting {
    #wrapper-for-cardstartmonth,
    #wrapper-for-cardexpire{
        .collection{
            label{
                display:block;
                padding-left:0;
            }
            .jf-input-type-select{
                display:inline-block;
                position:relative;
                select{
                    padding-right:20px;
                }
            }
        }
    }
}

.checkout-paymentmethod.pf-checkout {
    .checkout-actions  {
        flex-direction:row;
    }
}

#complete-print{
    text-decoration:underline;
    cursor:pointer;
    &:hover{
        text-decoration:underline;
    }
}

.checkout-paymentcomplete{
    @include media("<desktop") {
        .block,
        .block.sidebar{
            flex-basis:100%;
        }
    }
}
.checkout-pay-finance-refer,
.checkout-pay-invoice{
    .block{
        max-width:800px;
    }
}
