.widget-console{
    position:relative;
    svg{
        fill:$body-text;
        margin-right:.25em;
    }
    .sidebar-title{
        line-height: 28px;

        font-size: 24px;
        font-weight: 300;

        color: $body-text;
    }
    address{
        font-style: normal;
        margin-bottom:1.5em;
    }
    ul{
        list-style-type: none;
    }
    .edit-client{
        position:absolute;
        top:0;
        right:0;
        font-size: 14px;
        font-weight: 300;
        svg{
            vertical-align:middle;
            margin-right:0;
            margin-left:.5em;
            fill:$link-primary;
        }
    }
}