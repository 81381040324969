/**
* Off-Canvas Nav
**/

/* General styles for all menus */

.off-canvas-nav {
    position: fixed;
    width: 384px;
    height: 100%;
    top: 0;
    z-index: 1000;
    right: -384px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 55px 0 20px 28px;
    overflow-y: scroll;
    background: map_get($pushNav, background);
    background-size: cover;

    @include media("<=phoneland") {
        width: 100%;
        right: -100%;
        padding: 20px 0 20px 16px;
    }
}

.is-offcanvas-open .off-canvas-nav {
    right: 0;
}

.off-canvas-nav ul {
    list-style-type: none;
}

.off-canvas-nav li {
    border-bottom: 2px solid map_get($pushNav, border);
    line-height: 21px;
    padding: 15px 0 15px 15px;
}

.off-canvas-nav li a { // Over specificed due to lame code in _pf-admin.scss
    display: inline-block;
    width: 100%;

    vertical-align: middle;
    @if ($site == mobility) {
        @include Font(Proxima-Reg);
        font-size: 16px;
    } @else {
        @include Font(Proxima-Light);
        font-size: 14px;
    }
    line-height: 21px;
    text-transform: uppercase;
    color: $charcoal-grey;
    box-sizing: border-box;

    @include media("<=wide-tab") {
        height: auto;

        @include Font(Proxima-Reg);
        font-size: 16px;
        line-height: 20px;
        @if ($site == solicitors) {
            color: $accent-secondary;
        } @else {
            color: map_get($pushNav, text);
        }
        text-align: left;
        text-transform: capitalize;
    }

    &:hover, &:active {
        @if ($site == solicitors) {
            color: $accent-secondary !important;
        } @else {
            color: $link-primary !important;
        }

        background-color: $light-grey;

        text-decoration: none;
        @if ($site == mobility) {
            background-color: transparent;
        }

        @include media("<=wide-tab") {
            background-color: transparent;
        }
    }

    .off-canvas-nav li.current a {
        @if ($site == solicitors) {
            color: $accent-secondary !important;
        } @else {
            color: $link-primary !important;
        }

        background-color: $light-grey;

        text-decoration: none;
        @if ($site == mobility) {
            background-color: transparent;
        }

        @include media("<=wide-tab") {
            background-color: transparent;
        }
    }
}

/* Vertical menu that slides from the left or right */

.cbp-spmenu-right.cbp-spmenu-open {
    right: 0px;
}

.page-container {
    overflow-x: hidden;
    position: relative;
    left: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.is-offcanvas-open .page-container {
    left: -384px;
    @include media("<=phoneland") {
        left: -100%;
    }
}

//-------------------------//

.mobile-nav-close {
    position: absolute;
    right: 33px;
    top: 32px;
    width: 14px;
    height: 14px;
    padding: 0;
    background: url("../img/close-icon.svg") no-repeat center center;
    background-size: cover;
    z-index: 100;

    &:hover {
        cursor: pointer;
    }
    @include media("<=phoneland") {
        right: 20px;
    }
    svg {
        fill: $charcoal-grey;
        width: 20px;
        height: 20px;
    }
}

/* SECTION SPECIFIC STYLES */
// PF-ADMIN
.pf-admin .header .flex-nav .logo {
    max-width: none;
}

.pf-dashboard .menu-button,
.pf-brochure .menu-button{
    @include media(">wide-tab") {
        display: none !important;
    }
}

// ---------------------- //
// This is inherited from the old site - please refactor

.off-canvas-nav {
    .main-nav-login {
        position: relative;

        @include media("<=wide-tab") {
            max-height: 52px;
            transition: none;
            border-bottom: 2px solid map_get($pushNav, border);

            @if ($site == mobility) {
                //max-height: 38px;
            }

            &.hidden-box-opened {
                max-height: 300px;
                transition: all 0.6s linear;
            }
        }
        @include media("<=phoneland") {
            border-bottom: 1px solid map_get($pushNav, border);
        }

        .logInBox-sibling {
            position: relative;
            padding-right: 5px;
            z-index: 12;

            @include media("<=wide-tab") {
                //position: absolute;
                //left: 0;
                //top: 0;
                width: 100%;
                height: auto;
                padding-right: 27px;
                // padding-left: 0;
                z-index: 10;
                transform: rotate3d(1, 0, 0, 0deg);
                opacity: 1;
                transition: none;

                &.hidden-box-opened {
                    z-index: -10;
                    transform: rotate3d(1, 0, 0, 90deg);
                    opacity: 0;
                    transition: all 0.25s linear;
                }
            }
            @include media("<=phoneland") {
                padding-right: 19px;
            }

            &.hidden-box-opened {
                color: $link-primary;

                .nav-icon, svg {
                    fill: $link-primary; //@FIXME 0 this just doesn't wori. Appears to be making hte icon light up but class isn't present
                }
            }
        }

        .logInBox-container {
            display: none;
            position: absolute;
            top: 100%;
            right: 14px;
            width: 330px;
            height: auto;
            padding: 29px 35px 32px;
            background: $light-grey;
            z-index: 9999999999;
            box-sizing: border-box;

            &::after {
                display: block;
                position: absolute;
                content: '';
                right: 8px;
                top: -37px;
                border-top: 20px solid transparent;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
                border-bottom: 20px solid $light-grey;
                z-index: 1;
            }

            @include media("<=wide-tab") {
                display: block;
                position: relative;
                top: auto;
                right: auto;
                width: 100%;
                height: auto;
                padding: 24px 26px 26px 0;
                background: inherit;
                z-index: -5;
                transform: rotate3d(1, 0, 0, -90deg);
                opacity: 0;

                transition: none;

                &.hidden-box-opened {
                    z-index: 10;
                    transform: rotate3d(1, 0, 0, 0deg);
                    opacity: 1;
                    transition: all 0.25s linear 0.25s;
                }

                &::after {
                    display: none;
                }
            }
            @include media("<=phoneland") {
                padding-right: 18px;
            }

            .form-fields-wrap {
                .form-field {
                    margin-bottom: 12px;

                    &:first-child {
                        margin-bottom: 15px;
                    }

                    label {
                        display: block;
                        @include Font(Proxima-Light);
                        font-size: 14px;
                        line-height: 17px;
                        text-align: left;
                        color: $charcoal-grey;
                    }
                    input {
                        width: 100%;
                        height: 29px;
                        padding: 0 10px;
                        margin-top: 4px;
                        @include Font(Proxima-Light);
                        font-size: 16px;
                        line-height: 27px;
                        color: #565656;
                        background-color: $white;
                        border: solid 1px $mid-grey;
                        border-radius: 0;
                        box-sizing: border-box;
                        transition: all 300ms;

                        &:focus {
                            border: 1px solid $a_accent;

                            box-shadow: inset 0 0 5px rgba(141, 198, 63, 0.3);
                        }

                    }
                }
                .form-row {
                    .form-links {
                        float: left;
                        padding: 7px 0;
                        line-height: 0;
                        font-size: 0;

                        a {
                            position: relative;
                            display: inline-block;
                            padding: 0;
                            @include Font(Proxima-Light);
                            font-size: 12px;
                            line-height: 15px;
                            text-align: left;
                            color: $charcoal-grey;

                            &:hover, &:active {
                                text-decoration: none;
                                color: $link-primary;
                            }

                            &:not(:first-child) {
                                margin-left: 10px;

                                &::before {
                                    display: block;
                                    position: absolute;
                                    content: "";
                                    left: -5px;
                                    top: 3px;
                                    bottom: 4px;
                                    width: 1px;
                                    background-color: $charcoal-grey;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                    .form-submit {
                        float: right;
                    }
                }
            }
        }
    }
    .main-nav-search {
        position: relative;

        @include media("<=wide-tab") {
            max-height: 52px;
            transition: none;

            @if ($site == mobility) {
                max-height: 38px;
            }

            &.hidden-box-opened {
                max-height: 150px;
                transition: all 0.5s linear;
            }
        }

        .searchBox-sibling {
            position: relative;
            padding-left: 5px;
            z-index: 12;

            &.hidden-box-opened {
                color: $link-primary;

                .nav-icon, svg {
                    fill: $link-primary;
                }
            }

            @include media("<=wide-tab") {
                //position: absolute;
                //left: 0;
                //top: 0;
                width: 100%;
                height: auto;
                padding-right: 25px;
                padding-left: 0;
                z-index: 10;
                transform: rotate3d(1, 0, 0, 0deg);
                opacity: 1;
                transition: none;

                &.hidden-box-opened {
                    z-index: -10;
                    transform: rotate3d(1, 0, 0, 90deg);
                    opacity: 0;
                    transition: all 0.25s linear;
                }

                svg {
                    width: 17px !important;
                    height: 16px !important;
                    margin-top: 2px;
                }
            }
            @include media("<=phoneland") {
                padding-right: 17px;
            }
        }

        .searchBox-container {
            display: none;
            position: absolute;
            top: 100%;
            right: 20px;
            width: 378px;
            height: auto;
            padding: 32px 33px 31px;
            background: $light-grey;
            z-index: 10;
            box-sizing: border-box;

            &::after {
                display: block;
                position: absolute;
                content: '';
                right: 10px;
                top: -37px;
                border-top: 20px solid transparent;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
                border-bottom: 20px solid $light-grey;
                z-index: 1;
            }

            @include media("<=wide-tab") {
                display: block;
                position: relative;
                top: auto;
                right: auto;
                width: 100%;
                height: auto;
                padding: 23px 26px 22px 0;
                background: inherit;
                z-index: -5;
                transform: rotate3d(1, 0, 0, -90deg);
                opacity: 0;
                transition: none;

                &.hidden-box-opened {
                    z-index: 10;
                    transform: rotate3d(1, 0, 0, 0deg);
                    opacity: 1;
                    transition: all 0.25s linear 0.25s;
                }

                &::after {
                    display: none;
                }
            }
            @include media("<=phoneland") {
                padding-right: 18px;
            }

            .form-fields-wrap {
                .search-field-wrap {
                    float: left;
                    width: 239px;

                    @include media("<=phoneland") {
                        width: 70%;
                        width: -moz-calc(100% - 75px);
                        width: -webkit-calc(100% - 75px);
                        width: calc(100% - 75px);
                    }

                    input {
                        width: 100%;
                        height: 31px;
                        padding: 0 10px;
                        @include Font(Proxima-Light);
                        font-size: 16px;
                        line-height: 29px;
                        color: #565656;
                        background-color: $white;
                        border: solid 1px $mid-grey;
                        box-sizing: border-box;
                        border-radius: 0;
                        transition: all 300ms;

                        &:focus {
                            border: 1px solid $a_accent;

                            box-shadow: inset 0 0 5px rgba(141, 198, 63, 0.3);
                        }

                        @include media("<=wide-tab") {
                            height: 30px;
                            line-height: 28px;
                        }
                    }
                }
                .search-submit-wrap {
                    float: right;

                    .btn {
                        width: 65px;
                        height: 31px;
                        padding: 0;
                        line-height: 31px;
                        text-align: center;

                        @include media("<=wide-tab") {
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
}

// Samesies with refactoring as above
ul.first-level-submenu {
    position: absolute;
    top: 100%;
    width: 365px;
    left: -50%;
    padding: 27px 18px 0 18px;
    text-align: left;
    background-color: $light-grey;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    z-index: 50;
    display: none;

    @include media("<=wide-tab") {
        display: none;
        position: static;
        width: 100%;
        min-width: 0;
        height: auto;
        top: auto;
        left: auto;
        padding: 0 0 15px 20px;
        background-color: transparent;
        box-shadow: none;
        z-index: 1;
    }

    & > li {
        display: block;
        float: left;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 0 none;

        @include media("<=wide-tab") {
            position: relative;
            float: none;
            padding: 0 0 0 20px;

            &::after {
                position: absolute;
                display: block;
                top: 14px;
                left: 1px;
                height: 4px;
                width: 4px;
                content: "";
                background-color: $greyish-brown;
                z-index: 1;
                border-radius: 50%;
            }
        }

        & > a {
            display: inline-block;
            padding: 0 0 10px 0;
            @include Font(Proxima-Reg);
            font-size: 16px;
            line-height: 20px;
            color: $link-primary;

            text-transform: capitalize;
            text-align: left;

            @include media("<=wide-tab") {
                display: block;
                padding: 0;
                @include Font(Proxima-Light);
                line-height: 33px;
                color: $greyish-brown;
            }
        }

        & > ul.second-level-submenu {
            position: static;
            padding: 0 0 27px 0;

            @include media("<=wide-tab") {
                padding: 5px 0;
                display: none;
                min-width: 0;
                height: auto;
            }

            & > li {
                display: block;
                position: relative;
                padding-left: 28px;
                padding: 0 0 0 20px;
                border-bottom: 0 none;

                @include media("<=wide-tab") {
                    padding-left: 0;
                }

                &::after {
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 1px;
                    height: 4px;
                    width: 4px;
                    margin-top: -2px;
                    content: "";
                    background-color: $black-two;
                    z-index: 1;
                    border-radius: 50%;

                    @include media("<=wide-tab") {
                        height: auto;
                        top: 0;
                        bottom: 0;
                        width: 10px;
                        left: 0;
                        margin-top: 0;
                        content: ">";
                        padding: 6px 0;
                        color: $greyish-brown;
                        @include Font(Proxima-Light);
                        font-size: 16px;
                        line-height: 21px;
                        background-color: transparent;
                        border-radius: 0;
                    }
                }

                & > a {
                    display: block;
                    padding: 6px 0;
                    @include Font(Proxima-Light);
                    font-size: 16px;
                    line-height: 21px;
                    color: $black-two;
                    text-transform: capitalize;
                    text-align: left;

                    @include media("<=wide-tab") {
                        position: relative;
                        padding: 6px 40px 6px 15px;
                        @include Font(Proxima-Light);
                        color: $greyish-brown;
                        z-index: 5;
                    }
                }

                &:not(:first-child) a {
                    border-top: solid 0.5px $mid-grey;

                    @include media("<=wide-tab") {
                        border-top: 0;
                    }
                }
            }
        }
    }
}

ul.two-column-submenu {
    left: -230px;
    width: 714px;

    @include media("<=wide-tab") {
        left: 0;
        width: 100%;
    }

    & > li:first-child {
        width: 316px;

        @include media("<=wide-tab") {
            width: 100%;
        }
    }
    & > li:not(:first-child) {
        width: 362px;

        @include media("<=wide-tab") {
            width: 100%;
        }
    }
}

&.has-submenu {
    a.submenu-sibling {
        @include media("<=wide-tab") {
            position: relative;
            padding-right: 45px;

            &::before {
                display: block;
                position: absolute;
                right: 30px;
                top: 50%;
                content: "";
                width: 10px;
                height: 2px;
                margin-top: -1px;
                background: rgba(87, 103, 108, 0.9);
                z-index: 1;
            }

            &::after {
                display: block;
                position: absolute;
                right: 34px;
                top: 50%;
                content: "";
                width: 2px;
                height: 10px;
                margin-top: -5px;
                background: rgba(87, 103, 108, 0.9);
                z-index: 1;
            }
        }
        @include media("<=phoneland") {
            &::before {
                right: 20px;
            }
            &::after {
                right: 24px;
            }
        }
        &.submenu-opened {
            @include media("<=wide-tab") {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.off-canvas-nav {

    a {
        span {
            display: block;
            font-size: 10px;
            line-height: 22px;
            text-transform: uppercase;
            white-space: nowrap;

            @include media("<=wide-tab") {
                float: left;
                @include Font(Proxima-Reg);
                font-size: 16px;
                line-height: 20px;
                @if ($site == solicitors) {
                    color: $accent-secondary;
                } @else {
                    color: $link-primary;
                }
                text-transform: capitalize;
                color: map_get($pushNav, text);
                &:hover {
                    color: map_get($pushNav, hover);
                }
            }
        }
        svg {
            transition: all 250ms;
            fill: map_get($header, navIconFill);

            @include media("<=wide-tab") {
                float: right;
            }
        }
        .login {
            width: 14px;
            height: 20px;
        }
        .search {
            width: 21px;
            height: 20px;
        }
    }
}

.main-nav-icons {
    position: relative;
}

.off-canvas-nav {
    .nav-icons-wrap {
        padding: 0;
        border-bottom: 0 none;
    }

}
