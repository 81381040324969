// This is from the layout.css included in the dashboard <head>
// // @TODO Sort this out into proper files and work out what is actually used
html|* > svg {
    transform-origin: 0 0 0;
}

a > svg {
    pointer-events: none;
}

.pf-dashboard, .pf-form_flex, .pf-admin {
    .svg-icon {
        position: relative;

        display: block;

        width: 20px;
        height: 20px;
        margin: 0 auto;

        transform-origin: 0 0 0;
    }
}

.pf-dashboard {

    .title-section{
        display:none; //@TODO remove this once dashboard is updated to not have any of them
    }

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;

    .doughnutChart {
        position: relative;

        display: inline-block;

        width: 55px;
        height: 55px;
        margin: 0;
        margin-right: -16px;
        padding: 0;

        vertical-align: middle;
    }
    .doughnutSummary {
        display: none;
    }
    .doughnutTip {
        font-size: 12px;
        line-height: 1em;

        position: absolute;

        display: block;

        padding: 5px;

        color: #CCCCCC;
        background: #333333;
    }
    .doughnutTip:after {
        content: '%';
    }
    
    /* MATTY - you should have the buttons vertically aligned */
    .dashboard .summary-table table tbody tr.status-wrap td .button {
        vertical-align: middle;
    }
    .icon {
        font-size: 0;

        display: block;

        fill: #0086BF;
    }

    .button {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 30px;

        display: inline-block;
        float: right;

        width: auto;
        margin-top: 10px;
        padding: 0 15px;

        transition: all .5s;
        text-align: center;
        text-decoration: none;
        text-indent: 0;
        text-transform: uppercase;

        color: white;
        border: 1px solid #0086BF;
        border-radius: 0;
        background-color: #0086BF;
    }
    .button.quote {
        border: 1px solid #93BE30;
        background-color: #93BE30;
    }
    .button:hover {
        color: #FFFFFF;
        border: 1px solid #009444;
        background-color: #009444;
    }


    .dashboard .main {
        flex-basis:70%;
    }
    .dashboard .aside {
        flex-basis:30%;
        min-width:260px;
        max-width:300px;

        @include media("<=desktop") {
            max-width:none;

            flex-basis:100%;
        }
    }
    .status {
        line-height: 70px;

        display: block;

        text-align: left;
        vertical-align: middle;

        fill: green;
    }

    .iconWrapper {
        position: relative;

        display: block;

        padding-right: 20px;
    }
    .status-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        display: block;

        width: 70px;

        padding: 21px 0;
    }
    .status-icon .svg-icon {
        width: 28px;
        height: 28px;

        fill: $white;
    }
    .status + .status-icon {
        background: #93BE30;
    }
    .policies {
        margin:-32px;
        @include media("<=phoneland") {
            margin:-16px;
        }
    }
    .policy {
        position: relative;

        -ms-flex: 1 1 45%;
            flex: 1 1 45%;
    }
    .ie11 .policy,
    .ie10 .policy {
        display: inline-block;

        -ms-flex-preferred-size: 40%;
        flex-basis: calc(50% - 32px);
    }
    .ie9 .policy {
        width: calc(50% - 10px);
    }
    .policy .title {
        font-size: 19px;
        font-weight: 300;
        line-height: 19px;

        position: relative;

        display: block;

        min-height: 40px;
        margin: 0;
        padding: 12px 50px 12px 16px;

        color: #FFFFFF;
        background: #CCCCCC;
    }

    .policy .title .svg-icon {
        position: absolute;
        top: 50%;
        right: 16px;

        display: block;

        width: 28px;
        height: 28px;
        margin: -14px 0 0 0;

        fill: #FFFFFF;
    }

    @each $status, $bgcolor in $policyStatuses {
        .policy.#{$status} {
            .title{
                background: $bgcolor;
                .svg-icon{
                    color: $bgcolor;
                }
            }
            .button{
                border-color: $bgcolor;
                background: $bgcolor;
            }
        }
        .status.#{$status} {
            fill: $bgcolor;
        }
        .icon-#{$status} + .status-icon{
            color: $bgcolor;
            background: $bgcolor;
        }
            
    }
    
    .policy .content {
        font-size: 18px;

        top: 0;
        bottom: 0;

        display: table;
        /* min-height: 100%; */

        box-sizing: border-box;
        width: 100%;
        height: calc(100% - 46px);
        padding: 0;

        background: #DDE1E2;

        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    }
    .ie11 .policy .content {
        height: calc(100% - 66px);
    }
    .policy .content > div {
        display: table-row;
    }
    .policy .content > div span {
        display: table-cell;

        height: 100%;
        padding: 16px;

        vertical-align: bottom;
        @include media("<=phoneland") {
            vertical-align: bottom;
        }
    }
    .policy .content > div span .button {
        margin-top: 0;
    }
    h1.column-title {
        margin-top: 0;
    }
    
    //@TODO Compare these to Solicitors/LSOS/Acc and deal with in _logos.scss / _header.scss
    // #mainheader .flex-nav .logo{
    //     padding-right:24px;
    //     padding-top: 25px;
    // }

    // #mainheader .logo a svg{
        // width: 112px!important;
        // height: 48px!important;
    // }
    // #mainheader .logo_lsos{
    //     margin-left: 24px;
    // }
    // #mainheader .logo_lsos img {
    //     max-width: 120px!important;
    //     height: 48px!important;
    // }
    #mainheader .bounds > ul li a {
        padding: 32px 8px!important;
    }
 
    input,
    textarea,
    keygen,
    select,
    button {
        font-family: 'proxima-nova',sans-serif;
        font-size: 14px;
    }
    select {
        border: 1px solid #CCCCCC;
        border-radius: 0;
        background: transparent;

        -webkit-appearance: none;
           -moz-appearance: none;
    }
    h1 {
        font-size: inherit;
        /* text-transform: uppercase; */
        font-size: 24px;
        font-weight: 400;
        padding:0;
    }
    /*page style*/

    a,
    a:hover {
        color: $hover-primary;
    }




 




    



    .policyDoc a {
        display: block;
    }
    .policyDoc a .svg-icon {
        margin: 0;

        fill: #0086BF;
    }
    .policyDoc.disabled a .svg-icon {
        margin: 0;

        fill: #C7C7C7;
    }
    .policyDoc.disabled a,
    .policyDoc.disabled a:hover {
        cursor: default;
    }





    /*Dashboard nav*/
    .dash-nav {
        border-bottom: 1px solid #E6E7E8;
    }
    .dash-nav ul {
        margin: 0;
    }
    .dash-nav ul li {
        display: inline-block;

        margin: 0 10px;
        padding: 10px 0;

        transition: border-color .3s ease !important;

        border-top: transparent 3px solid;
        border-bottom: transparent 3px solid;
    }
    .dash-nav ul li:first-of-type {
        margin-left: 0;
    }
    .dash-nav ul li.current,
    .dash-nav ul li:hover {
        border-bottom: #939598 solid 3px;
    }
    .dash-nav ul li a {
        padding: 10px 5px;

        text-transform: uppercase;

        color: #595A5C;
    }


    .pf-dashboard section {
        padding-top: 20px;

        background: #F1F2F2;
    }
    .pf-dashboard section + section {
        padding-top: 20px!important;
        padding-bottom: 100px;

        border-top: 1px solid white;
    }
    .pf-dashboard footer {
        margin-top: 0;
    }
    .pf-dashboard section h1,
    .pf-dashboard section h2 {
        font-size: 22px;
        font-weight: 400;

        margin: 20px 0;

        text-transform: uppercase;

        color: #717171;
    }


    .two-column-left {
        display: inline-block;

        width: 100%;
        max-width: calc(65% - 32px);
        margin-right: 32px;

        vertical-align: top;
    }
    .one-column-right {
        display: inline-block;

        width: 100%;
        max-width: 35%;
    }
    .dataTables_length select,
    select.chosen-style,
    select.filter-select {
        padding-right: 25px;
    }
    .documents-page .inline-form {
        margin-bottom: 20px;
    }
    /* All below amended by matty */
    button {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 30px;

        display: inline-block;

        width: auto;
        margin-top: 10px;
        padding: 0 15px;

        text-align: center;
        text-decoration: none;
        text-indent: 0;
        text-transform: uppercase;

        color: white;
        border: 1px solid #0086BF;
        border-radius: 0;
        background-color: #0086BF;
    }
    button:hover {
        cursor: pointer;

        border: 1px solid #009444;
        background-color: #009444;
    }
    .button.docFilter {
        float: none;

        margin: 0;
    }

    .login-box .alert {
        margin: 0;
        padding: 5px;

        text-align: center;
    }

    .login-box .alert.red {
        border: 1px solid rgba($white,0.6);
        background: rgba($pale-red, .5);
        color:$white;
    }
    .login-box .alert.green {
        border: 1px solid #009D00;
        background: rgba(#009D00, .5);
        color:$white;
    }

    @if($site == accountants) {
        .login-box .alert.red {
            border: 1px solid rgba($white,0.6);
            background: rgba($rouge-three, .5);
            color:$white;
        }
        .login-box .alert.green {
            border: 1px solid rgba($white,0.6);
            background: rgba(#009D00, .5);
            color:$white;
        }
    }
    select {
        padding: 5px 15px 5px 5px;

        background: $white url(/img/arrow.png) right center no-repeat;
    }
    .ie9 select,
    .ie10 select,
    .ie11 select {
        padding-right: 5px;

        background-image: none;
    }
    b {
        font-weight: bold;
    }
    ul {
        padding: 0;
    }

   
    header.pageHead {
        margin-bottom: 20px;

        border: 0;
        background: #EEEEEE;
    }
    header.pageHead > h2 {
        width: 90%;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
    }
    header.pageHead > h2 {
        margin: 0 auto;
        padding: 10px;
    }

    .dataTable tbody tr.shown + tr {
        padding: 0;
    }
    .dataTable tbody tr.shown + tr td {
        padding: 8px 16px 32px;
    }
    .dataTable tbody tr.shown + tr td table tr td {
        padding: .625rem .85rem;
    }
    .dataTable tbody tr.shown + tr td table tr {
        background: #FEFEFE;
    }
    .dataTable tbody tr.shown + tr td table tr:nth-of-type(odd) {
        background: #E8EBEB;
    }
    .dataTable tbody tr.shown + tr tr.noFiles {
        display: table;

        width: 100%;
    }
    .dataTable tbody tr.shown + tr tr.noFiles td {
        font-weight: 600;

        width: 100%;
        padding: 5px 10px;
    }
    .create-note {
        display: block;

        padding-bottom: 14px;

        text-align: right;
    }
    .create-note button {
        margin-top: 0;
    }
    
    @media screen and (max-width:1200px) {
        .flex-nav{
            justify-content:flex-start;
        }

        #mainheader {
            height: 132px!important;
        }

        #mainheader .logo:before {
            height: 92px!important;
        }
        #mainheader .logo .svg-logo {
            width: 97px!important;
            height: 92px!important;
        }
        #mainheader .menu {
            display: block!important;
        }
        #mainheader .bounds > ul {
            display: none!important;
        }

        .dashboard .main {
            width: 100%;
        }
        .dashboard .main .policies {
            margin-right: -32px;
        }
    }

    /* Media Queries */
    @media only screen and (max-width: 1024px) {
        .chart {
            width: 200px;
            height: 200px;
        }
        .doughnutSummaryTitle {
            font-size: 14px;

            margin: -10px 0 0 -41px;
        }
    }

    @media only screen and (max-width: 850px) {
        .summary-table {
            padding-right: 0;
        }

        .chart {
            width: 500px;
            height: 500px;
        }
        .doughnutSummaryTitle {
            font-size: 18px;

            margin: 0 0 0 -20px;
        }
    }
    @media only screen and (max-width: 767px) {
        .status {
            line-height: 21px;

            right: auto;
            left: 0;
        }

        .status-icon {
            width: 50px;
            padding: 15px 0;
        }

        .status-icon .svg-icon {
            width: 20px;
            height: 20px;
        }

        .dashboard .summary-table table tbody tr td:last-of-type {
            height: 71px;
            padding: 10px 50px 10px 40%;

            text-align: left;
        }

        .policy {
            width: 100%;
            padding-right: 0;

            flex: 1 1 100%;
        }

        .ie11 .policy,
        .ie10 .policy {
            width: 100%;

            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
        }
        .bounds {
            width: 100%;
        }
        .two-column-left,
        .one-column-right {
            max-width: 100%;
            margin-right: 0;
        }
        .dashboard .main .policies {
            margin-right: -16px;
        }

    }

    @media screen and (max-width:568px) {
        .header .flex-nav .logo{
            max-width:250px!important;
        }
        #mainheader {
            height: auto!important;
        }
        .dashboard .main .policies {
            margin-right: -8px;
        }
    }

    .chosen-container {
        font-size: 15px !important;
    }

    // POST AMALGAMATION STYLES MG 221216
    .lockton-top-section{
        margin-bottom:1em;
    }

}
