// This is in the style of mobility product-comparison sidebar
.widget_d {
	// padding: 14px 20px 30px;
	margin-bottom: 3px;
	background-color: $side_background;;

	@include media("<tabport") {
		padding-bottom: 37px;
	}

	.widget-title {
		margin: 0;
		padding:20px;
		@include Font(Proxima-Thin);
		font-size: 28px;
		line-height: 34px;
		color: $greyish-brown;

		@include media("<tabport") {
			font-size: 26px;
			line-height: 32px;
		}
	}
	.widget-subtitle{
		background-color:$accent-secondary;
		color:$white;
		padding:10px 20px 8px;
		@include Font("Proxima-Black");
		font-size: 24px;
		line-height: 1em;
		color: $white;
		margin:0;
	}

	.widget-block{
		.widget-list{
			@extend %list_default;
		}
		&:last-child{ // This is for the CTA button
			text-align:center;
			margin-top:-12px;
			.btn_a{
				position:relative;
				transform: translateY(50%);
				@include Font("Proxima-Black");
				font-size: 19.2px;
				padding: 14px 25px;
			}
		}
	}

	.contacts {
		@include Font(Proxima-Light);
		font-size: 14px;
		line-height: 18px;
		color: $greyish-brown;

		span {
			display: block;
		}
	}
}