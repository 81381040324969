/* ---------------------------------------------------------------------- */
/*  Events Calendar
/* ---------------------------------------------------------------------- */
/*= CORE CSS */ // @TODO Is all of this used?
.hidden {
    display: none;
}
.eventCalendar-wrap {
    position: relative;

    overflow: hidden;
}
.eventCalendar-wrap .arrow {
    position: absolute;
    z-index: 5;
    top: 3px;
}
.eventCalendar-wrap .prev {
    left: 3px;
}
.eventCalendar-wrap .next {
    right: 3px;
}
.eventsCalendar-currentTitle .monthTitle {
    display: block;

    text-align: center;
}
.eventsCalendar-monthWrap {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}
.eventsCalendar-monthWrap.currentMonth {
    z-index: 1;
}
.eventsCalendar-daysList {
    display: table;

    width: 100%;
}
.eventsCalendar-daysList.showAsWeek {
    display: block;
}
.eventsCalendar-daysList li {
    display: table-cell;
}
.eventsCalendar-daysList li.dayWithEvents {
    background: rgba(0,0,0,.15);
}
.eventsCalendar-daysList li.current {
    background: rgba(0,0,0,.35);
}
.eventsCalendar-daysList.showAsWeek li {
    display: block;
    float: left;

    width: 14.28%;
    height: 20px;
}
.eventsCalendar-daysList li a {
    font-size: 8px;

    display: block;

    min-width: 7px;
    padding: 0;

    text-align: center;
}
.eventsCalendar-loading {
    line-height: 40px;

    display: block;

    min-width: 100px;
    height: 40px;
}
.eventsCalendar-list {
    position: relative;
    z-index: 1;
}
.eventsCalendar-list.oldEventList {
    z-index: 2;
}
.eventsCalendar-subtitle {
    /*font-weight:bold;*/
    font-size: 120%;

    padding-left: 5px;
}
/* end of core CSS */
.eventCalendar-wrap {
    color: #807E7E;
    border: solid 1px #D3D3D3;
    
    background-color: $white;
}
.eventCalendar-wrap .arrow {
    line-height: 28px;

    top: 9px;

    padding: 0 5px;
    padding: 8px 10px;

    text-decoration: none;

    color: $white;
}
.eventCalendar-wrap .arrow:hover {
    opacity: .7;
}
.eventCalendar-wrap .arrow span {
    font-size: 0;
    line-height: 0;

    float: left;

    width: 0;
    height: 0;

    text-indent: -5000px;

    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid $white;
}
.eventCalendar-wrap .arrow.prev span {
    border-right: 6px solid $white;
    border-left-width: 0;
}
.eventsCalendar-slider {
    height: 80px;
}
.eventsCalendar-monthWrap {
    top: 10px;
    left: 0;

    border-radius: 5px;
}
.eventsCalendar-currentTitle {
    line-height: 25px;

    border: 1px solid #E3E3E3;
    border-width: 1px 0;
    outline: 1px solid $newstype_event;
    background-color: $newstype_event;
}
.eventsCalendar-currentTitle .monthTitle {
    font-size: 14px;

    text-decoration: none;
    text-transform: uppercase;

    color: $white;
}
.eventsCalendar-daysList {
    zoom: 1;

    width: 100%;
    padding: 0;
}
.eventsCalendar-daysList.showAsWeek {
    width: auto;
    margin: 10px 5px;
}
.eventsCalendar-daysList.showDayNames.showAsWeek {
    border-bottom: solid 1px #BCBCBC;
}
.eventsCalendar-daysList:before,
.eventsCalendar-daysList:after {
    display: table;

    content: '';
}
.eventsCalendar-daysList:after {
    clear: both;
}
.eventsCalendar-day-header {
    font-size: 10px;

    text-align: center;
    text-transform: lowercase;

    border-bottom: solid 1px #BCBCBC;
}
.eventsCalendar-daysList.showAsWeek li {
    height: auto;
    margin: 0;
}
.eventsCalendar-daysList.showAsWeek li.empty {
    min-height: 29px;
}
.eventsCalendar-day a {
    font-size: 10px;

    text-decoration: none;

    color: #424242;
}
.eventsCalendar-day {
    border-left: solid 1px #BCBCBC;
}
.eventsCalendar-day a {
    border: solid 1px #BCBCBC;
    border-width: 0 1px 1px 0;
}
.showAsWeek .eventsCalendar-day {
    border-left-width: 0;
}
.showAsWeek .eventsCalendar-day a {
    font-size: 11px;
    line-height: 27px;

    border: solid 1px red;
    border-color: $white; /*#BCBCBC #BCBCBC #eee*/
}
.eventsCalendar-day a:hover {
    background-color: #E4E4E4;
}
.eventsCalendar-daysList li.today a {
    color: $white;
    background: #AAAAAA;
}
li.eventsCalendar-day.today a:hover {
    background-color: #CCCCCC;
}
.eventsCalendar-daysList li.dayWithEvents a {
    color: $white;
    background: #A3CE4A;
}
li.eventsCalendar-day.dayWithEvents a:hover {
    background-color: #C2D374;
}
.eventsCalendar-daysList li.current a {
    color: $white;
    background: #8FB04F;
}
li.eventsCalendar-day.current a:hover {
    background-color: #A3CE4A;
}
.eventsCalendar-loading {
    font-weight: bold;

    position: absolute;
    z-index: 4;
    top: 25px;
    left: 5px;

    margin: 5px auto;
    padding: 0 10px;

    text-align: center;

    color: $white;
    border-radius: 4px;
    background-color: #CCCCCC;
    box-shadow: 0 0 10px #CCCCCC;
    text-shadow: 0 0 3px #AAAAAA;
}
.eventsCalendar-loading.error {
    background-color: red;
}
.eventsCalendar-subtitle {
    padding-top: 10px;
}
.eventsCalendar-list-wrap {
    position: relative;

    min-height: 100px;
}
.eventsCalendar-list-content.scrollable {
    overflow-y: auto;

    height: 100px;
    margin: 0 5px 5px 0;
}
.eventsCalendar-list {
    margin: 0;
    padding: 0;

    list-style-type: none;
}
.eventsCalendar-list li {
    clear: both;

    margin: 0;
    padding: 0 5px 15px;
}
.eventsCalendar-list li time {
    font-size: 10px;
    line-height: 13px;
}
.eventsCalendar-list li time em {
    font-style: normal;

    float: left;
}
.eventsCalendar-list li time small {
    font-size: 10px;

    float: left;

    margin: 0 0 0 3px;
    padding: 0 5px 0 4px;

    color: $white;
    background-color: #807E7E;
}
.eventsCalendar-list li .eventTitle {
    display: block;
    clear: both;

    text-decoration: none;
}
.eventsCalendar-list li a.eventTitle {
    text-decoration: none;

    color: #868686;
}
.eventsCalendar-list li a.eventTitle:hover {
    color: #A3CE4A;
}
.eventsCalendar-list li .eventDesc {
    font-size: 80%;
    line-height: 1.2em;

    clear: both;

    margin: 0 0 5px 0;
}
.eventsCalendar-list .eventsCalendar-noEvents {
    font-size: 120%;

    margin: 5px;
    padding: 5px;

    text-align: center;

    border-radius: 4px;
}
.bt {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;

    display: block;
    clear: both;

    margin-top: 10px;
    padding: 9px 34px 11px;

    transition: .1s linear all;
    text-align: center;
    text-decoration: none;

    color: $white;
    border: 1px solid #698B10;
    border-color: #698B10 #698B10 #465F05;
    border-radius: 4px;
    background-color: #698B10;
    
    background-image: linear-gradient(to bottom, #89B814, #698B10);
    background-repeat: repeat-x;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#89B814', endColorstr='#698B10', GradientType=0);
}
.bt:hover {
    text-decoration: none;

    background-position: 0 -15px;
}
/* end of theme css */

.eventsCalendar-subtitle {
    font-family: 'Avenir LT W02 35 Light','Open Sans','Lucida Sans Unicode','Lucida Grande',sans-serif;
    font-size: 14px;

    text-transform: uppercase;
}
.back {
    width: 20%;
    margin-bottom: 1em;
}
.back .arrow {
    position: relative;
    top: 6px;

    float: left;

    width: 0;
    height: 0;
    margin-right: .5em;

    border-top: 6px solid transparent;
    border-right: 6px solid #A3CE4A;
    border-bottom: 6px solid transparent;
}