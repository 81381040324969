@mixin Font($type) {
    @if $type == Proxima-Light {
       /* Proxima Nova Light */
       font-family: 'proxima-nova', sans-serif;
       font-weight: 300;
       font-style: normal;
    }
    @else if $type == Proxima-Reg {
       /* Proxima Nova Regular */
       font-family: 'proxima-nova', sans-serif; 
       font-weight: 400;
       font-style: normal;
    }
  @else if $type == Proxima-Bold {
       /* Proxima Nova Bold */
       font-family: 'proxima-nova', sans-serif; 
       font-weight: 600;
       font-style: normal;
    }
    @else if $type == Proxima-Semibold {
       /* Proxima Nova Bold */
       font-family: 'proxima-nova', sans-serif; 
       font-weight: 600;
       font-style: normal;
    }
    @else if $type == Proxima-Black {
       /* Proxima Nova Black */
       font-family: 'proxima-nova', sans-serif;
       font-weight: 900;
       font-style: normal;
    }
    @else if $type == Proxima-RegIt {
       /* Proxima Nova RegularIt */
       font-family: 'proxima-nova', sans-serif; 
       font-weight: 300;
       font-style: italic;
    }
    @else if $type == Proxima-Thin {
       /* Proxima Nova Light */
       font-family: 'proxima-nova', sans-serif; 
       font-weight: 300;
       font-style: normal;
    }
    @else if $type == Proxima-ThinIt {
       /* Proxima Nova RegularIt */
       font-family: 'proxima-nova', sans-serif; 
       font-weight: 300;
       font-style: italic;
    }
}
