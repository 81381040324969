
.back-to-page {
	.back-to-page-link {
		position: relative;
		display: block;
		padding-left: 22px;

		.back-to-page-arrow {
			position: absolute;
			display: block;
			left: 1px;
			top: 2px;
			width: 15px;
			height: 15px;
		}
		.back-to-page-text {
			display: inline-block;
			@include Font(Proxima-Reg);
			font-size: 16px;
			line-height: 20px;
			color: $link-primary;
			transition: all 250ms;
		}
		.icon-fill {
			fill: $link-primary;
			transition: all 250ms;
		}

		&:hover, &:active {
			.back-to-page-text {
				color: $hover-primary;
			}
			.icon-fill {
				fill: $hover-primary;
			}
		}
	}
}