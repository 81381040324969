.documents-page{
	.sidebar .downloads-widget .downloads .icon,
	.sidebar .downloads-widget .downloads .icon svg{
		svg{
			width: 21px;
			height: 25px;
		}
	}
	.sidebar .downloads-widget .downloads .icon{
		top:-2px;
	}
	.sidebar ul.files li{
		padding-left: 32px;
	}
}

.modal_documents {
	.row{
		margin-bottom:8px;
	}
	.icon{
		display:inline-block;
		vertical-align: middle;
		height:20px;
	}
	svg{
		height:20px;
		width:20px;
	}
}